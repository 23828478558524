import React, { useEffect, useState } from 'react';
import { Modal, Input, Card, Row, Col } from 'antd';
import { InstructionalModel } from '@src/utils/instruction_model';
const { Meta } = Card;

export interface CardData {
    id: number;
    title: string;
    description: string;
}

interface NewCurriculumProps {
    visible: boolean;
    onCancel: () => void;
    cardsData: InstructionalModel[];
    click: (card: InstructionalModel) => void;
}

const BrowseModal = ({ visible, onCancel, cardsData, click }: NewCurriculumProps) => {
    const [filteredCards, setFilteredCards] = useState(cardsData);
    const [hoveredCard, setHoveredCard] = useState<number>(0);
    const [isMobile, setIsMobile] = useState(true);
    const [selectedCard,setSelectedCard]= useState<number>();

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);

    const handleCardHover = (cardId: number) => {
        setHoveredCard(cardId);
    };

    const handleCardLeave = () => {
        setHoveredCard(0);
    };
    useEffect(() => {
        setFilteredCards(cardsData);
    }, [cardsData]);

    const handleSearch = (value: any) => {
        const filtered = cardsData.filter(
            (card) =>
                card.instruction.toLowerCase().includes(value.toLowerCase()) ||
                card.description.toLowerCase().includes(value.toLowerCase()),
        );
        setFilteredCards(filtered);
    };

    return (
        <Modal
        open={visible}
        title={
            <div className="text-[1.25rem] text-black-500 font-[Inter,sans-serif]">
                Browse Instructional Models
            </div>
        }
        onCancel={onCancel}
        footer={null}
        width={916}
    >
        <Input
            className="w-full md:w-[21.875rem]"
            style={{ marginBottom: 24 }}
            placeholder="Search templates"
            onChange={(e) => handleSearch(e.target.value)}
            suffix={
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle
                        cx="8.33333"
                        cy="8.33333"
                        r="5.83333"
                        stroke="#E55109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M17.5 17.5L12.5 12.5"
                        stroke="#E55109"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            }
        />
        <div style={{ maxHeight: '50vh', overflowY: 'auto' }}>
            <Row gutter={[16, 16]}>
                {filteredCards.map((card, index) => (
                    <Col span={isMobile ? 12 : 6} key={index}>
                        <Card
                            className={`w-[10rem] md:w-auto flex flex-col gap-y-3 relative p-4 border ${
                                selectedCard === index
                                    ? 'border-orange-500'
                                    : 'border-gray-200'
                            }`}
                            style={{ height: '172px' }}
                            onClick={() => setSelectedCard(index)}
                        >
                            <h2 className="font-medium text-[0.813rem] text-[var(--gmind-black)]">
                                {card.instruction}
                            </h2>
                            <p className="font-normal text-[0.75rem] text-[var(--gmind-light-black)]">
                                {card.description}
                            </p>
                            <div
                                className={`absolute bottom-0 left-0 w-full h-10 flex flex-row justify-center items-center cursor-pointer ${
                                    selectedCard === index
                                        ? 'bg-orange-100 text-orange-600'
                                        : 'bg-gray-100 text-gray-600'
                                }`}
                                onClick={() => click(card)}
                            >
                                Use Template
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    </Modal>
    
    );
};

export default BrowseModal;
