import React from 'react';
import { Circle, File } from 'lucide-react';
import { AudioOutlined } from '@ant-design/icons';
interface UploadRecordProps {
    onUpload: () => void;
    isClicked: boolean;
}

const UploadRecord = ({ onUpload,isClicked }: UploadRecordProps) => {
    return (
        <button
            onClick={onUpload}
            className={`flex flex-row items-center justify-between gap-2 px-4 w-full py-2 rounded-md transition-colors duration-200 border
    ${isClicked ? 'dark:border-customOrange border-customOrange bg-white text-white dark:text-customOrange' : 'dark:border-white bg-transparent border-gray-700 text-gray-700 dark:text-white'}
    hover:bg-gray-200 dark:hover:bg-gray-700`}
        >
           <div className={`${isClicked ? 'bg-customOrange' : 'bg-gray-700 dark:bg-white'}  h-4 w-4 rounded-full`} />
            {/* <File className={`${isClicked ? 'text-customOrange' : 'text-gray-700 dark:text-white'}`} /> */}

            <span className={`${isClicked ? 'text-customOrange' : 'text-gray-700 dark:text-white'}`}>Upload Audio</span>

            
            <AudioOutlined className={`${isClicked ? 'text-customOrange' : 'text-gray-700 dark:text-white'}`} />
        </button>
    );
};

export default UploadRecord;
