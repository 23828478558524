import React, { useState } from 'react';
import { Input } from 'antd';
import { toast } from 'react-toastify';

const { TextArea } = Input;

export const ArticleTitle = ({
    topic,
    setTopic,
    setCurrent,
    current,
}: {
    topic: string;
    setTopic: React.Dispatch<React.SetStateAction<string>>;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
    current: number;
}) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <h2 className="text-[2.5rem] font-bold h-[4rem] dark:text-gray-300">What do you want us to write about?</h2>
            <p className="text-gray-600 text-[1.125rem] dark:text-gray-300 mb-6">Your prompt can be short, long, or even ambiguous.</p>

            <div className="dark:bg-[#3b4252] bg-white p-6 rounded-lg w-full max-w-lg border border-gray-500 relative focus-within:border-blue-500">
                <textarea
                    className="w-full h-24 bg-transparent text-black dark:text-white border-none outline-none resize-none"
                    placeholder="Enter your article topic."
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                />
                <button
                    className="absolute bottom-4 right-4 bg-customOrange text-white px-4 py-2 rounded-md hover:bg-orange-600 transition"
                    onClick={() =>{ 
                        if(!topic){
                            toast.error("Please enter a topic");
                            return;
                        }
                        setCurrent(current + 1)}}
                >
                    Next
                </button>
            </div>
        </div>
    );
};
