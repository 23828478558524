/* eslint-disable @typescript-eslint/no-unused-vars */
import LoadingComponent from '@src/components/LoadingComponent';
import { ChatType } from '@src/core/interfaces/chat';
import { handleAddHistory } from '@src/pages/dashboard/endpoints';
import { prompts } from '@src/utils/prompts';
import { Checkbox, Form, Input } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import MarkdownDesign from '@src/components/Markdown';
import Like from '@public/like.svg';
import DisLike from '@public/dislike.svg';
import Message from '@public/message-circle-2.svg';
import Regenerate from '@assets/repeat.svg';
import Profile from '@assets/user-circle.svg';
import { contentPromptOptionsData2 } from '../constants';
import { openEditor } from '@src/utils/app_functions';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { InputTypeProps } from '../../assessment_2/interface';
import { socialMedias } from '../../curriculum/constants';
import { BASE_URL } from '@src/utils/constants';
import { Chats } from '@src/pages/dashboard/components/interface/interface';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import NewContentEditorPage from '../../essay/editor/newInstanceOfEditor copy';
import BackButton from '@src/components/BackButton';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ThemeContext } from '@src/theme_provider/theme';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { ArrowRight } from 'lucide-react';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const ContentWriter = ({
    isComprehensive = true,
    isSocial = false,
    response,
    setResponse,
    setCurrentPage,
    setPageNumber,
    setShowSidebar,
    setShowTop,

    setPageName,
    setSubPage,
}: {
    isComprehensive: boolean;
    isSocial?: boolean;
    response: string;
    setResponse: any;
    setCurrentPage: any;
    setPageNumber: any;
    setShowSidebar: any;
    setShowTop: any;
    setPageName: any;
    setSubPage: any;
}) => {
    const [isTyping, setIsTyping] = useState(false);

    const [description, setDescription] = useState<string>('');
    const [prompt, setPrompt] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const pageHistory = 'contents';
    const [generating, setGenerating] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [navigate, setNavigate] = useState(false);
    const [pageId, setPageId] = useState('');
    const [showRefrence, setShowReference] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<Chats>({ role: 'assistant', content: '' });
    const bottomRef = useRef<HTMLDivElement>(null);
    const [rememberMe, setRememberMe] = useState(false);
    const Document = useRef<any>();
    const [histories, setHistories] = useState<any>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [message, setMessage] = useState('');
    const [topic, setTopic] = useState('');
    const [newMessage, setNewMessage] = useState('');
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const currentEntity = useRef<ChatType>();
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [page, setPage] = useState<string>('');
    const [pageClick, setPageClick] = useState<() => void>(() => {});
    const historyCloseId = 'historyClose';
    const [canNavigate, setCanNavigate] = useState(false);
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [editorContent, setEditorContent] = useState<string>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const location = useLocation();
    const { error, loading, fetchDocument, document, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [isEditContent, setIsEditContent] = useState(false);
    // const [response, setResponse] = useState('');
    const [latestRespone, setLatestRespone] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const editorInstanceRef = useRef<any>();
    const [isMobileStep, setIsMobileStep] = useState(0);
    const { isMobile, isLargeTab } = useContext(ThemeContext);
    const queryParams = new URLSearchParams(window.location.search);
    const { showingHistory } = useOutletContext<any>();

    const { details } = useOutletContext<any>();

    useEffect(() => {
        if (isTyping && response != '') {
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, response]);

    const naviagtePage = useNavigate();

    const options = contentPromptOptionsData2({
        response,
        showDownload: () => {},
    });

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const onGenerateHandler = async (message: string, humanize = false) => {
        // console.log(isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent,);
        // return;
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');

        setIsTyping(true);
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message };
        // console.log(msgs);
        // return;
        setChats(msgs);
        setResponse('');
        console.log(description);
        setResponse('');

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: isComprehensive
                                ? prompts.content(description, showRefrence)
                                : prompts.shortContent,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId ?? '',
                },
                label: "genie",
            });
        } catch (error) {
            //   setIsTyping(false);
        } finally {
            //   setIsTyping(false);
        }
    };

    useEffect(() => {
        console.log(`edit content: ${isEditContent}`);
    }, [isEditContent]);

    useEffect(() => {
        if (response) {
            setEditorContent(response);
        }
    }, [response]);

    useEffect(() => {
        if (!socket) return;
        let tempBuffer = '';

        socket.on('data', (data: string) => {
            // setResponse((response: any) => response + data);

            setResponse((response: any) => response + data);
        });

        socket.on('content_images', (images: string[]) => {
            // setResponse((response: any) => response   + data);
            const data = images.map((value, index) => {
                // mimick editor images 
                const dataValue = `
                    ![Alt text](${value})
                `;

                return dataValue;
            })

            const responseValue = data.join('\n');

            console.log(responseValue)

            // setResponse((response: any) => response + responseValue);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            // console.log(`-------------------Testing ooo------------------`);

            // const assistantMessage = assistant[assistant.length - 1].content;
            // // setLatestRespone(assistantMessage);
            // setEditorContent(assistantMessage);
            setIsTyping(false);
            toast.success('Content generated successfully');
            setIsEdit(true);

            const uuid = uuidv4();
            setDocId(uuid);
            DocumentId.current = uuid;
            const user = JSON.parse(localStorage.getItem('user') || '');
            setStreamEndMessage(data.assistant);
            setIsTyping(false);
            setDescription('');
            // if (streamId === StreamId.current) {
            StreamId.current = '';
            socket.emit('get-documents', user?.id);
            setNavigate(true);
            setPageId(uuid);
            // }
        });

        return () => {
            socket.off('message');
        };
    }, [socket, isEditContent]);

    const handleStreamEnd = async (prompt: string, data: Chats) => {
        // let dataToSave: Chats[]  = [
        //     {
        //         role: 'user',
        //         content: prompt,
        //     },
        //     data
        // ]
        let dataToSave = data;

        const promptSent = isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent;

        let docData = {
            content: response,
            doc_id: pageId,
            promptSent: prompt,
            promptType: promptSent,
        };

        await handleAddHistory(dataToSave, pageHistory, docData);
        if (pageId) fetchDocument(pageId ?? '', true);
    };

    useEffect(() => {
        if (!isTyping && response != '') {
            handleStreamEnd(prompt, streamEndMessage);
        }
        // const promptSent = isComprehensive ? prompts.content(description, showRefrence) : prompts.shortContent;
        // if (navigate && response != '') {
        //     openEditor({
        //         response,
        //         pageId,
        //         promptSent: prompt,
        //         promptType: promptSent,
        //         navigate: naviagtePage,
        //         pageSocket: socket,
        //     });
        // }
    }, [response, navigate, isTyping, socket]);

    const onFinish = async () => {
        if (!description) {
            toast.error('Enter your description');
            return;
        }

        setIsMobileStep(1);

        let message = `Generate a Content of the topic described with this details: ${description}`;
        setIsTyping(true);
        setPrompt(message);
        await onGenerateHandler(message);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };
    const regenerate = async (humanize = false) => {
        let newPrompt = prompt;
        setPrompt(newPrompt);
        await onGenerateHandler(newPrompt, humanize);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    // function getDocIdFromUrl() {
    //     try {
    //         const urlObject = new URL(window.location.href); // Parse the URL
    //         const doc = urlObject.searchParams.get('document');
    //         setDocId(doc ?? '');
    //         console.log(doc)
    //     } catch (error) {
    //         console.error('Invalid URL', error);
    //         return null;
    //     }
    // }

    useEffect(() => {
        if (document && docId) {
            // Register the user to the document space

            console.log('docId', docId);
            socket?.emit('register_document_space', {
                documentId: docId,
                userId: userDetails?.userId,
            });

            // Listen for document changes
            socket?.on('receive-changes', (data: { data: string; documentId: string; userId: string }) => {
                console.log(data.userId);
                console;
                if (data.userId === userDetails?.userId) return;
                setResponse(data.data);
                setEditorContent(data.data);
            });

            // Listen for users on document
            socket?.on('user_on_document', (data: UserDocOn) => {
                setUserOnDoc(data);
            });

            // Cleanup listeners on component unmount or dependencies change
            return () => {
                socket?.off('receive-changes'); // Fix typo here
                socket?.off('user_on_document');
            };
        }

        // Cleanup in case no document exists
        return () => {
            socket?.off('receive-changes');
            socket?.off('user_on_document');
        };
    }, [socket, document, docId]);

    return (
        <div className="w-full px-0 md:p-[40px] xl:pt-5  h-screen flex flex-col md:flex-row md:justify-center gap-x-10 gap-y-3">
           <div
    className={`${isMobile ? (isMobileStep === 0 ? 'flex mb-5' : 'hidden') : 'flex'} 
        bg-[#E6E6E6]  p-[24px] flex flex-col gap-y-3 max-h-fit w-full xl:w-[25%] md:w-[60%] 
        rounded-[0.75rem] dark:shadow-none shadow-lg overflow-auto`}
>
                <div className="flex flex-row gap-x-2 items-center justify-start">
                    <button
                        className="bg-gray-100 h-6 w-6 rounded-full p-2 flex flex-row gap-x-2 items-center justify-center"
                        onClick={() => {
                            setCurrentPage(0);
                            setPageNumber(0);
                            setShowSidebar(true);
                            setShowTop(true);
                            setResponse('');
                            setPageName('');
                            setSubPage('');
                        }}
                    >
                        <LeftOutlined />
                    </button>
                    <span className='text-[1rem] font-semiBold'>Back</span>
                </div>

                <p className="text-base font-bold leading-5 text-left">
                    {isComprehensive ? 'Comprehensive Content' : 'Short Content'}
                </p>
                <p className="font-normal text-[0.8rem] text-[var(--gmind-light-black)]">What can I assist you with?</p>

                <Form layout="vertical" className="flex flex-col flex-wrap gap-x-5" onFinish={onFinish}>
                    <Form.Item className="w-full mb-1" name={'description'}>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="w-full text-black placeholder-gray-700 h-[7.5rem] bg-white dark:border dark:border-gray-500 border border-gray-700"
                            placeholder="e.g Rewrite in Short sentence"
                            style={{ borderRadius: '10px', padding: '7px 10px' }}
                            rows={isMobile ? 4 : 8}
                        />
                    </Form.Item>

                    {isComprehensive && !isSocial && (
                        <div className="w-full flex flex-col gap-y-2 p-3 border rounded-lg">
                            {/* Show References Section */}
                            <div className="flex items-center justify-between">
                                <p className="font-medium dark:text-black text-[0.75rem]">Show References</p>
                                <Checkbox
                                    value={showRefrence ? true : false}
                                    onChange={(e) => setShowReference(!showRefrence)}
                                    style={{
                                        border: '1px solid gray',
                                        borderRadius: '10px',
                                        backgroundColor: 'transparent',
                                        padding: '7px 10px',
                                    }}
                                    type="checkbox"
                                />
                            </div>
                        </div>
                    )}
                    {/* Generate Button Below */}

                    <button
                        disabled={isTyping}
                        type="submit"
                        className="w-full h-[3.25rem] mt-2 text-white transcribe flex flex-row justify-center items-center gap-2 rounded-[0.625rem]"
                    >
                        <span className="text-[1rem] leading-[1.5rem]">Generate</span>
                        <div className="h-[1.5rem] w-[1.5rem] rounded-full flex justify-center items-center bg-white">
                            <ArrowRight className="text-[#EC5306] h-4 w-4" />
                        </div>
                    </button>
                </Form>
            </div>

            <div
                className={` ${isMobile ? (isMobileStep === 1 ? 'flex mb-5' : 'hidden') : 'flex'} w-full xl:w-[70%] md:w-auto  overflow-y-scroll h-[90%] flex flex-col gap-y-3  `}
                style={{}}
            >
                {/* {
                        !generate ? (
                            <div className="mt-5 h-full flex-col flex items-center justify-between">
                                <div className="flex flex-col gap-y-3">
                                    <p className="text-center font-medium text-[1.5rem] dark:text-white text-[var(--gmind-black)]">Create Content</p>
                                    <p className="text-center text-gray-400 font-normal">Generate content for course</p>
                                </div>

                                <p className="mb-10 text-center text-[1rem] text-gray-400 font-normal">Enter content description & click Generate to get started</p>
                            </div>
                        ):(
                            <div>
                                {generating ? 
                                    (
                                        <LoadingComponent isMobile={false} width={''} />
                                    ) : (
                                        <MarkdownDesign className="dark:!text-white">{response}</MarkdownDesign>
                                    )
                                }

                            </div>
                        )
                    } */}

                <NewEditorPage
                    docId={docId}
                    setDocId={setDocId}
                    isContent={true}
                    setIsMobileStep={setIsMobileStep}
                    regenerate={regenerate}
                    isBack={isMobile ? false : true}
                    latestRespone={latestRespone}
                    setLatestRespone={setLatestRespone}
                    highlightedTexts={highlightedTexts}
                    setHighlightedTexts={setHighlightedTexts}
                    collaborators={userOnDoc}
                    content={
                        <NewSunEditorComponent
                            latestRespone={latestRespone}
                            setLatestRespone={setLatestRespone}
                            highlightedTexts={highlightedTexts}
                            setHighlightedTexts={setHighlightedTexts}
                            isContent={true}
                            editorInstanceRef={editorInstanceRef}
                            // humanizeded_content={Document.current?.data.ops}
                            // hasAccess={hasAccess}
                            hasAccess={(documentResponse?.role ?? '') !== 'view' ? true : false && !isTyping}
                            initial_content={response}
                            onChangeEditorContent={(e: string) => {
                                setEditorContent(e);

                                const newtext = e;
                                // // console.log('newtext', newtext);
                                // console.log('docId', docId);
                                console.log('userDetails?.userId', userDetails?.userId);
                                sendChanges(docId, userDetails?.userId, e);
                            }}
                            contentHeight={true}
                            // setHighlightedTexts={setHighlightedTexts}
                            // highlightedTexts={highlightedTexts}
                            Response={response}
                            setResponse={setResponse}
                            setIsEditContent={(e) => {
                                setIsEditContent(e);
                            }}
                            contentIsTyping={isTyping}
                            editorContent={editorContent}
                            setEditorContent={setEditorContent}
                        />
                    }
                    showTopNavBar={false}
                    showInvite={documentResponse?.owner ?? false}
                    isTyping={isTyping}
                    Profile={Profile}
                    setGenerate={setGenerate}
                    generate={generate}
                    setEditorContent={setEditorContent}
                    editorContent={editorContent}
                    translatedContent={translatedContent}
                    setTranslatedContent={setTranslatedContent}
                    humanizedContent={humanizedContent}
                    setHumanizedContent={setHumanizedContent}
                    setUserOnDoc={setUserOnDoc}
                    userOnDoc={userOnDoc}
                    editorInstanceRef={editorInstanceRef}
                />

                {/* <div id="controls" className="w-full flex items-center justify-between">
                    <div className="flex gap-x-5 w-[50%] justify-start">
                        <div role="button" onClick={() => regenerate(true)} className="flex items-center">
                            <img src={Profile} alt="" />
                            <p>Humanize</p>
                        </div>
                        {options.map((option, index) => (
                            <div
                                key={index}
                                onClick={option.onClick}
                                className="rounded-[1rem] px-[1rem] py-[0.3rem] bg-[var(--gmind-header)] flex gap-x-3 cursor-pointer"
                            >
                                <img className='w-[1rem] h-[1rem]' src={option.icon} alt="" />
                                <div className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]">
                                    {option.name}
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="flex gap-x-5 w-[50%] justify-end">
                        <div
                            className="hidden md:flex items-center flex-row"
                            style={{ cursor: 'pointer' }}
                            onClick={() => regenerate()}
                        >
                            <img src={Regenerate} alt="" />{' '}
                            <span
                                className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)]"
                                style={{ marginLeft: '2px' }}
                            >
                                Regenerate
                            </span>
                        </div>
                        <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Message} alt="" />
                        <img style={{ cursor: 'pointer' }} onClick={() => {}} src={Like} alt="" />
                        <img
                            style={{ cursor: 'pointer', marginRight: '20px' }}
                            onClick={()=> {}}
                            src={DisLike}
                            alt=""
                        />
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default ContentWriter;
