import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import Input from '@src/pages/dashboard/components/Input';
import { FileContent } from '@src/core/interfaces/chat';
import { ChatContent } from '@src/pages/dashboard/components/interface/interface';
import Multiple from '@assets/essay/quiz1.png';
import True from '@assets/essay/quiz2.png';
import Short from '@assets/essay/quiz3.png';
import Fill from '@assets/essay/quiz4.png';
import Flash from '@assets/essay/quiz5.png';

import {
    HighlightOutlined,
    EditOutlined,
    CloseCircleOutlined,
    FilePdfFilled,
    PaperClipOutlined,
} from '@ant-design/icons';
import { GiMultipleTargets } from 'react-icons/gi';
import { ArrowRight, CheckCheck } from 'lucide-react';
import { Slider } from 'antd';
import { handleAutoGenerateQuiz } from '../helper';
import Quiz from './answer_quiz';
import BackButton from '@src/components/BackButton';
import { useNavigate, useOutletContext } from 'react-router';
import { CircularProgress } from '@mui/material';
import { extractTextFromPDF, extractTextFromDOCX } from '@src/pages/dashboard/function/pdf_doc_helper';
import { toast } from 'react-toastify';
import UploadComponent from './upload_component';

interface QuizQuestion {
    question: string;
    options: string[];
    correctAnswer: string;
    type: string;
    userAnswer?: string;
    questionScore?: number;
}
const QuizMaker = () => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [quizQuestions, setQuizQuestions] = useState<string>('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalVisible2, setIsModalVisible2] = useState(false);

    const [gradeLevel, setGradeLevel] = useState('');
    const [quiz, setQuiz] = useState<QuizQuestion[]>([]);
    const [generating, setGenerating] = useState(false);
    const [count, setCount] = useState(1);
    const [page, setPage] = useState(1);
    const [template, setTemplate] = useState('');
    const [Value, setIsValue] = useState('');
    const [quizState, setQuizState] = useState<QuizQuestion[]>([]);
    const [score, setScore] = useState<number | null>(null);
    const { setShowTop, setShowSidebar } = useOutletContext<any>();
    const [step, setStep] = useState(0);
    const [isloading, setisLoading] = useState(false);

    const handleGradeChange = (e: any) => {
        setGradeLevel(e.target.value);
    };

    const handleCountChange = (value: any) => {
        setCount(value);
    };

    const handleQuizSubmit = () => {
        // console.log(`Score: ${score}/${quizQuestions.length}`);
        // console.log("Results:", results);
    };
    const handleOk = () => {
        setIsModalVisible(false);
    };
    const handleOk2 = () => {
        setIsModalVisible2(false);
    };

    const handleClick = (template: string) => {
        

        setSelectedOption(template);
        setTemplate(template);
    };

    const optionStyle = (template: string) =>
        `basis-full sm:basis-1/2 md:basis-[calc(50%-0.5rem)] max-w-[calc(50%-0.5rem)] rounded-[0.75rem] p-[1rem] bg-[#E8ECEF] dark:bg-[#000B27] shadow shadow-md dark:shadow-gray-500 flex gap-x-3 flex-row items-center justify-between  font-semibold text-lg leading-8
        ${selectedOption === template ? ' border-[0.2rem] border-customOrange text-customOrange' : ''}`;

    const onFinish = async () => {
        setGenerating(true);

        if (Value || quizQuestions) {
            const response = await handleAutoGenerateQuiz(count, template, gradeLevel, quizQuestions, Value);
            console.log(response);
            const quizQuestion = Array.isArray(response) ? response : [response];
            console.log(quizQuestion);
            quizQuestion.map((question) => {
                setQuiz((prevQuiz) => [...prevQuiz, { ...question, userAnswer: '' }]);
            });
            console.log(response);
            console.log(quiz);

            if (response.length > 0) {
                setGenerating(false);
                setPage(2);
            }else{
                setGenerating(false);
                toast.error('Error generating quiz');
            }
        }
    };
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        console.log('Selected file:', file);
        setisLoading(true);

        if (!file) return;
        console.log('Selected file:', file);

        const fileType = file.type;

        if (
            fileType === 'application/pdf' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            // Handle PDF and DOCX files
            const fileTypeValue: 'PDF' | 'DOCX' = fileType === 'application/pdf' ? 'PDF' : 'DOCX';
            try {
                // }

                if (fileType === 'application/pdf') {
                    const doc = await extractTextFromPDF(file);
                    setQuizQuestions(doc);

                    console.log(doc);
                    console.log('exract', quizQuestions);
                } else {
                    const doc = await extractTextFromDOCX(file);
                    setQuizQuestions(doc);
                    console.log(doc);
                }
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('File upload failed');
            } finally {
                setisLoading(false);
            }
        } else {
            console.warn('Unsupported file type:', fileType);
            return;
        }
    };

    useEffect(() => {
        if (generating) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generating]);
    const navigate = useNavigate();

    useEffect(() => {
        setQuizState(quiz);
    }, [quiz]);

    return (
        <>
            {generating && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <div className="flex justify-center items-center">
                            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
                        </div>
                        <p className="text-center mt-4">Generating quiz...</p>
                    </div>
                </div>
            )}
            {page === 1 && !generating && (
                <div className="w-full flex flex-col h-[90%] pt-7 px-[2rem] overflow-y-auto mb-20">
                    <BackButton
                        onclick={() => {
                            navigate(-1);

                            setQuiz([]);
                        }}
                    />

                    <div className=" flex flex-col items-center mt-10 w-full ">
                        <div className="">
                            <h1 className="text-[2rem] md:text-[2.5rem] font-bold md:h-[4rem]  font-bold h-[4rem] text-center text-gray-800 dark:text-white ">
                                Quiz Me
                            </h1>
                            <p className="text-gray-600 text-center dark:text-gray-500 text-[1rem] md:text-[1.125rem] text-center">
                                Interactive knowledge tester
                            </p>
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col  gap-y-3  items-center" style={{ marginTop: '2.5rem' }}>
                        <div className="max-w-5xl md:w-[60%] ">
                            <div className="flex flex-wrap  w-full gap-4 justify-center ">
                                <div className="flex flex-wrap  w-full gap-4 justify-center ">
                                    {[
                                        { label: 'Multiple Choice Quiz', icon: Multiple },
                                        { label: 'True/False Quiz', icon: True },
                                        { label: 'Short Answer Quiz', icon: Short },
                                        { label: 'Fill in the Blank Quiz', icon: Fill },
                                        { label: 'Flashcard Quiz', icon: Flash },
                                    ].map((op) => (
                                        <div
                                            key={op.label}
                                            className={optionStyle(op.label)}
                                            onClick={() => {
                                                console.log('Option:', op);
                                                handleClick(op.label);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <div className="flex flex-row gap-x-3 items-center">
                                                <img src={op.icon} alt={op.label}  className=' w-8 h-8 md:w-auto' />
                                                <span className="text-gray-800 font-semibold  dark:text-gray-200 text-sm md:text-lg   ">
                                                    {op.label}
                                                </span>
                                            </div>

                                            <ArrowRight className=" hidden md:block w-4 h-4 dark:text-white" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-row gap-3 items-center " style={{ paddingTop: '2.15rem' }}>
                            <button
                                onClick={() => {
                                    if (!template) {
                                        toast.info('Please select a template');
                                        return;
                                    }

                                    setIsModalVisible(true);
                                }}
                                className="px-4 py-2  w-[19.44rem] rounded-[0.75rem] bg-customOrange text-white "
                            >
                                {' '}
                                Attach Document
                            </button>
                        </div>
                    </div>

                    {isModalVisible && (
                        <Modal
                            title={
                                <p className="font-semibold text-[1.125rem] leading-6 border-b pb-3 mb-3 border-gray-400">
                                    {'Attach Document you want to summarize'}
                                </p>
                            }
                            open={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleOk}
                            footer={[
                                <Button
                                    key="dontShowAgain"
                                    onClick={() => setIsModalVisible(false)}
                                    className="p-[9.5px] rounded-[0.75rem]"
                                >
                                    Cancel
                                </Button>,
                                <button
                                    className=" ml-3 px-[9.5px]  py-1.5 rounded-[0.75rem] bg-customOrange  text-white"
                                    key="ok"
                                    onClick={() => {
                                        if (!quizQuestions) {
                                            toast.error('Please attach document');
                                            return;
                                        }
                                        onFinish();
                                    }}
                                >
                                    Generate
                                </button>,
                            ]}
                        >
                            <UploadComponent
                                extractedFile={quizQuestions}
                                setExtractedFile={setQuizQuestions}
                                isNotebook={true}
                            />

                            <p className="text-md py-2 "> Additional Information (optional)</p>

                            <textarea
                                name=""
                                id="my_input"
                                value={Value}
                                onChange={(e) => setIsValue(e)}
                                placeholder="Enter additional information"
                                rows={3}
                                className="border border-gray-500 p-3 w-full rounded-[0.75rem]"
                            />
                            <div style={{ marginBottom: '16px' }}>
                                <label htmlFor="grade-level" style={{ display: 'block', marginBottom: '8px' }}>
                                    Grade Level
                                </label>
                                <select
                                    id="grade-level"
                                    style={{ width: '100%' }}
                                    value={gradeLevel}
                                    onChange={handleGradeChange}
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                >
                                    <option value="" disabled className="text-gray-500">
                                        Select Grade Level
                                    </option>
                                    {Array.from({ length: 12 }, (_, i) => (
                                        <option key={`Grade-${i + 1}`} value={`Grade ${i + 1}`}>
                                            Grade {i + 1}
                                        </option>
                                    ))}
                                    <option value="Post Graduate">Post Graduate</option>
                                </select>
                            </div>

                            <div style={{ marginBottom: '16px' }}>
                                <label htmlFor="count-slider" style={{ display: 'block', marginBottom: '8px' }}>
                                    Select Number Count
                                </label>
                                <Slider
                                    id="count-slider"
                                    min={1}
                                    max={100}
                                    value={count}
                                    onChange={handleCountChange}
                                />
                                <div>Selected Count: {count}</div>
                            </div>
                           
                        </Modal>
                    )}
                </div>
            )}

            {page === 2 && (
                <div className="flex flex-col gap-y-6 pt-7 px-[2rem]  h-screen " style={{ overflow: 'scroll' }}>
                    <BackButton
                        onclick={() => {
                            setPage(1);
                            setQuiz([]);
                        }}
                    />

                    <Quiz
                        questions={quiz}
                        onSubmit={handleQuizSubmit}
                        handleGenerate={() => setIsModalVisible2(true)}
                        quizState={quizState}
                        setQuizState={setQuizState}
                        score={score}
                        setScore={setScore}
                    />

                    {isModalVisible2 && (
                        <Modal
                            open={isModalVisible2}
                            onOk={handleOk2}
                            onCancel={handleOk2}
                            footer={[
                                <Button
                                    key="Cancel"
                                    className=" ml-3 p-2 rounded bg-gray-400  text-white"
                                    onClick={handleOk2}
                                >
                                    Cancel
                                </Button>,
                                <button
                                    className=" ml-3 p-2 rounded bg-customOrange  text-white"
                                    onClick={() => {
                                        setQuizState([]);
                                        setScore(null);
                                        setIsModalVisible2(false);
                                        setQuiz([]);
                                        onFinish();
                                    }}
                                >
                                    {' '}
                                    Regenerate
                                </button>,
                            ]}
                        >
                            <div style={{ marginBottom: '16px' }}>
                                <label htmlFor="grade-level" style={{ display: 'block', marginBottom: '8px' }}>
                                    Grade Level
                                </label>
                                <select
                                    id="grade-level"
                                    style={{ width: '100%' }}
                                    value={gradeLevel}
                                    onChange={handleGradeChange}
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                >
                                    <option value="" disabled className="text-gray-500">
                                        Select Grade Level
                                    </option>
                                    {Array.from({ length: 12 }, (_, i) => (
                                        <option key={`Grade-${i + 1}`} value={`Grade ${i + 1}`}>
                                            Grade {i + 1}
                                        </option>
                                    ))}
                                    <option value="Post Graduate">Post Graduate</option>
                                </select>
                            </div>

                            <div style={{ marginBottom: '16px' }}>
                                <label htmlFor="count-slider" style={{ display: 'block', marginBottom: '8px' }}>
                                    Select Number Count
                                </label>
                                <Slider
                                    id="count-slider"
                                    min={1}
                                    max={100}
                                    value={count}
                                    onChange={handleCountChange}
                                />
                                <div>Selected Count: {count}</div>
                            </div>
                         
                        </Modal>
                    )}
                </div>
            )}
        </>
    );
};
export default QuizMaker;
