import useFormStore from '@src/providers/formStore';
import usePowerPointStore from '@src/providers/store';
import React, { useEffect, useState } from 'react';
import FirstForm from './component/Forms/first-form';
import DashboardFrame from '@src/components/DashboardFrame';
import { ChatType, getHistory, handleDeleteHistory } from '../../endpoints';
import SecondForm from './component/Forms/second-form';
import ThirdForm from './component/Forms/third-form';
import PptViewer from './component/Forms/preview-slide';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router';


function SlideMaker({setCurrentPage}: {setCurrentPage?:any}) {
    const { currentStep } = useFormStore();
    const { ...formState } = usePowerPointStore();
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState([]);
  
    const {
        getHistory,
        setChatList,
    } = useOutletContext<any>();

    const pageHistory = 'slide';

    useEffect(() => {
        if (window.innerWidth >= 820) {
            setIsMobile(false);
        }
    }, [window.innerWidth]);
 

    const renderStepContent = (setCurrentPage: any) => {
        switch (currentStep) {
            case 0:
                return <FirstForm setCurrentPage={setCurrentPage} />;
            case 1:
                return <SecondForm />;
           
            default:
                return <div>Unknown Step</div>;
        }
    };



    return (
      
            <div className="w-full px-10">{renderStepContent(setCurrentPage)}</div>
        
    );
}

export default SlideMaker;
