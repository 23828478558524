import { Button, Form, Modal } from 'antd';
import { LessonMaterialProps } from '../interface';
import { toast } from 'react-toastify';
import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Input from '@src/pages/dashboard/components/Input';
import { FileContent } from '@src/core/interfaces/chat';
import { ChatContent } from '@src/pages/dashboard/components/interface/interface';
import { Chats, ChatType, getHistory } from '@src/pages/dashboard/endpoints';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import { BASE_URL } from '@src/utils/constants';

import { io, Socket } from 'socket.io-client';
import { useOutletContext, useNavigate } from 'react-router';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { openCurriculumEditor } from '@src/utils/app_functions';
import { prompts } from '@src/utils/prompts';

import { v4 as uuidv4 } from 'uuid';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import Profile from '@assets/Avatar.svg';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import BackButton from '@src/components/BackButton';
import { extractTextFromPDF, extractTextFromDOCX } from '@src/pages/dashboard/function/pdf_doc_helper';
import { chatRoutePages } from '@src/utils/app_routes';
import BulletSummary from '@assets/essay/summary1.png';
import Short from '@assets/essay/summary2.png';
import Detailed from '@assets/essay/summary3.png';
import SoundBite from '@assets/essay/summary4.png';
import { ArrowRight } from 'lucide-react';
import UploadComponent from './upload_component';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const NoteSummarizer = () => {
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [Value, setValue] = useState('');

    const [uploadedFile, setUploadedFile] = useState('');

    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState<[]>([]);
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState<any>([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [generate, setGenerate] = useState<boolean>(false);
    const [generating, setGenerating] = useState(false);
    const [promptSent, setPromptSent] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [canNavigate, setCanNavigate] = useState(false);
    const [option, setOption] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();

    const {
        setShowTop,
        setShowSidebar,
        details,

        response,
        setResponse,
    } = useOutletContext<any>();

    const navigate = useNavigate();
    const baseurl = BASE_URL;
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [userInput, setUserInput] = useState('');
    const [isloading, setisLoading] = useState(false);
    const [extractedFile, setExtractedFile] = useState<string>('');
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]); // Supports multiple selection
    const [currentStep, setCurrentStep] = useState<number>(1); // Step management
    const steps = ['Attach a document', 'Select summarization options', 'Generate summary']; // Step descriptions

    const handleStepChange = (step: number) => setCurrentStep(step);

    const handleCardClick = (option: string) => {
        setSelectedOptions((prevOptions) => {
            if (prevOptions.includes(option)) {
                // If the option exists, remove it
                return prevOptions.filter((opt) => opt !== option);
            } else {
                // If the option doesn't exist, add it
                return [...prevOptions, option];
            }
        });

        setTemplate((prev: any) =>
            prev === null
                ? template
                : typeof prev === 'string'
                  ? template
                  : prev.includes(template)
                    ? prev.filter((o) => o !== template)
                    : [...prev, template],
        );
        console.log(template);
    };

    const optionStyle = (template: string) =>
        `rounded-[0.75rem] p-[1rem] bg-[#E8ECEF] dark:bg-[#000B27] shadow shadow-md dark:shadow-gray-500 flex gap-x-3 flex-row items-center justify-between  font-semibold text-lg leading-8
      ${selectedOptions.includes(template) ? ' border-[0.1rem] border-customOrange text-customOrange' : 'dark:text-white'}`;

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
            console.log('Received data:', data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            // setShowEditorButton(true);
            const { assistant } = data;
            setStreamEndMessage(assistant);
            setIsTyping(false);
            setCanNavigate(true);
            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (generate || showingHistory) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (response) {
            console.log(response);
            setEditorContent(response);
            setTranslatedContent(response);
        }
    }, [response]);

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }

        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: summaryTemplate(String(selectedOptions), Value),
                            type: 'gpt-4',
                        },

                        msgs,
                    ],
                    userId: details?.userId,
                },
            });
            // await handleAddHistory([{ role: 'user', content: message }, msgs], pageHistory, {});
            // getPageHistory();
        } catch (error) {
            console.log(error);
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        console.log('Selected file:', file);
        setisLoading(true);

        if (!file) return;
        console.log('Selected file:', file);

        const fileType = file.type;

        if (
            fileType === 'application/pdf' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            // Handle PDF and DOCX files
            const fileTypeValue: 'PDF' | 'DOCX' = fileType === 'application/pdf' ? 'PDF' : 'DOCX';
            try {
                // }

                if (fileType === 'application/pdf') {
                    const doc = await extractTextFromPDF(file);
                    setExtractedFile(doc);
                    setUserInput(doc);
                    console.log(doc);
                    console.log('exract', extractedFile);
                } else {
                    const doc = await extractTextFromDOCX(file);
                    setExtractedFile(doc);
                    console.log(doc);
                    setUserInput(doc);
                }
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('File upload failed');
            } finally {
                setisLoading(false);
            }
        } else {
            console.warn('Unsupported file type:', fileType);
            return;
        }
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleDontShowAgain = () => {
        localStorage.setItem('showAttachDocumentModal', 'true'); // Store the user's preference
        setIsModalVisible(false);
    };

    const onFinish = (values: string) => {
        const promptMessage = `
        You are a helpful assistant.
        Follow the instructions below:
        Using this ${template} create a summary of the following notes:
        ${extractedFile ? ` ${template} template. The file is attached below. ${extractedFile}` : ` Summarize the following notes ${values} using the ${template} template.`}

        - Use this ${Value} as the instruction to summarize {!Important}
       
        `;

        onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
    };

    return (
        <div
            className={`${!generate && !showingHistory ? 'px-10 pt-5' : ''} w-full flex flex-col h-full overflow-y-auto md:h-screen  items-center`}
        >
            <div className="flex flex-row items-start justify-start mt-10 w-full">
                {!generate && !showingHistory && (
                    <BackButton onclick={() => navigate(`/${chatRoutePages.studentResources}`)} />
                )}
            </div>
            {!generate && !showingHistory ? (
                <>
                    <div className="flex flex-col  items-center text-start py-6">
                        <h1 className=" text-[2rem] md:text-[2.5rem] font-bold md:h-[4rem] text-center text-gray-800 dark:text-white">
                            Note Summarizer
                        </h1>
                        <p className="text-gray-600 text-[1rem] md:text-[1.125rem] text-center dark:text-white">Summarize your notes with ease</p>
                    </div>

                    {/* Steps Navigation */}

                    {/* Current Step */}
                    <div className="flex flex-col items-center mt-5 w-full">
                        <div className="grid grid-cols-2 gap-4 md:grid-cols-2  ">
                            {[
                                { label: 'Bullet Points', icon: BulletSummary },
                                { label: 'Short Paragraph', icon: Short },
                                { label: 'Detailed Summary', icon: Detailed },
                                { label: 'Sound Bite', icon: SoundBite },
                            ].map((op) => (
                                <div
                                    key={op.label}
                                    className={optionStyle(op.label)}
                                    onClick={() => {
                                        console.log('Option:', op);
                                        handleCardClick(op.label);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="flex flex-row gap-x-3 items-center">
                                        <img src={op.icon} alt={op.label} className=' w-8 h-8 md:w-auto' />
                                        <span className="text-gray-800 font-semibold  dark:text-gray-200 text-sm md:text-lg   ">
                                            {op.label}
                                        </span>
                                    </div>

                                    <ArrowRight className="hidden md:block w-4 h-4 dark:text-white" />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-row gap-3 items-center " style={{ paddingTop: '2.15rem' }}>
                        <button
                            onClick={() => {
                                if (!template) {
                                    toast.info('Please select a template');
                                    return;
                                }

                                setIsModalVisible(true);
                            }}
                            className="px-4 py-2  w-[19.44rem] rounded-[0.75rem] bg-customOrange text-white "
                        >
                            {' '}
                            Attach Document
                        </button>
                    </div>

                    {isModalVisible && (
                        <Modal
                            title={
                                <p className="font-semibold text-[1.125rem] leading-6 border-b pb-3 mb-3 border-gray-400">
                                    {'Attach Document you want to summarize'}
                                </p>
                            }
                            open={isModalVisible}
                            onOk={handleOk}
                            onCancel={handleOk}
                            className="relative"
                            footer={[
                                <Button
                                    key="dontShowAgain"
                                    onClick={() => setIsModalVisible(false)}
                                    className="p-[9.5px] rounded-[0.75rem]"
                                >
                                    Cancel
                                </Button>,
                                <button
                                    className=" ml-3 px-[9.5px]  py-1.5 rounded-[0.75rem] bg-customOrange  text-white"
                                    key="ok"
                                    onClick={() => {
                                        if(!extractedFile){
                                            toast.error('Please attach document')
                                            return;
                                        }
                                        onFinish(Value)
                                    }}
                                >
                                    Generate
                                </button>,
                            ]}
                        >
                            <UploadComponent
                                extractedFile={extractedFile}
                                setExtractedFile={setExtractedFile}
                                isNotebook={true}
                            />

                            <p className="text-md py-2 "> Additional Information (optional)</p>

                            <textarea
                                name=""
                                id="my_input"
                                value={Value}
                                onChange={(e) => setValue(e)}
                                placeholder="Enter additional information"
                                rows={3}
                                className="border border-gray-500 p-3 w-full rounded-[0.75rem]"
                            />
                        </Modal>
                    )}
                </>
            ) : (
                <div className="w-full flex flex-col gap-0 ">
                    <NewEditorPage
                        docId={docId}
                        setDocId={setDocId}
                        editorInstanceRef={editorInstanceRef}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        latestRespone={latestRespone}
                        setLatestRespone={setLatestRespone}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        regenerate={onFinish}
                    />
                </div>
            )}
        </div>
    );
};

export default NoteSummarizer;

const summaryTemplate = (condition: string, value: string) => {
    const text = `
    Summarize the text provided in the 'Initial Text' section according to the following criteria:

    
    - Condense the content while preserving the key points and essential details.
    - If a specific word limit is provided in the 'Number of words to summarize to' section, adjust the summary to match that length.
    - Follow the specified condition: **${condition}**. 
    - Use ${value} as the template for the instruction.

    if (${condition} === 'Bullet Points') {
        // Provide the summary in bullet point format.
        Examples: 
        - Key Point 1: Summarize the main idea in a concise way.
        - Key Point 2: Focus on the technical or essential aspects.
        - Key Point 3: Avoid unnecessary details, keeping each bullet concise.
    } else if (${condition} === 'Short Paragraph') {
        // Provide a short paragraph summary without any bullet points.
        Examples:
        - "This is a compact summary that captures the essence of the text in a few sentences."
        - "The content has been condensed into a brief paragraph for clarity and quick understanding."
        - "All key points are summarized in a concise and readable format."
    } else if (${condition} === 'Detailed Summary') {
        // Write a detailed and expanded summary covering all key aspects.
        Examples:
        - "This summary expands on the key details, explaining the content comprehensively."
        - "Each critical point is addressed, providing a deep dive into the topic."
        - "This detailed summary ensures no information is left out while maintaining readability."
    } else if (${condition} === 'Analysis') {
        // Provide an analytical breakdown of the text.
        Examples:
        - "This section critically evaluates the text, providing insights into its implications."
        - "The analysis identifies patterns, themes, and potential impacts of the content."
        - "A breakdown of the strengths, weaknesses, opportunities, and threats in the text."
    } else if (${condition} === 'Most used key words') {
        // Highlight the most frequently used keywords in the text.
        Examples:
        - "Key terms: governance, compliance, risk, assessment, auditing."
        - "Frequently used words: innovation, strategy, leadership, execution."
        - "Highlighted terms: framework, security, policy, development."
    } else if (${condition} === 'Soundbite') {
        // Create a concise, impactful soundbite.
        Examples:
        - "A punchy takeaway like 'Simplifying compliance for a secure future.'"
        - "A memorable one-liner: 'Driving risk management excellence, one framework at a time.'"
        - "Short impactful phrase: 'Securing IT systems, safeguarding business growth.'"
    } else {
        // Default to a concise paragraph summary if no valid condition is provided.
        Examples:
        - "A brief paragraph summarizing the main ideas of the text."
        - "This is a default summary that captures the key points in a compact way."
        - "All essential details are provided in a single, concise paragraph."
    }

    - Include any requested specific details when applicable.
    - Ensure the summary is clear, concise, and retains the essential meaning of the original text.
    - Do not explain the summary or give context; simply provide the summary itself.

   
    Condition: **${condition}**
`;
    return text;
};
