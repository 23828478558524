import { Button, Form, Input, Select } from 'antd';
import { LessonMaterialProps } from '../interface';
import { toast } from 'react-toastify';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { gradeOptions } from '../../curriculum/constants';
import { useEffect, useRef, useState } from 'react';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import './style.scss';
import { extractTextFromPDF } from '@src/pages/dashboard/function/pdf_doc_helper';

const QuoteOfTheDay = ({ onGenerate, setTopic }: LessonMaterialProps) => {
    let grades = gradeOptions();

    const recognitionRef = useRef<any>(null);
    const [recording, setRecording] = useState(false);
    const [form] = Form.useForm();
    const [finalTranscript, setFinalTranscript] = useState('');

    const [recordingField, setRecordingField] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input
    const [docData, setDocData] = useState<string | ArrayBuffer | null>(null);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const content = e.target?.result;

                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);

                    setDocData(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };
            reader.onerror = () => console.error('Error reading file');

            reader.readAsArrayBuffer(file);
        }
    };

    const onPaperClipClick = () => {
        // Programmatically trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleMicrophoneClicked = (field: string) => {
        if (recording && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }

        setRecordingField(field); // Set the current field being recorded
        handleMicrophone(field);
        console.log(recordingField);
    };
    async function handleMicrophone(field: string) {
        if (recording) {
            stopRecording();
        } else {
            startRecording(field);
        }
    }

    const startRecording = (field: string) => {
        try {
            toast.success('Recording in progress...');
            setRecording(true);
            recognitionRef.current = new (window as any).webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setFinalTranscript(texts.join(' '));
                console.log('text', texts);
                console.log('final:', finalTranscript);
                // Use getFieldsValue and setFieldsValue for Antd form
                // Use getFieldsValue and setFieldsValue for Antd form
                const fields = form.getFieldsValue();
                console.log('Current fields:', fields);

                // Set the updated value in the correct field
                form.setFieldsValue({
                    [field as string]: texts.join(''), // Update the specific field
                });
                console.log('Current fields:', fields);

                console.log(`Updated ${field}:`, texts);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = () => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording(false); // Reset after stopping
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const onFinish = (values: any) => {
        let promptMessage = '';

        const topic = values.topic;
        const gradeLevel = values.gradeLevel;

        // check if all data is passed
        if (!topic || !gradeLevel) {
            toast.error('Please enter all prompts field');
            return;
        }

        promptMessage = `Generate an inspirational quote of the day from this topic ${topic} for this grade level students ${gradeLevel}`;

        onGenerate(promptMessage, false);
    };
    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    }, [fileInputRef]);

    return (
        <div className="flex items-center flex-col min-h-screen px-4 mt-4">
            <Form
                form={form}
                onFinish={onFinish}
                className="flex flex-col py-4 px-10 dark:text-white space-y-4"
                layout="vertical"
            >
                <h1 className="text-3xl font-bold dark:text-white text-center">Quote of the Day</h1>
                <p className="text-[#6C7275] dark:text-white/50 text-center">
                    Generate quote of the day suggestions based on any topic.
                </p>

                <div className="bg-[#F7F7F7] dark:bg-[#000B27] rounded-[0.75rem] gap-[1.5rem] p-[1.5rem] shadow-md w-full md:w-[38.625rem] border border-[#E6E6E6] dark:border-white ">
                    <Form form={form} onFinish={onFinish} className="flex flex-col space-y-4" layout="vertical">
                        {/* Topic Input */}
                        <Form.Item
                            label="Quotes about…"
                            name="topic"
                            rules={[{ required: true, message: 'Please enter a topic' }]}
                        >
                            <div
                                className=" w-full relative flex items-start bg-white dark:bg-[#FFFFFF33] border  border-[#D1D5DB] dark:border-white border-gray-700 rounded-[10px] p-[14px]"
                                style={{  gap: '8px' }}
                            >
                                <textarea
                                    value={form.getFieldValue('topic') || docData}
                                    onChange={(e) => {
                                        setDocData(e.target.value);
                                        form.setFieldsValue({ topic: e.target.value });
                                    }}
                                    className="p-2 border-none w-full dark:text-white focus:outline-none bg-transparent placeholder-[#6B7280] dark:placeholder-[#D9D9D9]"
                                    placeholder="Enter a topic"
                                    style={{
                                        outline: 'none',
                                        boxShadow: 'none',
                                        height: '100%',
                                        minHeight: '80px',
                                        maxHeight: 'calc(100% - 32px)',
                                        overflow: 'auto',
                                    }}
                                />

                                {/* Icons at Bottom Left */}
                                <div className="absolute bottom-2 left-2 flex gap-x-3 text-gray-500">
                                    <AudioOutlined
                                        className={`hover:text-green-500 transition-colors duration-200 ${
                                            recording['topic']
                                                ? 'dark:text-customOrange'
                                                : 'dark:text-white text-gray-500'
                                        }`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleMicrophoneClicked('topic')}
                                    />
                                    <PaperClipOutlined onClick={onPaperClipClick} className="dark:text-white" />
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept=".doc,.docx,.pdf"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, 'topic')}
                                    />
                                </div>
                            </div>
                        </Form.Item>

                        {/* Grade Level Selection */}
                        <Form.Item
                            label="Grade Level"
                            name="gradeLevel"
                            rules={[{ required: true, message: 'Please select a grade level' }]}
                            className="w-full"
                        >
                           <select className="w-full rounded-md h-[3rem] bg-white dark:bg-[#ffffff33] dark:text-white p-3 border-[#D1D5DB] dark:border-white border">
                            {grades.map((item, index) => (
                                <option key={index} value={item || 'Select grade level'}>
                                    {item || 'Select grade level'}
                                </option>
                            ))}
                        </select>
                        </Form.Item>
                    </Form>
                </div>

                {/* Generate Button (outside the container) */}
                <div className="flex justify-center mt-4">
                    <button
                        type="submit"
                        className="no-hover-effect w-[311px] h-[52px] bg-customOrange text-white rounded-[12px] flex items-center justify-center"
                    >
                        Generate
                    </button>
                </div>
            </Form>
        </div>
    );
};

export default QuoteOfTheDay;
