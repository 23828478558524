/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, Select } from 'antd';
import { LessonMaterialProps, LetterWriterProps, TextTranslatorProps } from '../interface';
import { toast } from 'react-toastify';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import './style.scss';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import { extractTextFromPDF } from '@src/pages/dashboard/function/pdf_doc_helper';
import { textTranslatorLangOptions } from '../constants';
import { Tooltip } from 'react-tooltip';

const { Option } = Select;

import { v4 as uuidv4 } from 'uuid';
import Profile from '@assets/Avatar.svg';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useNavigate, useOutletContext } from 'react-router';
import { Chats, ChatType } from '@src/pages/dashboard/endpoints';
import { io, Socket } from 'socket.io-client';
import { useUserContext } from '@src/context/UseProvider';
import { BASE_URL } from '@src/utils/constants';
import form from 'antd/es/form';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { prompts } from '@src/utils/prompts';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import BackButton from '@src/components/BackButton';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}
const TextTranslator = () => {
    const recognitionRef = useRef<any>(null);
    const [recording, setRecording] = useState(false);
    const [form] = Form.useForm();
    const [finalTranscript, setFinalTranscript] = useState('');

    const [recordingField, setRecordingField] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input
    const [docData, setDocData] = useState<string | ArrayBuffer | null>(null);
    const [rememberMe, setRememberMe] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [histories, setHistories] = useState<any>([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    // socket.io
    // const [response, setResponse] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [promptSent, setPromptSent] = useState('');
    const [newPromptSent, setNewPromptSent] = useState('');
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [isMobile, setIsMobile] = useState(true);
    const UrlRef = useRef<string>('');
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [canNavigate, setCanNavigate] = useState(false);
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [topic, setTopic] = useState('');
    const [audience, setAudience] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const {
        setShowMobileHistory,
        setShowTop,
        setShowSidebar,
        setRightComponentName,
        setRightComponentPlaceholder,
        setRightComponentData,
        setHistory,

        setShowPagePath,

        setCanNavigateHome,
        setShowExpiredModal,
        setShowCompleteProfileModal,
        setIsAffiliate,
        setMenu,
        setShowInput,
        setShowHistoryOptions,
        setRightComponentClick,
        setOnDetailsFetched,
        setOnDeleteAllHistory,
        setOnDeleteHistory,
        setOnClickPage,
        setSelectedHistory,
        setValue,
        setShowRightComponent,
        setSchoolAdminPageName,
        setShowSchoolHeader,
        setShowHistory,
        response,
        setResponse,
        details,
    } = useOutletContext<any>();

    const pageHistory = 'assessments';
    const navigate = useNavigate();
    const baseurl = BASE_URL;

    const bottomRef = useRef<HTMLDivElement>(null);
    const editorInstanceRef = useRef<any>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const { userDetails } = useUserContext();
    const { error, loading, fetchDocument, document: textDocument, documentResponse } = useDocumentContext();
    const [editorContent, setEditorContent] = useState<any>('');
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [isNotLoggedIn, setIsNotLoggedIn] = useState(false);

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setNewPromptSent('');
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content:  prompts.textTranslator,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId,
                },
            });
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            console.log(data);
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);

            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);
            

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);

        });

        socket.on('user_log_in', (data: string) => {
            console.log(data);

            setIsNotLoggedIn(true);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);


    const regenerate = () => {
        onGenerateHandler(promptSent);
        setGenerate(true);
        setGenerating(true);
        setIsTyping(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const content = e.target?.result;

                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);

                    setDocData(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };
            reader.onerror = () => console.error('Error reading file');

            reader.readAsArrayBuffer(file);
        }
    };

    const onPaperClipClick = () => {
        // Programmatically trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleMicrophoneClicked = (field: string) => {
        if (recording && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }

        setRecordingField(field); // Set the current field being recorded
        handleMicrophone(field);
        console.log(field);
    };
    async function handleMicrophone(field: string) {
        if (recording) {
            stopRecording();
        } else {
            startRecording(field);
        }
    }

    const startRecording = (field: string) => {
        try {
            toast.success('Recording in progress...');
            setRecording(true);
            recognitionRef.current = new (window as any).webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setFinalTranscript(texts.join(' '));
                console.log('text', texts);
                console.log('final:', finalTranscript);
                // Use getFieldsValue and setFieldsValue for Antd form
                // Use getFieldsValue and setFieldsValue for Antd form
                const fields = form.getFieldsValue();
                console.log('Current fields:', fields);

                // Set the updated value in the correct field
                form.setFieldsValue({
                    [field as string]: texts.join(''), // Update the specific field
                });
                console.log('Current fields:', fields);

                console.log(`Updated ${field}:`, texts);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = () => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording(false); // Reset after stopping
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };
    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    }, [fileInputRef]);

    const onFinish = (values: any) => {
        let promptMessage = '';

        const originalText = values.originalText;
        const language = values.language;

        // check if all data is passed
        if (!originalText || !language) {
            toast.error('Please enter text to summarize');
            return;
        }

        promptMessage = `Translate the following text to ${language} Language: ${originalText}. Please ensure that the translation preserves the original meaning and context.`;
        console.log(promptMessage);
        onGenerateHandler(promptMessage);
        setGenerate(true);
    };

    if (generate || showingHistory){
        setShowTop(false)
        setShowSidebar(false)   
    } else {
        setShowTop(true)
        setShowSidebar(true)
    }

    return (
        <div className="flex flex-col  w-full h-screen px-[2rem] pt-5">
             {!generate && (
                <BackButton
                    onclick={() => {
                        navigate(-1);
                    }}
                />
            )}

            {!generate && !showingHistory ? (
                <Form form={form} onFinish={onFinish} className="flex flex-col items-center pt-4  space-y-4" layout="vertical">
                    <h1 className="text-xl md:text-[2.5rem] font-bold dark:text-white text-center">Text Translator</h1>
                    <p className=" text-[1.125rem] dark:text-gray-300 text-center">
                        Easily translate your text while preserving meaning and context, across multiple languages.
                    </p>

                    <div className="md:w-[38.625rem] w-full bg-[#F7F7F7] dark:bg-[#000B27] shadow-lg rounded-[0.75rem] p-[1.5rem] border border-[#E6E6E6] dark:border-[#FFFFFF33]  space-y-[24px]">

                    <Form.Item
                        label="Initial Text"
                        name="originalText"
                        rules={[{ required: true, message: 'Please enter the original text' }]}
                    >
                        <div className="flex items-start border bg-white dark:bg-[#FFFFFF33] border-gray-700 dark:border-gray-200  rounded-[0.75rem]">
                            <div className=" flex gap-y-3 flex-col px-3 py-2 text-gray-500">
                                <AudioOutlined
                                    className={`hover:text-green-500 dark:text-white transition-colors duration-200 ${recording ? 'text-customOrang' : 'text-gray-500 '}`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleMicrophoneClicked('originalText')}
                                />
                                <PaperClipOutlined className="dark:text-white" onClick={onPaperClipClick} />

                                {/* <Tooltip
                                    className="custom-tooltip flex"
                                    title="Attach file"
                                    placement="left"
                                    overlayInnerStyle={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        border: '1px solid #ddd',
                                        borderRadius: '4px',
                                    }}
                                >
                                    
                                </Tooltip> */}

                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept=".doc,.docx,.pdf,.txt"
                                    style={{ display: 'none' }} // Hide the input element
                                    onChange={(e) => handleFileChange(e, 'originalText')}
                                />
                            </div>
                            <textarea
                                value={form.getFieldValue('originalText')} // This makes it controlled
                                onChange={(e) => {
                                    setDocData(e.target.value); // Update both docData and form values
                                    form.setFieldsValue({ originalText: e.target.value });
                                }}
                                rows={7}
                                className="p-2 rounded-none border-none dark:text-white w-full focus:outline-none bg-transparent hover:bg-transparent placeholder-gray-700 dark:!placeholder-white"
                                placeholder="Please enter the text you would like to summarize. This tool is most effective for texts with 1,500 words or fewer."
                                style={{ outline: 'none', boxShadow: 'none', borderColor: 'transparent' }} // Remove outline and box shadow
                            />
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="Language to translate to"
                        name="language"
                        rules={[{ required: true, message: 'Please select platforms' }]}
                    >
                        <Select
                            className="w-full rounded-md h-[3rem] bg-white dark:bg-transparent"
                            placeholder="Choose language"
                        >
                            {textTranslatorLangOptions.map((value, index) => (
                                <Option value={value.value}>{value.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    </div>
                    <Form.Item>
                        <button
                            type="submit"
                            className=" no-hover-effect mt-2 w-full md:w-[8rem] py-2 px-4 bg-customOrange text-white rounded hover:bg-[var(--gmind-orange)]"
                        >
                            Translate
                        </button>
                    </Form.Item>
                </Form>
            ) : (
                <div className="w-full flex flex-col gap-0  h-full overflow-auto">
                    <NewEditorPage
                        editorInstanceRef={editorInstanceRef}
                        regenerate={regenerate}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        latestRespone={latestRespone}
                        setLatestRespone={setHighlightedTexts}
                        docId={undefined}
                        setDocId={''}
                    />
                </div>
            )}
        </div>
    );
};

export default TextTranslator;
