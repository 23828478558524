import { newGenerationHomeDataProps } from "../interface";
import syllabusImg from '@icons/syllabus.svg';
import lessonPlanner from '@icons/lesson_planner.svg';
import ShareImg from '@public/share.svg';
import Copy from '@public/copy.svg';
import DownloadImage from '@public/download.svg';
import research from '@icons/research.svg';
import youtube from '@assets/essay/youtube-logo-png-46031 (1).png';
import article from '@icons/article.svg';
import comprehensive from '@icons/comphrensive.svg';
import socialMedia from '@assets/essay/resume.jpg';
import letterImg from '@icons/letter.svg';
import { copyData, handleShare } from "@src/utils/app_functions";
import { PromptOptionsType } from "@src/core/interfaces/general";
import activity from '@assets/activity.svg';
import { AssessementWhiteImage } from "@src/components/Sidebar/images";
import { chatRoutePages } from "@src/utils/app_routes";

import quizMeImg from '@assets/essay/quizMeImg.png';
import noteSummImg from '@assets/essay/noteSummImg.png';
import interviewPrepImg from '@assets/essay/interviewPrepImg.png';
import coverLetterImg from '@assets/essay/coverLetterImg.png';
import assignmentHelpImg from '@assets/essay/assignmentHelpImg.png';
import aplusImg from '@assets/essay/aplusImg.png';




const newGenerationHomeData: Array<newGenerationHomeDataProps> = [
    {
        img: noteSummImg,
        head: 'Note Summarizer',
        text: 'Summarize your notes in a concise and organized manner.',
        pageIndex: 2,
        naviagatePage:true,
        pageName: "Note Summarizer",
        label: chatRoutePages.noteSummarizer,
    },
  
   
    {
        img: aplusImg,
        imgElement: AssessementWhiteImage(),
        head: 'A+ Essay',
        text: 'Generate a comprehensive essay on any topic.',
        pageIndex: 4,
        pageName: "A+ essay",
        naviagatePage: true,
        label: chatRoutePages.aPlusEssay,
    },
    
    {
        img: quizMeImg,
        imgElement: AssessementWhiteImage(),
        head: 'Quiz Me',
        text: 'Generate a comprehensive quiz on any topic.',
        pageIndex: 5,
        pageName: "Quiz Me",
        naviagatePage: true,
        label: chatRoutePages.quizMaker,
    },
    {
        img: assignmentHelpImg,
        imgElement: AssessementWhiteImage(),
        head: 'Assignment Helper',
        text: 'Solve your assignment problems with ease.',
        pageIndex: 6,
        pageName: "Assignment Helper",
        naviagatePage: true,
        label: chatRoutePages.assignmentHelper,

    },

    {
        img: coverLetterImg,
        imgElement: AssessementWhiteImage(),
        head: 'Cover Letter Writer',
        text: 'Generate a well-written cover letter for your job application.',
        pageIndex: 8,
        pageName: "Cover Letter Writer",
        naviagatePage: true,
        label: chatRoutePages.coverLetterWriter,
    },
    {
        img: interviewPrepImg,
        imgElement: AssessementWhiteImage(),
        head: 'Interview Prep',
        text: 'Prepare for your next job interview with our interview prep tool.',
        pageIndex: 9,
        pageName: "Interview Prep",
        naviagatePage: true,
        label: chatRoutePages.interviewPrep,
    },
  

    {
        img: socialMedia,
        imgElement: AssessementWhiteImage(),
        head: 'Resume Enhanncer',
        text: 'Enhance your resume with our resume enhancer.',
        pageIndex: 10,
        pageName: "Resume Enhanncer",
        naviagatePage: true,
        label: chatRoutePages.resumeEnhancer,
    },
    // {
    //     img: youtube,
       
    //     head: 'Youtube transcript',
    //     text: 'Extract audio transcript from a youtube video.',
    //     pageIndex: 7,
    //     pageName: "Youtube transcript",
    //     naviagatePage: true,
        
    //     label: 'youtube_transcript',
    // },
];

const contentPromptOptionsData = ({response, onSuccess, urlRef, showDownload}: {
    response:string;
    onSuccess: React.Dispatch<React.SetStateAction<boolean>>;
    urlRef: React.MutableRefObject<string>;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
} ): PromptOptionsType[]  => [
    {
        name: 'Share',
        icon: ShareImg,
        onClick: () => handleShare({
            response,
            onSuccess,
            urlRef,
        }),
    },
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];

const contentPromptOptionsData2 = ({response, showDownload}: {
    response:string;
    showDownload: React.Dispatch<React.SetStateAction<boolean>>;
} ): PromptOptionsType[]  => [
    {
        name: 'Copy',
        icon: Copy,
        onClick: () => copyData(response),
    },
    {
        name: 'Download',
        icon: DownloadImage,
        onClick: () => showDownload(true),
    },
];


export {
    newGenerationHomeData,
    contentPromptOptionsData,
    contentPromptOptionsData2,
}