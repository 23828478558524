import { Upload } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { extractTextFromPDF, extractTextFromDOCX } from '@src/pages/dashboard/function/pdf_doc_helper';
import { CircularProgress } from '@mui/material';

const UploadComponent: React.FC<{ extractedFile: any; setExtractedFile: any; isNotebook?: boolean }> = ({
    extractedFile,
    setExtractedFile,
    isNotebook = false,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState<File | null>(null);

    const handleFileUpload = async (file: File) => {
        setIsLoading(true);
        setFile(file);

        try {
            let extractedText = '';
            if (file.type === 'application/pdf') {
                extractedText = await extractTextFromPDF(file);
            } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                extractedText = await extractTextFromDOCX(file);
            } else {
                toast.warn('Unsupported file type');
                return false;
            }
            setExtractedFile(extractedText);
            console.log('Extracted text:', extractedText);
        } catch (error) {
            console.error('Error extracting text:', error);
            toast.error('File processing failed');
        } finally {
            setIsLoading(false);
        }
        return false; // Prevent auto-upload
    };

    return (
        <div
            className={`relative  p-6 rounded-lg border border-dashed border-orange-500 w-full ${isNotebook ? 'md:w-auto' : 'md:w-[568px] bg-white  dark:bg-[#1c1b22]'} text-center`}
        >
            <Upload.Dragger
                style={{ border: 'none' }}
                multiple={false}
                showUploadList
                accept=".pdf,.docx"
                maxCount={1}
                beforeUpload={handleFileUpload} // Handles file selection and processing
            >
                <UploadOutlined className="text-orange-500 text-3xl" />
                <p className="text-orange-500 font-semibold mt-2">
                    Click to upload <span className="text-gray-400 text-xs">or Drag and Drop files here.</span>
                </p>
                <p className="text-gray-400 text-xs">PDF or DOCX (max 3MB)</p>
                {!isLoading ? (
                    <>
                        {extractedFile && (
                            <div className="mt-4">
                                <span className="bg-orange-500 text-white text-xs px-3 py-1 rounded-full max-w-full truncate inline-block">
                                    {file?.name ?? ''}
                                </span>
                            </div>
                        )}{' '}
                    </>
                ) : (
                    <CircularProgress />
                )}
            </Upload.Dragger>
        </div>
    );
};

export default UploadComponent;
