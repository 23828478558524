/* eslint-disable @typescript-eslint/no-unused-vars */
import DashboardFrame from '@src/components/DashboardFrame';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
    Chats,
    ChatType,
    getHistory,
    handleAddHistory,
    handleDeleteAllWithTypeHistory,
    handleDeleteHistory,
} from '../../endpoints';
import right from '@assets/right_gray.svg';
import { Download } from '../../components/Share/Download';
import ReusableDialog from './components/CommentModal';
import LoadingComponent from '@src/components/LoadingComponent';
import ResponseTextbox from '../../components/responseTextBox';
import MarkdownDesign from '@src/components/Markdown';
import NewGenerateContent from './components/new_generate_home';
import BackButton from '@src/components/BackButton';
import { contentPromptOptionsData } from './constants';
import { toast } from 'react-toastify';
import { prompts } from '@src/utils/prompts';
import { io, Socket } from 'socket.io-client';
import { Input } from 'antd';
import { Share } from '../../components/Share';
import moment from 'moment';
import { convertStringToDelta, getUrlPath, openCurriculumEditor, openEditor } from '@src/utils/app_functions';
import saveAs from 'file-saver';
import { pdfExporter } from 'quill-to-pdf';
import axios from 'axios';
import Profile from '@assets/Avatar.svg';
import { v4 as uuidv4 } from 'uuid';
import ContentTypingComponent from '../../components/content_typing';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { BASE_URL } from '@src/utils/constants';
import { HistoryTypeFormat } from '@src/core/interfaces/chat';
import path from 'path';
import { ThemeContext } from '@src/theme_provider/theme';
import NewEditorPage from '../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../essay/sun_editor copy';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import { useDebouncer } from '../../function/helper';
import AdminCollaboratorsList from '../essay/editor/collaborators';

interface User {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    profileImg: string;
}

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: User[];
}

const Generate = () => {
    const pageHistory = 'contents';
    const navigate = useNavigate();

    const [histories, setHistories] = useState<any[]>([]);
    const [page, setPage] = useState<string>('');
    const [generating, setGenerating] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [sideBarVisible, setSidebarVisible] = useState<boolean | null>(null);
    const [basePage, setBasePage] = useState<number>(0);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const UrlRef = useRef<string>('');
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });

    const [newPromptSent, setNewPromptSent] = useState('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [message, setMessage] = useState('');
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [canNavigate, setCanNavigate] = useState(false);
    const [topic, setTopic] = useState('');
    const [basePrompt, setBasePrompt] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);

    const [currentPage, setCurrentPage] = useState<number>(0);
    const [conversation, setConversation] = useState<ChatType[]>([]);
    const baseurl = BASE_URL;
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });
    const editorInstanceRef = useRef<any>();

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const location = useLocation();
    const { error, loading, fetchDocument, document: textDocument, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const {
        setShowMobileHistory,
        setShowTop,
        setShowSidebar,
        setRightComponentName,
        setRightComponentPlaceholder,
        setRightComponentData,
        setHistory,
        setMiddleClass,
        setMobileNavStyle,
        selectedHistory,
        setShowPagePath,
        setTopExtraData,
        setCloseIdValue,
        setShowHistoryOption,
        setGmindTyping,
        setCanNavigateHome,
        setShowExpiredModal,
        setShowCompleteProfileModal,
        setIsAffiliate,
        setMenu,
        setShowInput,
        setShowHistoryOptions,
        setRightComponentClick,
        setOnDetailsFetched,
        setOnDeleteAllHistory,
        setOnDeleteHistory,
        setOnClickPage,
        setSelectedHistory,
        setValue,
        setShowRightComponent,
        setSchoolAdminPageName,
        setShowSchoolHeader,
        setShowHistory,
        setPromptSent,
        setResponse,
        setShowingHistory,
        setHistoryType,
        setPageHistory,
        showingHistory,
        response,
        promptSent,
        getHistory,
        historyType,
        showSidebar,
        details,
        globalDocId,
    } = useOutletContext<any>();
    const { savedTheme, isMobile } = useContext(ThemeContext);
    const [pageId, setPageId] = useState('');

    const bottomRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isTyping && response != '') {
            console.log('scrolling');
            bottomRef.current?.scrollTo({
                top: bottomRef.current?.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isTyping, response]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        console.log('I am called');
        const pageDocId = queryParams.get('document');
        const pageId = queryParams.get('document');
        setDocId(pageId ?? '');
        setPageId(pageId ?? '');
    }, [window.location.pathname]);

    const promptOptions = contentPromptOptionsData({
        response,
        onSuccess: setShowShareModal,
        urlRef: UrlRef,
        showDownload: setShowDownload,
    });

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (currentPage == 1 || currentPage == 3) {
            setHistoryType('content');
            setPageHistory('content');
        } else if (currentPage == 2) {
            setHistoryType('learning_materials');
            setPageHistory('learning_materials');
        } else if (currentPage == 4) {
            setHistoryType('letter');
            setPageHistory('letter');
        } else if (currentPage == 5) {
            setHistoryType('social');
            setPageHistory('social');
        }

        if (currentPage == 1 || currentPage == 3 || currentPage == 2 || currentPage == 4) {
            setShowSidebar(false);
            setShowTop(false);
        } else {
            if (generate || showingHistory) {
                setShowTop(false);
                setShowSidebar(false);
            } else {
                setShowTop(true);
                setShowSidebar(true);
            }
        }

        console.log(currentPage);
    }, [currentPage, generate, showingHistory]);

    useEffect(() => {
        if (conversation.length == 0) return;
        handleAddHistory(conversation, pageHistory, historyType);
        getHistory();
        setConversation([]);
    }, [isTyping]);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { streamId, assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);
            setConversation([assistant]);
            // if (streamId === StreamId.current) {
            StreamId.current = '';
            // setStreaming(false);
            const uuid = uuidv4();

            const id = uuid;
            DocumentId.current = uuid;
            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: assistant.content,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: assistant.content,
                });
            }
            socket.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const handleStreamEnd = async (prompt: string, data: ChatType) => {
        const dataToSave: ChatType[] = [
            {
                role: 'user',
                content: prompt,
            },
            data,
        ];

        let docData = {
            content: response,
            doc_id: docId,
            promptSent: prompt,
            promptType: promptSent,
        };

        await handleAddHistory(dataToSave, pageHistory, docData);
        getHistory();
    };

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
            return doc;
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);
        console.log({ generate, showingHistory });

        if (generate || showingHistory) {
            const id = getDocIdFromUrl();
            openCurriculumEditor({
                response,
                pageId: globalDocId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    const handlePDF = async () => {
        const data = convertStringToDelta(response);
        const blob = await pdfExporter.generatePdf(data);
        const current_time = moment().format('YYYY-MM-DD HH:mm:ss');
        saveAs(blob as Blob, `gmind_document-${current_time}.pdf`);
    };
    const handleWord = async () => {
        const dataToDownload = response;
        const responseData = await axios.post(
            'https://api-v2.gmind.ai/v1/document/markdown-to-doc',
            { content: dataToDownload },
            {},
        );
        const { data: d } = responseData;
        if (d?.statusCode === 1) {
            const link = document.createElement('a');
            link.href = `https://api-v2.gmind.ai/${d?.url}`;
            const current_time = moment().format('YYYY-MM-DD HH:mm:ss');

            link.download = `gmind_document-${current_time}.docx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('Download successful');
        }
    };

    const handleMessageClick = () => {
        setDialogVisible(true);
    };
    const handleLikeClick = () => {};
    const handleDisLikeClick = () => {};

    const regenerate = () => {
        onGenerateHandler(promptSent);
        setGenerate(true);
        setGenerating(true);
        setIsTyping(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const onFinish = async (promptMessage: string, letter = false, prompts?: string) => {
        console.log(promptMessage);
        setMessage(promptMessage);
        console.log(message);
        setIsTyping(true);
        setPromptSent(promptMessage);
        await onGenerateHandler(promptMessage, letter);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const chatWithAI = async () => {
        const promptMessage = newPromptSent;
        setNewPromptSent(promptMessage);
        setPromptSent(promptMessage);

        await onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
        const timeoutId = setTimeout(() => {
            setGenerating(false);
        }, 3000);
    };

    const handleSaveComment = (comment: any) => {
        console.log('Saved comment:', comment);
        setDialogVisible(false);
    };

    const onGenerateHandler = async (message: string, letter = false, promptSelected?: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message };
        // console.log(msgs);
        // return;
        setChats(msgs);
        setNewPromptSent('');
        setResponse('');

        const base = promptSelected ? promptSelected : letter ? prompts.letterWritter : prompts.content2(topic);
        setBasePrompt(base);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: promptSelected
                                ? promptSelected
                                : letter
                                  ? prompts.letterWritter
                                  : prompts.socialContent,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId ?? '',
                },
                label: "genie",
            });
        } catch (error) {
            //   setIsTyping(false);
        } finally {
            //   setIsTyping(false);
        }
    };

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        // alert("MF")
        console.log({ message: 'sending changes', documentId, userId, data, socket });

        socket?.emit(
            'send-changes',
            {
                documentId,
                userId,
                data,
            },
            (response: any) => {
                if (response.success) {
                    console.log('Event emitted successfully:', response.message);
                } else {
                    console.error('Failed to emit event:', response.error);
                }
            },
        );
    }, 1000);
    const setClickedHistory = useCallback(
        (id: string) => {
            let filterHistory: any = histories
                .flatMap((historyFormat: any) => historyFormat.histories)
                .filter((history: any) => {
                    const chatIdObj = history.find((h: any) => h.id === id);
                    return chatIdObj !== undefined;
                })
                .flat();

            // historyId.current = filterHistory.find((h: any) => h.chat_id)?.chat_id;
            filterHistory = filterHistory.filter((h: any) => h?.role && h?.content);
            const userPrompt = filterHistory.find((element: any) => element.role == 'user');
            const assistantResponse = filterHistory.find((element: any) => element.role == 'assistant');
            setPromptSent(userPrompt.content);
            setResponse(assistantResponse.content);
            setShowingHistory(true);
        },
        [histories, setPromptSent, setResponse, setShowingHistory],
    );

    useEffect(() => {
        console.log('MyHistory', histories);
        getHistory();
    }, []);

    const path = getUrlPath().pagename;
    useEffect(() => {
        setShowRightComponent(false);

        setMobileNavStyle(!isMobile ? { position: 'fixed' } : {});
        setShowHistory(!isMobile);
        setShowInput(false);
        setShowPagePath(false);
        setOnDeleteAllHistory((id: string) => {});
        setCanNavigateHome(true);
        setShowHistoryOptions(page != '');
        setHistoryType('contents');
    }, []);

    useEffect(() => {
        setResponse(documentResponse?.data?.data ?? '');
    }, [documentResponse]);

    useEffect(() => {
        if (response) {
            setEditorContent(response);
        }
    }, [response]);

    function getDocIdFromSearch() {
        const params = new URLSearchParams(window.location.search);
        const doc = params.get('document');

        if (doc) {
            setDocId(doc);
            return doc;
        } else {
            return null;
        }
    }

    useEffect(() => {
        let Id = getDocIdFromSearch();
        console.log(Id);
    }, [generate, showingHistory]);

    useEffect(() => {
        if (documentResponse?.data?.id) {
            // Register the user to the document space

            console.log('docId', documentResponse?.data?.id);
            socket?.emit('register_document_space', {
                documentId: documentResponse?.data?.id ?? '',
                userId: userDetails?.userId,
            });

            // Listen for document changes
            socket?.on('receive-changes', (data: { data: string; documentId: string; userId: string }) => {
                console.log(data.userId);
                console.log('Received:', data.data);
                if (data.userId === userDetails?.userId) return;
                setResponse((data.data ?? '').trim());
                setEditorContent(data.data);
            });

            // Listen for users on document
            socket?.on('user_on_document', (data: UserDocOn) => {
                console.log(data);
                setUserOnDoc(data);
            });

            // Cleanup listeners on component unmount or dependencies change
            return () => {
                socket?.off('receive-changes'); // Fix typo here
                socket?.off('user_on_document');
            };
        }

        // Cleanup in case no document exists
        return () => {
            socket?.off('receive-changes');
            socket?.off('user_on_document');
        };
    }, [socket, documentResponse?.data?.id]);

    useEffect(() => {
        console.log('userOnDoc', userOnDoc);
    }, [userOnDoc]);

    return (
        <div ref={bottomRef} className="h-screen overflow-auto ">
            {!generate && !showingHistory ? (
                <NewGenerateContent
                    onGenerate={(prompt, isLetter, promptSelected) => {
                        console.log(isLetter);
                        onFinish(prompt, isLetter, promptSelected);
                    }}
                    setTopic={(e) => setTopic(e)}
                    setPageName={(e) => setPage(e)}
                    setPageNumber={(i) => setCurrentPage(i)}
                />
            ) : (
                <div className="w-full flex h-screen flex-col  gap-0">
                    <div className="">
                        {!loading ? (
                            <div className="w-full flex flex-col gap-0">
                                <NewEditorPage
                                    collaborators={userOnDoc}
                                    docId={docId}
                                    setDocId={setDocId}
                                    editorInstanceRef={editorInstanceRef}
                                    regenerate={regenerate}
                                    latestRespone={latestRespone}
                                    setLatestRespone={setLatestRespone}
                                    highlightedTexts={highlightedTexts}
                                    setHighlightedTexts={setHighlightedTexts}
                                    content={
                                        <NewSunEditorComponent
                                            latestRespone={latestRespone}
                                            setLatestRespone={setLatestRespone}
                                            highlightedTexts={highlightedTexts}
                                            setHighlightedTexts={setHighlightedTexts}
                                            editorInstanceRef={editorInstanceRef}
                                            hasAccess={
                                                !isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false
                                            }
                                            initial_content={response}
                                            onChangeEditorContent={(e) => {
                                                setEditorContent(e);
                                                sendChanges(documentResponse?.data?.id, userDetails?.userId, e);
                                            }}
                                        />
                                    }
                                    showTopNavBar={false}
                                    showInvite={true}
                                    isTyping={isTyping}
                                    Profile={Profile}
                                    promptSent={promptSent}
                                    showingHistory={showingHistory}
                                    setGenerate={setGenerate}
                                    setShowingHistory={setShowingHistory}
                                    generate={generate}
                                    setEditorContent={setEditorContent}
                                    editorContent={editorContent}
                                    translatedContent={translatedContent}
                                    setTranslatedContent={setTranslatedContent}
                                    humanizedContent={humanizedContent}
                                    setHumanizedContent={setHumanizedContent}
                                    setUserOnDoc={setUserOnDoc}
                                    userOnDoc={userOnDoc}
                                />
                                {/* Other content here */}
                            </div>
                        ) : (
                            <div className="w-full h-screen flex flex-col justify-center items-center">
                                <div className="bg-white p-4 rounded-lg shadow-lg">
                                    <div className="flex justify-center items-center">
                                        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
                                    </div>
                                    <p className="text-center mt-4">Generating Document...</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Generate;
