import React, { useContext } from 'react';
import { Card } from 'antd';
import { ThemeContext } from '@src/theme_provider/theme';
import OrangePlus from '@assets/essay/BlackPlus.png';
import BlackPlus from '@assets/essay/plus-circle.png';
import GreyPlus from '@assets/essay/black-plus-circle.png';

const styles = ['High school', 'College', 'Post graduate'];

export const AGrade = ({
    selectedGrade,
    setSelectedGrade,
}: {
    selectedGrade: string;
    setSelectedGrade: React.Dispatch<React.SetStateAction<string>>;
}) => {
    const { savedTheme } = useContext(ThemeContext);

    return (
        <div className="flex flex-col items-center justify-center">
            <h2 className="text-[2.5rem] font-bold h-[4rem] dark:text-gray-300">Grade level</h2>
            <p className="text-gray-600 text-[1.125rem] dark:text-gray-300 mb-6">Select grade level</p>
            <div
                style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}
                className="w-full items-center justify-center"
            >
                {styles.map((style) => (
                    <button
                        key={style}
                        className="w-full md:w-auto flex flex-row items-center justify-center px-[16px] py-[6px] rounded-[6px] dark:text-white shadow-md dark:shadow-gray-500"
                        style={{
                            backgroundColor: savedTheme === 'dark' ? '#000B27' : 'transparent',
                            color: style === selectedGrade ? ' #EC5306' : savedTheme === 'dark' ? '#D9D9D9' : '#000B27',
                            border: style === selectedGrade ? '2px solid #EC5306' : '',
                        }}
                        onClick={() => setSelectedGrade(style)}
                    >
                        {style}
                        <img
                            src={style === selectedGrade ? OrangePlus : savedTheme === 'dark' ? GreyPlus : BlackPlus}
                            alt="Plus"
                            className="ml-2"
                        />
                    </button>
                ))}
            </div>
        </div>
    );
};
