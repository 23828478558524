import { Button, Form, Input, Select } from 'antd';
import { LessonMaterialProps, LetterWriterProps } from '../interface';
import { toast } from 'react-toastify';
import { useEffect, useRef, useState } from 'react';
import { InputTypeProps } from '../../assessment_2/interface';
import { gradeOptions, socialMedias, toneOptions } from '../../curriculum/constants';
import { prompts } from '@src/utils/prompts';
import { io, Socket } from 'socket.io-client';
import AIGenerate from '@icons/generate.svg';
import { onGenerateWithAI } from '../../curriculum/functions';
import { BASE_URL } from '@src/utils/constants';
import TextArea from 'antd/es/input/TextArea';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import './style.scss';
import { extractTextFromPDF } from '@src/pages/dashboard/function/pdf_doc_helper';
import { useOutletContext } from 'react-router';

const Report = ({ onGenerate, setTopic }: LetterWriterProps) => {
    let grades = gradeOptions();
    const [socialInput, setSocialInput] = useState<{ [key: string]: string }>({
        title: '',
        media: '',
        tone: '',
        message: '',
        hastags: '',
        action: '',
    });
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [inputTyping, setInputTyping] = useState<{ [key: string]: boolean }>({});
    const [visibility, setVisibility] = useState<{ [key: string]: boolean }>({});
    const recognitionRef = useRef<any>(null);

    const [recording, setRecording] = useState<{ [key: string]: boolean }>({
        strength: false,
        growth: false,
    });
    const [form] = Form.useForm();
    const [finalTranscript, setFinalTranscript] = useState('');

    const [recordingField, setRecordingField] = useState<string | null>(null);

    const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input
    const fileInputRef2 = useRef<HTMLInputElement>(null); // Ref for the file input
    const [docData1, setDocData1] = useState<string | ArrayBuffer | null>(null);
    const [docData2, setDocData2] = useState<string | ArrayBuffer | null>(null);

    const { details: userDetails } = useOutletContext<any>();

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const content = e.target?.result;

                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);

                    setDocData1(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData1(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData1(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };
            reader.onerror = () => console.error('Error reading file');

            reader.readAsArrayBuffer(file);
        }
    };

    const handleFileChange1 = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const content = e.target?.result;

                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);

                    setDocData2(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData2(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData2(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };
            reader.onerror = () => console.error('Error reading file');

            reader.readAsArrayBuffer(file);
        }
    };

    const onPaperClipClick = () => {
        // Programmatically trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const onPaperClipClick2 = () => {
        // Programmatically trigger the file input click
        if (fileInputRef2.current) {
            fileInputRef2.current.click();
        }
    };

    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    }, [fileInputRef]);
    useEffect(() => {
        if (fileInputRef2.current) {
            fileInputRef2.current.value = ''; // Reset the file input value
        }
    }, [fileInputRef2]);

    const handleMicrophoneClicked = (field: string) => {
        if (recording[field] && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }

        {
            setRecordingField(field); // Set the current field being recorded
            handleMicrophone(field);
            console.log(recordingField);
        }
    };
    async function handleMicrophone(field: string) {
        if (recording[field]) {
            stopRecording(field);
        } else {
            startRecording(field);
        }
    }

    const startRecording = (field: string) => {
        try {
            toast.success('Recording in progress...');
            setRecording((prevRecording) => ({ ...prevRecording, [field]: true }));
            recognitionRef.current = new (window as any).webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setFinalTranscript(texts.join(' '));
                console.log('text', texts);
                console.log('final:', finalTranscript);
                // Use getFieldsValue and setFieldsValue for Antd form
                // Use getFieldsValue and setFieldsValue for Antd form
                const fields = form.getFieldsValue();
                console.log('Current fields:', fields);

                // Set the updated value in the correct field
                form.setFieldsValue({
                    [field as string]: texts.join(''), // Update the specific field
                });
                console.log('Current fields:', fields);

                console.log(`Updated ${field}:`, texts);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = (field: string) => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording((prevRecording) => ({ ...prevRecording, [field]: false })); // Reset after stopping
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleOnChange = (value: string, keyString: string) => {
        setSocialInput({
            ...socialInput,
            [keyString]: value,
        });
    };

    const handleOnChangeFromAI = (value: string, key: string) => {
        setSocialInput((prevFormData) => ({
            ...prevFormData,
            [key]: prevFormData[key] + value,
        }));
    };

    const setTyping = (key: string, value: boolean) => {
        setInputTyping((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const setItemVisibility = (key: string, value: boolean) => {
        setVisibility((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const inputData: Array<InputTypeProps> = [
        {
            name: 'Title*',
            placeholder: 'Enter a title for your social media post',
            value: 'title',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'input',
            required: true,
        },
        {
            name: 'Social Media Platform*',
            placeholder: 'e.g Physics, Project Management etc',
            value: 'media',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'select',
            options: socialMedias,
            required: true,
        },
        {
            name: 'Tone*',
            placeholder: 'Enter tone of your social media test',
            value: 'tone',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'select',
            options: toneOptions,
            required: true,
        },
        {
            name: 'Main Message*',
            placeholder: 'Main message of your media content',
            value: 'message',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'input',
            required: true,
        },
        {
            name: 'Hashtags*',
            placeholder: 'Enter content hastags',
            value: 'hastags',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'input',
            required: true,
        },
        {
            name: 'Call to Action',
            placeholder: 'Enter call action',
            value: 'action',
            onChange: (value, key) => handleOnChange(value, key),
            type: 'textarea',
            minRows: 2,
            onGenerate: (e, t) => {
                let message = `Generate a short call to action for this social media post with title ${t}`;
                onGenerateWithAI(sockets[e], message, userDetails?.userId ?? '', prompts.socialContent);
            },
        },
    ];

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        const newTyping: { [key: string]: boolean } = {};
        const visibility: { [key: string]: boolean } = {};
        inputData.forEach((item) => {
            if (item.type == 'textarea') {
                newSockets[item.name] = io(BASE_URL.slice(0, -2));
                newTyping[item.name] = false;
                visibility[item.name] = true;
            }
        });
        setSockets(newSockets);
        setInputTyping(newTyping);
        setVisibility(visibility);

        return () => {
            Object.values(newSockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);

    useEffect(() => {
        inputData.forEach((item) => {
            if (item.type == 'textarea') {
                const socket = sockets[item.name];
                if (!socket) return;

                const handleData = (data: string) => {
                    handleOnChangeFromAI(data, item.value);
                };

                const handleStreamEnd = () => {
                    setTyping(item.name, false);
                };

                socket.on('data', handleData);
                socket.on('stream_end', handleStreamEnd);
                return () => {
                    socket.off('data', handleData);
                    socket.off('stream_end', handleData);
                };
            }
        });
    }, [sockets]);

    const onFinish = (values: any) => {
        let promptMessage = '';

        let gradeLevel = values.gradeLevel;
        let pronouns = values.pronouns;
        let strength = values.strength;
        let growth = values.growth;

        let baseToSend = '';

        let canProcced = gradeLevel && strength && pronouns && growth;

        if (!canProcced) {
            toast.error('Kindly enter all values');
            return;
        }

        let informationData = `
            Grade Level: ${gradeLevel}
            Student Name: ${pronouns}
            Strength: ${strength}
            Growth: ${growth}
        `;

        promptMessage = `Generate a report card comment with details: ${informationData}`;

        onGenerate(promptMessage, true);
    };

    return (
        <div className="flex items-center flex-col min-h-screen px-4 pb-20">
            <Form
                form={form}
                className="flex flex-col py-4 px-10 dark:text-white space-y-4"
                onFinish={onFinish}
                layout="vertical"
            >
                <h1 className="text-3xl font-bold dark:text-white text-center">Report Card Comments</h1>
                <p className="text-[#6C7275] dark:text-white/50 text-center">
                    Generate report card comments with a student's strengths and areas for improvement.
                </p>

                {/* Form Container */}
                <div className=" md:w-[54.375rem] bg-[#F7F7F7] dark:bg-[#000B27] shadow-lg rounded-[0.75rem] p-[1.5rem] border border-[#D1D5DB] dark:border-[#FFFFFF33] ">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-[1.5rem]">
                    <Form.Item label="Grade Level" name="gradeLevel" className="w-full">
                    <select className="w-full rounded-md h-[3rem] bg-white dark:bg-[#ffffff33] dark:text-white p-3 border-[#D1D5DB] dark:border-white border">
                            {grades.map((item, index) => (
                                <option key={index} value={item || 'Select grade level'}>
                                    {item || 'Select grade level'}
                                </option>
                            ))}
                        </select>
                    </Form.Item>

                    <Form.Item label="Student Name" name="pronouns" className="w-full">
                        <input
                            className="p-2 border rounded-[10px] w-full rounded-md h-[3rem] bg-white dark:bg-[#FFFFFF33] dark:text-white placeholder-[#6B7280] dark:placeholder-[#D9D9D9] border-[#D1D5DB] dark:border-white w-full"
                            placeholder="Enter student's name"
                        />
                    </Form.Item>

                    <Form.Item label="Areas of Strength:" name="strength" className="w-full">
                        <div className="relative flex items-start bg-white dark:bg-[#FFFFFF33] border rounded-[10px] p-[14px] border-[#D1D5DB] dark:border-white">
                            <textarea
                                value={form.getFieldValue('strength') || docData1}
                                onChange={(e) => {
                                    setDocData1(e.target.value);
                                    form.setFieldsValue({ strength: e.target.value });
                                }}
                                rows={4}
                                className="p-2 border-none w-full focus:outline-none bg-transparent dark:text-white placeholder-[#6B7280] dark:placeholder-[#D9D9D9]"
                                placeholder="Enter areas of strength"
                            />

                            <div className="absolute bottom-2 left-2 flex gap-x-3 text-gray-500">
                                <AudioOutlined
                                    className={`hover:text-green-500 transition-colors duration-200 ${
                                        recording['strength']
                                            ? 'dark:text-customOrange'
                                            : 'dark:text-white text-gray-500'
                                    }`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleMicrophoneClicked('strength')}
                                />
                                <PaperClipOutlined className="dark:text-white" onClick={onPaperClipClick} />
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    accept=".doc,.docx,.pdf"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileChange(e, 'strength')}
                                />
                            </div>
                        </div>
                    </Form.Item>

                    <Form.Item label="Areas for Improvement:" name="growth" className="w-full">
                        <div className="relative flex items-start bg-white dark:bg-[#FFFFFF33] border rounded-[10px] p-[14px] border-[#D1D5DB] dark:border-white">
                            <textarea
                                value={form.getFieldValue('growth') || docData2}
                                onChange={(e) => {
                                    setDocData2(e.target.value);
                                    form.setFieldsValue({ growth: e.target.value });
                                }}
                                rows={4}
                                className="p-2 border-none w-full focus:outline-none dark:text-white bg-transparent placeholder-[#6B7280] dark:placeholder-[#D9D9D9]"
                                placeholder="Enter areas for improvement"
                            />

                            <div className="absolute bottom-2 left-2 flex gap-x-3 text-gray-500">
                                <AudioOutlined
                                    className={`hover:text-green-500 transition-colors duration-200 ${
                                        recording['growth'] ? 'dark:text-customOrange' : 'dark:text-white text-gray-500'
                                    }`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleMicrophoneClicked('growth')}
                                />
                                <PaperClipOutlined className="dark:text-white" onClick={onPaperClipClick2} />
                                <input
                                    ref={fileInputRef2}
                                    type="file"
                                    accept=".doc,.docx,.pdf"
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleFileChange1(e, 'growth')}
                                />
                            </div>
                        </div>
                    </Form.Item>
                </div>
                </div>

                <div className="flex justify-center mt-4">
                    <button
                        type="submit"
                        className="no-hover-effect md:w-[311px] md:h-[52px] bg-customOrange text-white rounded-[0.75rem] flex items-center justify-center"
                    >
                        Generate
                    </button>
                </div>
            </Form>
        </div>
    );
};
export default Report;
