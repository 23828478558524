import { Button, Form, Input } from 'antd';
import { LessonMaterialProps, LetterWriterProps } from '../interface';
import { toast } from 'react-toastify';
import { AudioOutlined, PaperClipOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { useEffect, useRef, useState } from 'react';
import './style.scss';
import mammoth from 'mammoth';
import * as pdfjsLib from 'pdfjs-dist';
import { extractTextFromPDF } from '@src/pages/dashboard/function/pdf_doc_helper';

const TextReWriter = ({ onGenerate, setTopic }: LetterWriterProps) => {
    const recognitionRef = useRef<any>(null);

    const [recording, setRecording] = useState<{ [key: string]: boolean }>({
        rewriteText: false,
        originalText: false,
    });
    const [form] = Form.useForm();
    const [finalTranscript, setFinalTranscript] = useState('');

    const [recordingField, setRecordingField] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null); // Ref for the file input
    const [docData, setDocData] = useState<string | ArrayBuffer | null>(null);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();

            reader.onload = async (e) => {
                const content = e.target?.result;

                if (file.type === 'application/pdf') {
                    const pdfText = await extractTextFromPDF(file);

                    setDocData(pdfText);

                    setDocData(pdfText);
                    form.setFieldsValue({ [field]: pdfText });
                } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                    const arrayBuffer = e.target?.result as ArrayBuffer;
                    const result = await mammoth.extractRawText({ arrayBuffer });
                    setDocData(result.value);
                    form.setFieldsValue({ [field]: result.value });
                } else if (content) {
                    setDocData(content as string);
                    form.setFieldsValue({ [field]: content });
                }
            };
            reader.onerror = () => console.error('Error reading file');

            reader.readAsArrayBuffer(file);
        }
    };

    const onPaperClipClick = () => {
        // Programmatically trigger the file input click
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleMicrophoneClicked = (field: string) => {
        if (recording[field] && recordingField !== field) {
            toast.warning('Please stop the current recording before starting a new one.');
            return; // Prevent starting a new recording
        }

        setRecordingField(field); // Set the current field being recorded
        handleMicrophone(field);
        console.log(field);
    };
    async function handleMicrophone(field: string) {
        if (recording[field]) {
            stopRecording(field);
        } else {
            startRecording(field);
        }
    }

    const startRecording = (field: string) => {
        try {
            toast.success('Recording in progress...');
            setRecording((prevRecording) => ({ ...prevRecording, [field]: true }));
            recognitionRef.current = new (window as any).webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setFinalTranscript(texts.join(' '));
                console.log('text', texts);
                console.log('final:', finalTranscript);
                // Use getFieldsValue and setFieldsValue for Antd form
                // Use getFieldsValue and setFieldsValue for Antd form
                const fields = form.getFieldsValue();
                console.log('Current fields:', fields);

                // Set the updated value in the correct field
                form.setFieldsValue({
                    [field as string]: texts.join(''), // Update the specific field
                });
                console.log('Current fields:', fields);

                console.log(`Updated ${field}:`, texts);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = (field: string) => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording((prevRecording) => ({ ...prevRecording, [field]: false })); // Reset after stopping
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };
    useEffect(() => {
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the file input value
        }
    }, [fileInputRef]);

    const onFinish = (values: any) => {
        let promptMessage = '';

        const originalText = values.originalText;
        const rewriteText = values.rewriteText;

        // check if all data is passed
        if (!originalText || !rewriteText) {
            toast.error('Please enter all prompts field');
            return;
        }

        promptMessage = `From this text ${originalText}.\nRewrite in such a way that new text meets this criteria: ${rewriteText}`;

        console.log(promptMessage);
        onGenerate(promptMessage, true);
    };

    return (
        <div className="flex items-center flex-col min-h-screen px-4">
            <Form
                form={form}
                onFinish={onFinish}
                className="flex flex-col py-4 px-10 dark:text-white space-y-4"
                layout="vertical"
            >
                <h1 className="text-3xl font-bold dark:text-white text-center">Text Rewriter</h1>
                <p className="text-[#6C7275] dark:text-white/50 text-center">
                    Revamp your words, transform your message, effortlessly rewrite your text.
                </p>

                <div className="md:w-[38.625rem] bg-[#F7F7F7] dark:bg-[#000B27] shadow-lg rounded-[0.75rem] p-[1.5rem] border border-[#E6E6E6] dark:border-[#FFFFFF33]  space-y-[24px]">
                    {' '}
                    <Form form={form} onFinish={onFinish} className="flex flex-col space-y-4" layout="vertical">
                        <Form.Item
                            label="Initial Text"
                            name="originalText"
                            rules={[{ required: true, message: 'Please enter the original text' }]}
                        >
                            <div
                                className="relative flex items-start border dark:border-white border-[#D1D5DB] bg-white dark:bg-[#FFFFFF33] rounded-[10px] p-[14px]"
                                style={{  gap: '8px' }}
                            >
                                <textarea
                                    value={form.getFieldValue('originalText' || docData)}
                                    onChange={(e) => {
                                        setDocData(e.target.value);
                                        form.setFieldsValue({ originalText: e.target.value });
                                    }}
                                    className="p-2 border-none w-full focus:outline-none bg-transparent dark:text-white placeholder-[#6B7280] dark:placeholder-[#D9D9D9]"
                                    placeholder="Please enter the text you would like to have rewritten. This tool is most effective for texts with 1,500 words or fewer."
                                    style={{
                                        background: 'transparent',
                                        outline: 'none',
                                        boxShadow: 'none',
                                        height: '100%', // Make it fit the container
                                        minHeight: '80px', // Prevent excessive shrinking
                                        maxHeight: 'calc(100% - 32px)', // Prevent overlapping with icons
                                        overflow: 'auto', // Ensure scroll appears if needed
                                    }}
                                />

                                {/* Icons at Bottom Left */}
                                <div className="absolute bottom-2 left-2 flex gap-x-3 text-gray-500">
                                    <AudioOutlined
                                        className={`hover:text-green-500 transition-colors duration-200 ${
                                            recording['originalText']
                                                ? 'dark:text-customOrange'
                                                : 'dark:text-white text-gray-500'
                                        }`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleMicrophoneClicked('originalText')}
                                    />
                                    <PaperClipOutlined onClick={onPaperClipClick} className="dark:text-white" />
                                    <input
                                        ref={fileInputRef}
                                        type="file"
                                        accept=".doc,.docx,.pdf,.txt"
                                        style={{ display: 'none' }}
                                        onChange={(e) => handleFileChange(e, 'originalText')}
                                    />
                                </div>
                            </div>
                        </Form.Item>

                        <Form.Item
                            label="Rewrite so that:"
                            name="rewriteText"
                            rules={[{ required: true, message: 'Please enter your text here' }]}
                            className="w-full"
                        >
                            {' '}
                            <div className="flex flex-row p-2 border rounded-[10px] items-center space-x-2 bg-white dark:bg-[#FFFFFF33]">
                                <input
                                    value={form.getFieldValue('rewriteText')}
                                    onChange={(e) => form.setFieldsValue({ rewriteText: e.target.value })}
                                    placeholder="Enter your text here"
                                    className="   placeholder-[#6B7280] w-full dark:placeholder-[#D9D9D9]"
                                />
                                <AudioOutlined
                                    className={`hover:text-green-500  transition-colors duration-200   ${
                                        recording['rewriteText']
                                            ? 'dark:text-customOrange'
                                            : 'dark:text-white text-gray-500'
                                    }`}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleMicrophoneClicked('rewriteText')}
                                />
                            </div>
                        </Form.Item>
                    </Form>
                </div>

                {/* Generate Button (outside the container) */}
                <div className="flex justify-center mt-4">
                    <button
                        type="submit"
                        className="no-hover-effect w-[311px] h-[52px] bg-customOrange text-white rounded-[12px] flex items-center justify-center"
                    >
                        Generate
                    </button>
                </div>
            </Form>
        </div>
    );
};

export default TextReWriter;
