import { Form, Input } from 'antd';
import { SyllabusInputType, SyllabusType } from '../interfaces';
import BaseForm from './base_form';
import { useContext, useEffect, useRef, useState } from 'react';
import addCarbon from '@icons/carbon_add.svg';
import icBaselineMinus from '@icons/ic_baseline-minus.svg';
import { toast } from 'react-toastify';
import AIGenerate from '@icons/generate.svg';
import { io, Socket } from 'socket.io-client';
import { prompts } from '@src/utils/prompts';
import { onGenerateWithAI } from '../functions';

import { curriculumOptions } from '../constants';
import { BASE_URL } from '@src/utils/constants';
import { Chats, ChatType, getHistory } from '@src/pages/dashboard/endpoints';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';

import { useOutletContext, useNavigate } from 'react-router';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { openCurriculumEditor } from '@src/utils/app_functions';

import { v4 as uuidv4 } from 'uuid';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import Profile from '@assets/Avatar.svg';

import './global.scss';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import BackButton from '@src/components/BackButton';
import { chatRoutePages } from '@src/utils/app_routes';
import { ThemeContext } from '@src/theme_provider/theme';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const SyllabusScreen = () => {
    // comment fir syllabus screen
    // 0 - form page
    // 1 - syllabus advance form

    const [basePrompt, setBasePrompt] = useState('');
    const [subject, setCurrentSubject] = useState('');

    const [objective, setObjective] = useState('');
    const [materials, setMaterials] = useState('');
    const [courseOutline, setCourseOutline] = useState('');
    const [additionalDetails, setAdditionalDetails] = useState('');
    const [showAdditional, setShowAdditional] = useState(true);
    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [visible, setVisibility] = useState<boolean>(true);
    const [objectiveVisible, setObjectiveVisibility] = useState<boolean>(true);
    const [materialsVisible, setMaterialVisibility] = useState<boolean>(true);
    const [outlineVisible, setOutlineVisibility] = useState<boolean>(true);
    const [detailsVisible, setDetailsVisibility] = useState<boolean>(true);
    const [inputTyping, setInputTyping] = useState<{ [key: string]: boolean }>({});
    const baseurl = BASE_URL;
    const [selectedOption, setSelectedOption] = useState<string | null>(null);
    const [uploadedFile, setUploadedFile] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [description, setDescription] = useState('');
    const [template, setTemplate] = useState('');
    const { isMobile } = useContext(ThemeContext);

    const [histories, setHistories] = useState<any>([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [promptSent, setPromptSent] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [canNavigate, setCanNavigate] = useState(false);
    const [option, setOption] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { loading, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();

    const {
        setShowTop,
        setShowSidebar,
        setHistory,
        setMobileNavStyle,
        setShowPagePath,
        setShowHistoryOption,
        setShowRightComponent,
        setShowHistory,
        response,
        setResponse,
        details,
    } = useOutletContext<any>();

    const navigate = useNavigate();
    const bottomRef = useRef<HTMLDivElement>(null);
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [userInput, setUserInput] = useState('');
    const [isloading, setisLoading] = useState(false);
    const [extractedFile, setExtractedFile] = useState<string>('');
    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
            console.log('Received data:', data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            // setShowEditorButton(true);
            const { assistant } = data;
            setStreamEndMessage(assistant);
            setIsTyping(false);
            setCanNavigate(true);
            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (generate || showingHistory) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (response) {
            console.log(response);
            setEditorContent(response);
            setTranslatedContent(response);
        }
    }, [response]);

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: systemPrompt,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId ?? '',
                },
            });
            // await handleAddHistory([{ role: 'user', content: message }, msgs], pageHistory, {});
            // getPageHistory();
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const inputValues: Array<SyllabusInputType> = [
        {
            showAI: visible,
            value: description,
            name: 'description',
            label: 'Course Overview*',
            additionalInput: false,
            onChange: (e) => {
                setDescription(e);
            },
            placeholder: 'What is the course overview?\ne.g., This course will cover the British curriculum',
            onGenrate: () => {
                const prompt = `Generate a short course description for the syllabus for ${subject}`;
                console.log(prompt);
                const socket = sockets['description'];
                onGenerateWithAI(socket, prompt, details?.userId ?? '');
            },
            setData: setDescription,
            setVisible: setVisibility,
        },

        {
            showAI: objectiveVisible,
            value: objective,
            name: 'objective',
            label: 'Training Objective*',
            additionalInput: false,
            onChange: (e) => {
                setObjective(e);
            },
            placeholder: 'What are the course objective?\ne.g., By the end of this course, students will be able to...',
            onGenrate: () => {
                const prompt = `Generate a short course objective for the syllabus for ${subject}`;
                const socket = sockets['objective'];
                onGenerateWithAI(socket, prompt, details?.userId ?? '');
            },
            setData: setObjective,
            setVisible: setObjectiveVisibility,
        },
        {
            showAI: materialsVisible,
            value: materials,
            name: 'materials',
            label: 'Required Materials*',
            additionalInput: false,
            onChange: (e) => {
                setMaterials(e);
            },
            placeholder: 'List materials needed for the course\ne.g. British curriculum, Calculator, etc.',
            onGenrate: () => {
                const prompt = `Generate a list of required materials for the syllabus for ${subject}`;
                const socket = sockets['materials'];
                onGenerateWithAI(socket, prompt, details?.userId ?? '');
            },
            setData: setMaterials,
            setVisible: setMaterialVisibility,
        },
        {
            showAI: outlineVisible,
            value: courseOutline,
            name: 'outline',
            label: 'Course Outline*',
            additionalInput: true,
            onChange: (e) => {
                setCourseOutline(e);
            },
            placeholder: 'What is the course overview?\ne.g., This course will cover the British curriculum',
            onGenrate: () => {
                const prompt = `Generate a short course outline for the syllabus for ${subject}`;
                const socket = sockets['outline'];
                onGenerateWithAI(socket, prompt, details?.userId ?? '');
            },
            setData: setCourseOutline,
            setVisible: setOutlineVisibility,
        },
        {
            showAI: detailsVisible,
            value: additionalDetails,
            name: 'details',
            label: 'Other Details',
            additionalInput: true,
            onChange: (e) => {
                setAdditionalDetails(e);
            },
            placeholder: 'What is the course overview?\ne.g., This course will cover the British curriculum',
            onGenrate: () => {
                const prompt = `Generate other details for the syllabus for ${subject}`;
                const socket = sockets['details'];
                onGenerateWithAI(socket, prompt, details?.userId ?? '');
            },
            setData: setAdditionalDetails,
            setVisible: setDetailsVisibility,
        },
    ];

    useEffect(() => {
        const newSockets: { [key: string]: Socket } = {};
        const newTyping: { [key: string]: boolean } = {};
        inputValues.forEach((item) => {
            newSockets[item.name] = io(baseurl.slice(0, -2));
            newTyping[item.name] = false;
            setInputTyping(newTyping);
        });
        setSockets(newSockets);

        return () => {
            Object.values(newSockets).forEach((socket) => {
                socket.close();
            });
        };
    }, []);

    const setTyping = (key: string, value: boolean) => {
        setInputTyping((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    useEffect(() => {
        inputValues.forEach((item) => {
            const socket = sockets[item.name];
            if (!socket) return;

            const handleData = (data: string) => {
                item.setData((response) => response + data);
            };

            const handleStreamEnd = () => {
                setTyping(item.name, false);
            };

            socket.on('data', handleData);
            socket.on('stream_end', handleStreamEnd);
            return () => {
                socket.off('data', handleData);
                socket.off('stream_end', handleData);
            };
        });
    }, [sockets]);

    const onFinish = () => {
        // check if properties are passed
        if (!description || !objective || !materials) {
            toast.error(`Kindly enter details for ${subject} standard syllabus`);
            return;
        }

        const outline: string = courseOutline ? `\nCourse Outline: ${courseOutline}` : '';
        const details: string = additionalDetails ? `\nAdditional Details: ${additionalDetails}` : '';

        const additionalinfo = outline + details;

        const promptToGenerate = `Create a Standard Syallabus for ${subject} using this details\nDescription: ${description}\nObjective: ${objective}\nMaterials: ${materials}${additionalinfo} with the following base info ${basePrompt}. Kindly ensure all the details sent is shown in the response. Also ensure that the response is grade level appropriate and strictly follows the requested curriculum.`;

        onGenerateHandler(promptToGenerate);
        setGenerate(true);
    };

    const hasTrueValue: boolean = Object.keys(inputTyping).some((key: string) => inputTyping[key]);

    return (
        <div
            className={`flex flex-col  w-full h-full overflow-y-scroll ${!generate ? 'pb-20' : isMobile ? 'pb-10' : ''}`}
        >
            {!generate && !showingHistory ? (
                <div className={` px-10 w-full flex flex-col gap-y-3`}>
                    {!isloading && (
                        <div className={`pb-5`}>
                            <div className=" pt-5">
                                <BackButton onclick={() => navigate(-1)} />{' '}
                            </div>
                            <div className="flex flex-col   w-full items-center">
                                <h2 className="font-medium text-[2rem] md:text-[2.5rem] dark:text-white text-[var(--gmind-black)]">
                                    Syllabus Generator
                                </h2>
                                <p className="text-[1.125] text-[var(--gmind-white60)] dark:text-gray-300 mb-2 text-normal mb-2">
                                    Provide us the information requested below to get your syllabus ready.
                                </p>
                            </div>
                            <BaseForm
                                firstText="Curriculum"
                                dropDownOptions={curriculumOptions}
                                onSubmit={(baseValue) => {
                                    setCurrentSubject(baseValue.subject);
                                    setisLoading(true);

                                    setBasePrompt(baseValue.basePrompt);
                                }}
                            />
                        </div>
                    )}
                    {isloading && (
                        <div className="flex flex-col gap-y-3 w-full h-[70%] ">
                            {/* <div className="items-center flex gap-x-3">
                        <p className="font-semibold text-[0.9rem] text-[var(--gmind-light-black)]">{subject}</p>
                        <button className="p-1 rounded-[0.25rem] text-[var(--gmind-orange)] text-[0.9rem]" style={
                            {
                                border: "1px solid var(--gmind-orange)"
                            }
                        }>Syllabus Generator</button>
                    </div> */}
                            <div className=" pt-5">
                                <BackButton
                                    onclick={() => {
                                        setisLoading(false);
                                    }}
                                />{' '}
                            </div>

                            <div className="flex flex-col items-center w-full">
                                <h2 className="whitespace-normal font-medium text-[2rem] text-center md:text-[2.5rem] md:h-[4rem] text-[var(--gmind-black)] dark:text-white">
                                    Create a standard syllabus
                                </h2>
                                <p className="text-[1.125rem] text-[var(--gmind-white60)]  text-center text-normal dark:text-gray-300">
                                    Provide details about the course, including objectives, materials, and additional
                                    information.
                                </p>
                            </div>

                            <Form
                                onFinish={onFinish}
                                className="flex flex-col items-center  gap-y-3 gap-x-3"
                                layout="vertical"
                            >
                                <div className="flex items-center flex-col gap-y-[1.5rem] border border-[#D1D5DB] dark:border-[#FFFFFF33] bg-[#F7F7F7] dark:bg-[#000B27] shadow-lg rounded-[12px] p-[1.5rem] w-full md:w-full md:max-w-[54.375rem]">
                                    <div className="md:grid flex flex-col md:grid-cols-2 gap-[1.5rem] w-full">
                                        <div className="flex flex-col gap-y-2 w-full ">
                                            {inputValues.map(
                                                (item, index) =>
                                                    !item.additionalInput && (
                                                        <Form.Item
                                                            key={index}
                                                            className="w-full"
                                                            name={item.name}
                                                            label={
                                                                <label className="font-normal text-[0.9rem] text-[var(--gmind-light-black)] dark:text-white">
                                                                    {item.label}
                                                                </label>
                                                            }
                                                            rules={[
                                                                {
                                                                    message: 'field is required',
                                                                    required: false,
                                                                },
                                                            ]}
                                                        >
                                                            <div className="relative">
                                                                <Input.TextArea
                                                                    className="rounded-[0.25rem] bg-transparent hover:bg-transparent placeholder-gray-700"
                                                                    autoSize={{ minRows: 4 }}
                                                                    style={{ border: '1px solid gray' }}
                                                                    value={item.value}
                                                                    onChange={(e) => item.onChange(e.target.value)}
                                                                    placeholder={item.placeholder}
                                                                />

                                                                <div
                                                                    onClick={() => {
                                                                        item.onGenrate();
                                                                        item.setVisible(false);
                                                                        setTyping(item.name, true);
                                                                    }}
                                                                    className={`${item.showAI ? '' : 'hidden'} cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[10px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2`}
                                                                >
                                                                    <img src={AIGenerate} alt="" />
                                                                    <span>Use Gmind AI</span>
                                                                </div>
                                                            </div>
                                                        </Form.Item>
                                                    ),
                                            )}
                                            {/* <div onClick={() => setShowAdditional(!showAdditional)} className="w-full flex items-center justify-between p-2 bg-[var(--gmind-orange15)]" style={{border: "1px solid var(--gmind-orange)"}}>
                                <h2 className="text-[0.9rem] font-normal text-[var(--gmind-orange)]">{`${showAdditional ? 'Hide Advanced Inputs' :'Show Advanced Inputs'}`}</h2>
                                <img src={showAdditional ? icBaselineMinus :addCarbon} alt={`${showAdditional ? 'ic baseline minus': 'addCarbon'}`} />
                            </div> */}

                                           
                                        </div>

                                        <div className="w-full  flex flex-col gap-y-3 px-3 py-3 mb-20">
                                            {showAdditional && (
                                                <div className="border-[2px] border-dashed border-[var(--gmind-orange)] rounded-[0.5rem] px-5 py-3 flex flex-col">
                                                    <h2 className="mb-5 self-center hidden md:flex text-center font-medium text-[1.5rem] dark:text-white">
                                                        Advance Options
                                                    </h2>
                                                    {inputValues.map(
                                                        (item, index) =>
                                                            item.additionalInput && (
                                                                <Form.Item
                                                                    key={index}
                                                                    className="w-full"
                                                                    name={item.name}
                                                                    label={
                                                                        <label className="font-normal text-[0.9rem] text-[var(--gmind-light-black)] dark:text-white">
                                                                            {item.label}
                                                                        </label>
                                                                    }
                                                                >
                                                                    <div className="relative">
                                                                        <Input.TextArea
                                                                            className="rounded-[0.25rem] bg-transparent hover:bg-transparent placeholder-gray-700"
                                                                            autoSize={{ minRows: 4 }}
                                                                            style={{ border: '1px solid gray' }}
                                                                            value={item.value}
                                                                            onChange={(e) =>
                                                                                item.onChange(e.target.value)
                                                                            }
                                                                            placeholder={item.placeholder}
                                                                        />

                                                                        {item.showAI && (
                                                                            <div
                                                                                onClick={() => {
                                                                                    item.onGenrate();
                                                                                    item.setVisible(false);
                                                                                    setTyping(item.name, true);
                                                                                }}
                                                                                className="cursor-pointer shadow-md rounded-[1rem] absolute top-[55px] right-[15px] py-[0.3rem] px-[1rem] bg-white text-normal text-[0.75rem] text-[var(--gmind-black)] flex gap-x-2"
                                                                            >
                                                                                <img src={AIGenerate} alt="" />
                                                                                <span>Use Gmind AI</span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </Form.Item>
                                                            ),
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center w-full">
                                                {!showAdditional && (
                                                    <button
                                                        type="submit"
                                                        className="w-[11rem] p-3 rounded-[0.375rem] text-white bg-[var(--gmind-orange)] "
                                                    >
                                                        Generate Syllabus
                                                    </button>
                                                )}
                                                {showAdditional && (
                                                    <button
                                                        type="submit"
                                                        className={` w-[11rem] p-3 rounded-[0.375rem] text-white bg-[var(--gmind-orange)]`}
                                                    >
                                                        Generate Syllabus
                                                    </button>
                                                )}
                                            </div>
                                </div>
                            </Form>
                        </div>
                    )}
                </div>
            ) : (
                <div className="w-full flex flex-col gap-0 ">
                    <NewEditorPage
                        setDocId={setDocId}
                        docId={docId}
                        editorInstanceRef={editorInstanceRef}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        latestRespone={latestRespone}
                        setLatestRespone={setLatestRespone}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        regenerate={onFinish}
                    />
                </div>
            )}
        </div>
    );
};

export default SyllabusScreen;

const systemPrompt = `
You are a professional and detail-oriented syllabus generator designed to help educators create comprehensive and structured course plans. Your responsibilities include:

1. Understanding the course subject and target audience, whether they are high school students, college students, or professionals.
2. Providing a syllabus that includes the following sections:
   - Course Title
   - Course Description
   - Learning Objectives
   - Prerequisites
   - Weekly Schedule (with topics, subtopics, and any assignments or activities)
   - Assessment Methods (e.g., exams, projects, participation)
   - Recommended Resources (books, articles, tools, and online resources)
   - Instructor Contact Information (if provided)

3. Adapting to different course durations, such as 4 weeks, 8 weeks, 12 weeks, or customized lengths.
4. Ensuring content is logically organized, concise, and engaging for learners.
5. Offering optional suggestions for enhancing the course, such as group projects, case studies, or additional activities.
6. Formatting the output in a clear, professional tone suitable for sharing with students.

When provided with the course title, description, target audience, and duration, create a detailed and customized syllabus following the structure above.
`;
