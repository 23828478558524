import { CloseOutlined } from '@ant-design/icons';
import { CheckCircle } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import './answer.scss';
import { Download } from '@src/pages/dashboard/components/Share/Download';
import { convertHTMLToPDF, convertHTMLToWord } from '../../essay/functions';

interface QuizQuestion {
    question: string;
    options: string[];
    correctAnswer: string;
    type: string;
    userAnswer?: string;
    questionScore?: number;
}

interface QuizProps {
    questions?: QuizQuestion[];
    onSubmit: (score: number, results: QuizQuestion[]) => void;
    handleGenerate: () => void;
    quizState: QuizQuestion[];
    setQuizState: React.Dispatch<React.SetStateAction<QuizQuestion[]>>;
    score: number | null;
    setScore: React.Dispatch<React.SetStateAction<number | null>>;
}

const Quiz: React.FC<QuizProps> = ({
    questions,
    onSubmit,
    handleGenerate,
    quizState,
    setQuizState,
    score,
    setScore,
}) => {
    const [step, setStep] = useState(0);
    const [pdfDownloading, setPdfDownloading] = useState(false);
    const [wordDownloading, setWordDownloading] = useState(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const UrlRef = useRef<string>('');
    const [isAnswer, setIsAnswer] = useState<boolean>(false);

    const handleAnswerChange = (index: number, answer: string) => {
        const updatedQuestions = [...quizState];
        updatedQuestions[index].userAnswer = answer;
        setQuizState(updatedQuestions);
    };

    const [isFlipped, setIsFlipped] = useState<boolean[]>(questions ? questions.map(() => false) : []);

    const handleFlip = (index: number) => {
        const updatedFlipped = [...isFlipped];
        updatedFlipped[index] = !updatedFlipped[index];
        setIsFlipped(updatedFlipped);
    };

    const handleSubmit = () => {
        // Check for unanswered questions
        const unAnsweredQuestions = quizState.some((question) => !question.userAnswer);
        if (unAnsweredQuestions) {
            toast.info(`Please answer all questions before submitting.`);
            return;
        }

        // Initialize score
        let totalScore = 0;

        // Calculate results
        const results = quizState.map((question) => {
            const isCorrect = question.userAnswer?.trim().toLowerCase() === question.correctAnswer.trim().toLowerCase();
            const questionScore = isCorrect ? question.score || 1 : 0; // Default score per question is 1
            totalScore += questionScore;
            return { ...question, isCorrect, questionScore };
        });

        setQuizState(results);

        // Calculate final score as percentage
        const maxScore = quizState.reduce((sum, question) => sum + (question.score || 1), 0); // Maximum possible score
        const finalScore = (totalScore / maxScore) * 100;

        // Submit results
        onSubmit(totalScore, results);

        // Update state with raw score
        setScore(totalScore);

        // Log for debugging
        console.log(`Total Score: ${totalScore}`);
        console.log(`Final Score: ${finalScore.toFixed(2)}%`);
    };

    useEffect(() => {
        console.log(quizState);
    }, [quizState]);

    const handleBack = () => {
        setStep((prevStep) => Math.max(prevStep - 1, 0));
    };

    const handleNext = () => {
        if (questions) setStep((prevStep) => Math.min(prevStep + 1, questions.length - 1));
    };

    function removeUnwantedText(element: HTMLElement) {
        const unwantedTexts = ['Download', 'cancel'];
        element.childNodes.forEach((node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const textContent = node.textContent?.trim();
                if (textContent && unwantedTexts.includes(textContent)) {
                    node.parentNode?.removeChild(node);
                }
            }
        });
    }

    async function handlePDF() {
        const metaTable = document.getElementById('quiz-content');
        if (!metaTable) {
            toast.error('No content to download');
            return;
        }

        const clonedContent = metaTable.cloneNode(true) as HTMLElement;

        clonedContent.querySelectorAll('textarea, button, input').forEach((el) => el.remove());

        if (!isAnswer) {
            clonedContent.querySelectorAll('.answer').forEach((el) => el.remove());
        }

        removeUnwantedText(clonedContent);

        const cleanHTML = clonedContent.innerHTML;

        try {
            setPdfDownloading(true);
            await convertHTMLToPDF(cleanHTML);
        } catch (error) {
            toast.error('Error downloading PDF');
        } finally {
            setPdfDownloading(false);
        }
    }

    async function handleWord() {
        const metaTable = document.getElementById('quiz-content');
        if (!metaTable) {
            toast.error('No content to download');
            return;
        }

        const clonedContent = metaTable.cloneNode(true) as HTMLElement;

        clonedContent.querySelectorAll('textarea, button').forEach((el) => el.remove());
        if (!isAnswer) {
            clonedContent.querySelectorAll('.answer').forEach((el) => el.remove());
        }

        removeUnwantedText(clonedContent);

        const cleanHTML = clonedContent.innerHTML;

        try {
            setWordDownloading(true);
            await convertHTMLToWord(cleanHTML);
        } catch (error) {
            toast.error('Error downloading Word document');
        } finally {
            setWordDownloading(false);
        }
    }

    return (
        <div className="flex flex-col items-center h-[90%] overflow-hidden">
            {/* Header and Score Section */}
            {questions && questions.length > 0 && (
                <div className="w-full max-w-5xl sticky top-0  p-4">
                    {questions && questions[0].type !== 'flashcard' && (
                        <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-2">Answer Quiz</h1>
                    )}
                    {questions && questions[0].type === 'flashcard' && (
                        <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-200 mb-2">Flashcard Quiz</h1>
                    )}
                    {score !== 0 && questions && questions[0].type !== 'flashcard' && (
                        <p className="text-lg font-bold text-gray-700 dark:text-gray-300">
                            Final score : {score}
                            {score && '/'}
                            {score && questions.length}
                        </p>
                    )}
                </div>
            )}

            {/* Scrollable Quiz Questions Section */}
            <div
                className="flex-1 w-full h-full max-w-6xl space-y-6 overflow-y-auto overflow-x-auto scroll-container mr-2 mb-10"
                style={{ paddingBottom: '3rem', paddingTop: '1rem' }}
                id="quiz-content"
            >
                <h1 className="hidden">Quiz</h1>
                {quizState.map((question, index) => (
                    <>
                        {questions && questions.length > 0 && (
                            <React.Fragment key={index}>
                                {question.type === 'flashcard' ? (
                                    <>
                                        {index === step && (
                                            <div className="w-full flex flex-col md:flex-row items-center justify-center gap-4 p-4">
                                                {!isFlipped[index] ? (
                                                    <div
                                                        className="bg-white w-full md:w-[60%] border-8 border-green-500 flex flex-col items-center justify-center p-4 rounded-lg"
                                                        style={{
                                                            minHeight: '20rem', // Adjusted for mobile
                                                            transition: 'transform 0.6s ease-in-out',
                                                        }}
                                                    >
                                                        <h3 className="text-xl md:text-3xl font-bold mb-2">Question</h3>
                                                        <p className="text-lg md:text-2xl font-semibold text-center">
                                                            {question.question}

                                                            <p className="text-lg hidden">
                                                                {' '}
                                                                Answer: {question.correctAnswer}
                                                            </p>
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className="bg-green-500 w-full md:w-[60%] border-8 border-white flex flex-col items-center justify-center p-4 rounded-lg"
                                                        style={{
                                                            minHeight: '20rem', // Adjusted for mobile
                                                            transition: 'transform 0.6s ease-in-out',
                                                        }}
                                                    >
                                                        <h3 className="text-xl md:text-3xl text-white font-bold mb-2">
                                                            Answer
                                                        </h3>
                                                        <p className="text-lg md:text-2xl text-white font-medium text-center">
                                                            {question.correctAnswer}
                                                        </p>
                                                    </div>
                                                )}

                                                {/* Button */}
                                                <div className="flex justify-center md:justify-end w-full md:w-auto mt-4 md:mt-0">
                                                    <button
                                                        onClick={() => handleFlip(index)}
                                                        className={`${
                                                            isFlipped[index] ? 'bg-gray-500' : 'bg-customOrange'
                                                        } text-white font-bold py-2 px-4 rounded-md w-full md:w-auto`}
                                                    >
                                                        {isFlipped[index] ? 'Hide Answer' : 'Show Answer'}
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <div className="quiz-question bg-white rounded-lg shadow-md p-6 dark:border-gray-700 pb-4">
                                        <div className="flex flex-row justify-between">
                                            <p className="text-lg font-medium text-gray-700 mb-2">
                                                {`${index + 1}. ${question.question}`}
                                            </p>

                                            <p
                                               
                                                className=" answer text-lg hidden "
                                            >
                                                {' '}
                                                Answer : {question.correctAnswer}
                                            </p>
                                            {score !== null && (
                                                <div className="flex flex-col items-end max-w-[40%]">
                                                    <p
                                                        className="text-md text-green-200 font-bold text-end"
                                                        style={{ color: 'green' }}
                                                    >
                                                        Score : {question.questionScore}
                                                    </p>
                                                    {score !== null && (
                                                        <p className="text-sm font-medium text-gray-700 mb-2 flex items-end text-end">
                                                            Correct answer : {question.correctAnswer}
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex flex-row justify-between">
                                            {question.type === 'multiple-choice' || question.type === 'true-false' ? (
                                                <div className="space-y-2">
                                                    {question.options.map((option, optionIndex) => (
                                                        <label
                                                            key={optionIndex}
                                                            className="flex items-center space-x-3 text-gray-600"
                                                        >
                                                            <input
                                                                type="radio"
                                                                name={`question-${index}`}
                                                                value={option}
                                                                onChange={(e) =>
                                                                    handleAnswerChange(index, e.target.value)
                                                                }
                                                                checked={question.userAnswer === option}
                                                                disabled={score !== null}
                                                                className="w-4 h-4 text-green-500 border-gray-300  dark:border-gray-600 focus:ring-green-400 dark:focus:ring-green-500"
                                                            />
                                                            <li>{option}</li>
                                                        </label>
                                                    ))}
                                                </div>
                                            ) : (
                                                <input
                                                    type="text"
                                                    value={question.userAnswer || ''}
                                                    disabled={score !== null}
                                                    onChange={(e) => handleAnswerChange(index, e.target.value)}
                                                    placeholder="Type your answer here"
                                                    className="px-4 py-2 border rounded-lg border-gray-300 dark:border-gray-600 bg-gray-50 dark:bg-transparent text-gray-900 dark:text-gray-900 focus:outline-none focus:ring-2 focus:ring-green-400 dark:focus:ring-green-500"
                                                />
                                            )}

                                            {score !== null && (
                                                <p>
                                                    {question.userAnswer?.toLowerCase() ===
                                                    question.correctAnswer.toLowerCase() ? (
                                                        <CheckCircle className="text-green-500" />
                                                    ) : (
                                                        <CloseOutlined className="text-red-500" />
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </>
                ))}
            </div>

            {questions && questions.length > 0 && (
                <div className="flex flex-col md:flex-row justify-between items-center  w-full">
                    <div
                        className={`${
                            questions[0].type !== 'flashcard'
                                ? 'items-center w-full'
                                : 'justify-between items-start md:w-[73%] w-full'
                        } flex flex-col md:flex-row gap-y-4 md:gap-x-3 mt-2 mb-12  px-4 md:p-0`}
                    >
                        {/* <p className=" block md:hidden text-sm text-center text-gray-500 dark:text-gray-100 flex items-center justify-center py-1">
                                    {step + 1} of {questions.length}
                                </p> */}
                        <button
                            onClick={handleGenerate}
                            className="bg-gray-500 text-white px-4 py-2 w-full md:w-auto rounded-lg shadow-lg hover:bg-gray-600 focus:ring-2 focus:ring-gray-300 dark:focus:ring-green-500"
                        >
                            Regenerate
                        </button>
                        {questions[0].type !== 'flashcard' ? (
                            <button
                                onClick={handleSubmit}
                                disabled={score !== null}
                                className="bg-green-500 text-white px-4 py-2 w-full md:w-auto rounded-lg shadow-lg hover:bg-green-600 focus:ring-2 focus:ring-green-300 dark:focus:ring-green-500"
                            >
                                Submit
                            </button>
                        ) : (
                            <div className="flex flex-col w-full items-center md:items-end gap-y-4">
                                <div className="flex flex-col md:flex-row items-center gap-y-4 md:gap-x-4 w-full">
                                    <button
                                        onClick={handleBack}
                                        disabled={step === 0}
                                        className="bg-gray-100 text-gray-700 font-bold px-4 py-2 w-full md:w-auto rounded-lg shadow-md hover:bg-gray-400 disabled:opacity-50"
                                    >
                                        Back
                                    </button>
                                    <p className=" hidden md:block text-sm text-center text-gray-500 dark:text-gray-100">
                                        {step + 1} of {questions.length}
                                    </p>
                                    <button
                                        onClick={handleNext}
                                        disabled={step === (questions?.length ?? 0) - 1}
                                        className="bg-green-500 text-white font-bold px-4 py-2 w-full md:w-auto rounded-lg shadow-md hover:bg-green-600 disabled:opacity-50"
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>

                    <button
                        className=" w-full md:w-auto text-white bg-customOrange rounded  px-3 py-2 mb-12 "
                        onClick={() => setShowDownload(true)}
                    >
                        Download
                    </button>

                    {showDownload && (
                        <Download
                            handlePDF={handlePDF}
                            handleWord={handleWord}
                            url={UrlRef.current}
                            onClose={() => setShowDownload(false)}
                            isPdfDownloading={pdfDownloading}
                            isWordDownloading={wordDownloading}
                            isAnswer={isAnswer}
                            setIsAnswer={setIsAnswer}
                            showCheck={true}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default Quiz;

// Usage example
/*
import React from 'react';
import Quiz from './Quiz';

const quizQuestions = [
    {
        question: "What is 2 + 2?",
        options: ["3", "4", "5", "6"],
        correctAnswer: "4",
        type: "multiple-choice",
    },
    {
        question: "Is the earth round?",
        options: ["true", "false"],
        correctAnswer: "true",
        type: "true-false",
    },
    {
        question: "The capital of France is ___",
        options: [],
        correctAnswer: "Paris",
        type: "fill-in-the-blank",
    },
    {
        question: "Explain photosynthesis.",
        options: [],
        correctAnswer: "A process by which plants make food.",
        type: "short-answer",
    },
];

const App = () => {
    const handleQuizSubmit = (score: number, results: QuizQuestion[]) => {
        console.log(`Score: ${score}/${quizQuestions.length}`);
        console.log("Results:", results);
    };

    return <Quiz questions={quizQuestions} onSubmit={handleQuizSubmit} />;
};

export default App;
*/
