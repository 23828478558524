/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
import DashboardFrame from '@src/components/DashboardFrame';
import { useCallback, useEffect, useRef, useState } from 'react';

import LoadingComponent from '@src/components/LoadingComponent';

import BackButton from '@src/components/BackButton';
import Profile from '@assets/Avatar.svg';
import MarkdownDesign from '@src/components/Markdown';

import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { BASE_URL } from '@src/utils/constants';
import { toast } from 'react-toastify';

import { io, Socket } from 'socket.io-client';
import { prompts } from '@src/utils/prompts';
import { openCurriculumEditor, openEditor } from '@src/utils/app_functions';
import { v4 as uuidv4 } from 'uuid';

import { FileContent, HistoryTypeFormat } from '@src/core/interfaces/chat';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';

import { Input as Inputs, Button } from 'antd';
import {
    ChatType,
    getHistory,
    handleAddHistory,
    handleDeleteAllHistory,
    handleDeleteHistory,
} from '@src/pages/dashboard/endpoints';
import { ChatContent, Chats } from '@src/pages/dashboard/components/interface/interface';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import Input from '@src/pages/dashboard/components/Input';
import UploadComponent from './upload_component';

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const CoverLetterWritter = () => {
    const [isMobile, setIsMobile] = useState(true);
    const [histories, setHistories] = useState<any>([]);
    const [chatList, setChatList] = useState<ChatType[]>([]);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });
    const [generate, setGenerate] = useState(false);
    const [generating, setGenerating] = useState(false);
    const [promptSent, setPromptSent] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [socket, setSocket] = useState<Socket | null>(null);
    const [canNavigate, setCanNavigate] = useState(false);
    const [option, setOption] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [showingHistory, setShowingHistory] = useState(false);
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { loading, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();
    const [currentStep, setCurrentStep] = useState(0);
    const [relevantExperience, setRelevantExperience] = useState('');
    const {
        setShowTop,
        setShowSidebar,
        setHistory,
        setMobileNavStyle,
        setShowPagePath,
        setShowHistoryOption,
        setShowRightComponent,
        setShowHistory,
        response,
        setResponse,
        details,
    } = useOutletContext<any>();

    const pageHistory = 'essay';
    const navigate = useNavigate();
    const baseurl = BASE_URL;
    const bottomRef = useRef<HTMLDivElement>(null);
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [jobRole, setJobRole] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [skills, setSkills] = useState('');
    const [extractedFile, setExtractedFile] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [Value, setValue] = useState('');
    const [additionalInformation, setAdditionalInformation] = useState('');

    // useEffect(() => {
    //     if (isTyping && response != '') {
    //         console.log('scrolling');
    //         bottomRef.current?.scrollTo({
    //             top: bottomRef.current?.scrollHeight,
    //             behavior: 'smooth',
    //         });
    //     }
    // }, [isTyping, response]);

    useEffect(() => {
        const newSocket = io(baseurl.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const steps = [
        {
            label: 'Enter Job Role',
            content: (
                <div className="dark:bg-[#3b4252] bg-white p-6 rounded-lg w-full max-w-lg border border-gray-500 relative focus-within:border-blue-500">
                    <textarea
                        className="w-full h-24 bg-transparent text-black dark:text-white border-none outline-none resize-none"
                        placeholder="Enter your job role."
                        value={jobRole}
                        onChange={(e) => setJobRole(e.target.value)}
                    />
                    <button
                        className="absolute bottom-4 right-4 bg-customOrange text-white px-4 py-2 rounded-md hover:bg-orange-600 transition dark:placeholder-gray-200"
                        onClick={() => {
                            if (!jobRole) {
                                toast.error('Please enter a job role.');
                                return;
                            }
                            handleNext();
                        }}
                    >
                        Next
                    </button>
                </div>
            ),
        },
        {
            label: 'Enter Job Description',
            content: (
                <div className="dark:bg-[#3b4252] bg-white p-6 rounded-lg w-full max-w-lg border border-gray-500 relative focus-within:border-blue-500">
                    <textarea
                        className="w-full h-24 bg-transparent text-black dark:text-white border-none outline-none resize-none dark:placeholder-gray-400"
                        placeholder="Enter your job description."
                        value={jobDescription}
                        onChange={(e) => setJobDescription(e.target.value)}
                    />
                    {/* <button
                        className="absolute bottom-4 right-4 bg-customOrange text-white px-4 py-2 rounded-md hover:bg-orange-600 transition"
                        onClick={() => {
                            if (!jobRole) {
                                toast.error('Please enter a job description.');
                                return;
                            }
                            handleNext();
                        }}
                    >
                        Next
                    </button> */}
                </div>
            ),
        },
        {
            label: 'Upload Resume',
            content: (
                <div className="mx-10">
                    <UploadComponent extractedFile={extractedFile ?? ''} setExtractedFile={setExtractedFile} />
                </div>
            ),
        },

        {
            label: 'Additional Information (Optional)',
            content: (
                <Inputs.TextArea
                    value={additionalInformation}
                    onChange={(e) => setAdditionalInformation(e.target.value)}
                    placeholder="Enter Relevant Experience"
                    className="border border-gray-500 rounded-md p-2 w-full"
                    rows={3}
                />
            ),
        },
    ];

    const handleNext = () => {

        if (currentStep === 0) {
            if (!jobRole) {
                toast.error('Please enter a job role.');
                return;
            }
        } else if (currentStep === 1) {
            if (!jobDescription) {
                toast.error('Please enter a job description.');
                return;
            }
        } else if (currentStep === 2) {
            if (!extractedFile) {
                toast.error('Please upload a resume.');
                return;
            }
        }
        if (currentStep < steps.length - 1) setCurrentStep(currentStep + 1);
    };

    const handleBack = () => {
        if (currentStep > 0) setCurrentStep(currentStep - 1);
    };

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setResponse((response: string) => response + data);
            console.log('Received data:', data);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            // setShowEditorButton(true);
            const { assistant } = data;
            setStreamEndMessage(assistant);
            setIsTyping(false);
            setCanNavigate(true);
            // handle stream end
            const uuid = uuidv4();
            setDocId(uuid);

            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: data.assistant,
                });
            }
            socket?.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    if (generate || showingHistory) {
        setShowTop(false);
        setShowSidebar(false);
    } else {
        setShowTop(true);
        setShowSidebar(true);
    }

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: prompts.curriculum,
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);

    const getPageHistory = useCallback(() => {
        getHistory(pageHistory).then((response) => {
            if (response?.statusCode === 1) {
                const data = response?.data;
                const filtered: HistoryTypeFormat[] = data?.map((d: any) => {
                    return {
                        date: d.date,
                        histories: d.histories?.map((history: any) => {
                            return [
                                ...history.conversation,
                                { chat_id: history?.chat_id },
                                { id: history._id },
                                { date: history.updatedAt },
                            ];
                        }),
                    };
                });

                console.log(filtered);
                setHistories(filtered);
            } else {
                setHistories([]);
            }
        });
    }, [pageHistory, setHistories]); // Dependencies

    useEffect(() => {
        if (response) {
            console.log(response);
            setEditorContent(response);
            setTranslatedContent(response);
        }
    }, [response]);

    const onGenerateHandler = async (message: string) => {
        if (!message) {
            toast.error("Input can't be empty");
            return;
        }
        toast.info('Please sit tight, your beautiful content is on its way.');
        // setIsTyping(true);
        let msgs = chats;
        msgs = { role: 'user', content: message, type: 'gpt-4' };
        setChats(msgs);
        setResponse('');
        setIsTyping(true);

        try {
            socket?.emit('data', {
                data: {
                    messages: [
                        {
                            role: 'system',
                            //   content: `You are G-Mind. You can help with all educational or academic questions or tasks`,
                            content: prompts.essay,
                            type: 'gpt-4',
                        },
                        msgs,
                    ],
                    userId: details?.userId,
                },
            });
            await handleAddHistory([{ role: 'user', content: message }, msgs], pageHistory, {});
            getPageHistory();
        } catch (error) {
            // setIsTyping(false);
        } finally {
            // setIsTyping(false);
        }
    };

    const onFinish = async () => {
        const promptMessage = `
        You are G-Mind, an expert in educational and academic tasks and a professional cover letter writer. 
        Generate a tailored cover letter based on the following details:
    
        Job Description: ${jobDescription}
        User Skills: extract users skill from from this resume text ${extractedFile} or use this ${Value}
        Role: ${jobRole}
        Additional Information: ${additionalInformation}
    `;

        console.log(promptMessage);
        setPromptSent(promptMessage);
        await onGenerateHandler(promptMessage);
        setGenerate(true);
        setGenerating(true);
    };

    useEffect(() => {
        setShowHistory(!isMobile);
        setShowSidebar(true);
        setShowRightComponent(false);
        setMobileNavStyle(isMobile ? { position: 'fixed' } : {});
        setHistory(histories);
        setShowTop(true);
        setShowPagePath(false);
        setShowHistoryOption(true);
    }, [
        setShowHistory,
        setShowSidebar,
        setShowRightComponent,
        setMobileNavStyle,
        setShowTop,
        setShowPagePath,
        isMobile,
        setHistory,
        histories,
        setShowHistoryOption,
    ]);

    useEffect(() => {
        console.log(`generate ${generate}`);
        console.log(`showing History ${showingHistory}`);
    }, [generate, showingHistory]);

    useEffect(() => {
        if (response) {
            setEditorContent(response);
        }
    }, [response]);

    if (generate || showingHistory) {
        setShowTop(false);
        setShowSidebar(false);
    } else {
        setShowTop(true);
        setShowSidebar(true);
    }

    return (
        <div
            ref={bottomRef}
            className={`${!generate && !showingHistory ? 'h-full  overflow-auto pt-7 pb-20 px-[2rem]' : 'h-full  overflow-auto'}`}
        >
            {!generate && !showingHistory ? (
                <div className="flex flex-col pb-4  pt-20 xl:pt-4">
                    <BackButton
                        onclick={() => {
                            navigate(-1);
                        }}
                    />

                    <div className="flex flex-col   items-center">
                        <h2 className="text-[2rem] md:text-[2.5rem] font-bold md:h-[4rem] text-center  font-bold h-[4rem]text-gray-800 dark:text-gray-100 mt-5">
                            Cover Letter Writer
                        </h2>
                        <h3 className="text-[1rem] md:text-[1.125rem] text-center text-gray-700 dark:text-gray-400 mb-3">
                            {steps[currentStep].label}
                        </h3>

                        <div className="mt-[2.5rem] w-full  max-w-lg flex flex-col items-center">
                            {steps[currentStep].content}
                        </div>

                        <div className="flex  items-center gap-4 justify-center mt-4 w-full md:w-[50%]">
                            {currentStep !== 0 && (
                                <Button
                                    onClick={handleBack}
                                    disabled={currentStep === 0}
                                    className="px-4 py-2 rounded-md bg-gray-300"
                                >
                                    Back
                                </Button>
                            )}
                            {currentStep < steps.length - 1 ? (
                                currentStep !== 0 && (
                                    <button
                                        onClick={handleNext}
                                        className="px-4 py-2 rounded-md bg-customOrange text-white"
                                    >
                                        Next
                                    </button>
                                )
                            ) : (
                                <button
                                    onClick={onFinish}
                                    disabled={loading || !jobRole || !jobDescription}
                                    className={`px-4 py-2 rounded-md ${loading ? 'bg-gray-300' : 'bg-customOrange text-white'}`}
                                >
                                    {loading ? 'Generating...' : 'Generate Cover Letter'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="w-full flex flex-col gap-0 ">
                    <NewEditorPage
                        setDocId={setDocId}
                        docId={docId}
                        editorInstanceRef={editorInstanceRef}
                        content={
                            <NewSunEditorComponent
                                latestRespone={latestRespone}
                                setLatestRespone={setLatestRespone}
                                highlightedTexts={highlightedTexts}
                                setHighlightedTexts={setHighlightedTexts}
                                editorInstanceRef={editorInstanceRef}
                                // humanizeded_content={Document.current?.data.ops}
                                // hasAccess={hasAccess}
                                hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                                initial_content={response}
                                onChangeEditorContent={(e: string) => {
                                    setEditorContent(e);
                                    const newtext = e;
                                    console.log('newtext', newtext);

                                    console.log('docId', docId);
                                    console.log('userDetails?.userId', userDetails?.userId);

                                    sendChanges(docId, userDetails?.userId, e);
                                }}
                            />
                        }
                        showTopNavBar={false}
                        showInvite={true}
                        isTyping={isTyping}
                        Profile={Profile}
                        promptSent={promptSent}
                        showingHistory={showingHistory}
                        setGenerate={setGenerate}
                        setShowingHistory={setShowingHistory}
                        generate={generate}
                        setEditorContent={setEditorContent}
                        editorContent={editorContent}
                        translatedContent={translatedContent}
                        setTranslatedContent={setTranslatedContent}
                        humanizedContent={humanizedContent}
                        setHumanizedContent={setHumanizedContent}
                        setUserOnDoc={setUserOnDoc}
                        userOnDoc={userOnDoc}
                        latestRespone={latestRespone}
                        setLatestRespone={setLatestRespone}
                        highlightedTexts={highlightedTexts}
                        setHighlightedTexts={setHighlightedTexts}
                        regenerate={onFinish}
                    />
                </div>
            )}
        </div>
    );
};

export default CoverLetterWritter;
