/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import { NewContentProps, newGenerationHomeDataProps } from '../interface';
import BackButton from '@src/components/BackButton';
import { newGenerationHomeData } from '../constants';
import chevronRight from '@icons/chevron-right.svg';
import WorkSheetGenerator from '../sub_pages/worksheet_generator';
import ClassLetter from '../sub_pages/class_letter';
import TextReWriter from '../sub_pages/text_rewriter';
import Report from '../sub_pages/reports';
import { useNavigate, useOutletContext } from 'react-router';
import QuoteOfTheDay from '../sub_pages/quote';
import HooksGenerator from '../sub_pages/hooks_generator';
import ExitTicketGenerator from '../sub_pages/exit_ticket_generator';
import SlideMaker from '../../slide';
import useFormStore from '@src/providers/formStore';

import { Import } from 'lucide-react';
import './global.scss';
import TextSummarizer from '../sub_pages/text_summarizer';
import { ThemeContext } from '@src/theme_provider/theme';
import './global.scss';
import { RightOutlined } from '@ant-design/icons';
import { ArrowRightOutlined } from '@ant-design/icons';
import EditorInterface from '../sub_pages/double_input';
import Page from '../../speech';
import NewLetterWriter from '../sub_pages/letter_writter/lesson';

const NewGenerateLetter = ({
    setShowSidebar,
    onGenerate,
    setPageName,
    currentIndex = 0,
    setTopic,
    setPageNumber,
}: NewContentProps) => {
    const [currentPage, setCurrentPage] = useState<number>(currentIndex);
    const [contentOptions, setContentOptions] = useState<newGenerationHomeDataProps[]>(newGenerationHomeData);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    const resetSlide = useFormStore((state) => state.goToStep);
    const { savedTheme, toggleTheme } = useContext(ThemeContext);

    const { subPage, setSubPage, showHistoryModal, setShowTop, response, setResponse, details } =
        useOutletContext<any>();

    useEffect(() => {
        if (currentPage === 0) {
            setSubPage('');
        }
    }, [subPage]);

    useEffect(() => {
        if (user.plan && user.plan?.restrictions) {
            const canUseLetter = user.plan.restrictions.letterWriter;
            const canUseArticleWritter = user.plan.restrictions.articleWriter;

            const contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer' && !canUseLetter) return false;
                if (e.label === 'article' && !canUseArticleWritter) return false;

                return true;
            });

            setContentOptions(contentOptions);
        } else if (user.freeTrialAvailable) {
            const canUseLetter = true;
            const canUseArticleWritter = true;

            const contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer' && !canUseLetter) return false;
                if (e.label === 'article' && !canUseArticleWritter) return false;

                return true;
            });

            setContentOptions(contentOptions);
        } else {
            const contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer') return false;
                if (e.label === 'article') return false;

                return true;
            });

            setContentOptions(contentOptions);
        }
    }, [window.location.pathname]);

    return (
        <div
            className={`w-full flex flex-col h-auto md:h-screen  overflow-y-scroll  ${currentPage > 0 ? (currentPage === 6 || currentPage === 7 ? '' : 'pt-5 pb-20') : 'pt-7 pb-2'} px-[2rem] gap-y-2`}
        >
            {currentPage > 0 && currentPage !== 8 && currentPage !== 6 && currentPage !== 7 && (
                <BackButton
                    onclick={() => {
                        setCurrentPage(0);
                        setPageNumber(0);
                        setShowSidebar(null);
                        setPageName('');
                        setResponse('');
                        console.log('hdjdd');
                        resetSlide(0);
                        setSubPage('');
                    }}
                />
            )}
            {currentPage == 1 && <TextReWriter onGenerate={(e, t) => onGenerate(e, t)} setTopic={() => {}} />}
            {currentPage == 2 && (
                <WorkSheetGenerator setTopic={(e) => setTopic(e)} onGenerate={(e, t) => onGenerate(e, t)} />
            )}
            {currentPage == 3 && (
                <QuoteOfTheDay setTopic={(e) => setTopic(e)} onGenerate={(e, t) => onGenerate(e, t)} />
            )}
            {currentPage == 4 && <ClassLetter setTopic={(e) => setTopic(e)} onGenerate={(e, t) => onGenerate(e, t)} />}
            {currentPage == 5 && <Report setTopic={(e) => setTopic(e)} onGenerate={(e, t, p) => onGenerate(e, t, p)} />}
            {currentPage == 6 && (
                <EditorInterface
                    response={response}
                    setResponse={setResponse}
                    setCurrentPage={setCurrentPage}
                    setPageName={setPageName}
                    setPageNumber={setPageNumber}
                    setShowSidebar={setShowSidebar}
                    setShowTop={setShowTop}
                    setSubPage={setSubPage}
                    pageName={'Lesson Hook'}
                />
            )}
            {currentPage == 7 && (
                <EditorInterface
                    response={response}
                    setResponse={setResponse}
                    setCurrentPage={setCurrentPage}
                    setPageName={setPageName}
                    setPageNumber={setPageNumber}
                    setShowSidebar={setShowSidebar}
                    setShowTop={setShowTop}
                    setSubPage={setSubPage}
                    pageName={'Exit Ticket'}
                />
            )}
            {currentPage == 8 && <SlideMaker setCurrentPage={setCurrentPage} />}
            {currentPage === 14 && (
                <NewLetterWriter
                    response={response}
                    setResponse={setResponse}
                    setCurrentPage={setCurrentPage}
                    setPageName={setPageName}
                    setPageNumber={setPageNumber}
                    setShowSidebar={setShowSidebar}
                    setShowTop={setShowTop}
                    setSubPage={setSubPage}
                    pageName={'Learning Materials'}
                />
            )}

            {currentPage == 0 && (
                <div className="w-full flex flex-col  items-center justify-center">
                    <p className="text-customOrange font-medium text-base leading-7 text-center">
                        {details ? `Hi ${details.firstName || ''} ${details.lastName || ''}!👋.` : 'Hi there!👋.'}
                    </p>
                    <h2 className="self-start md:self-center dark:text-white font-medium text-[2rem] md:text-[2.5rem] text-[var(--gmind-black)] text-center lg:h-[4rem]">
                        Generate Different Educator Resources
                    </h2>
                    <p className="mx-0 md:mx-20 self-start dark:text-gray-300 md:self-center text-[1.125rem] md:text-[0.8rem] text-[var(--gmind-white60)] mb-2 text-center max-w-[734px]">
                        {' '}
                        Explore standardized content tailored to your needs! Click to unlock resources, carefully
                        curated to align with academic standards, tailored to meet your unique needs and goals
                    </p>

                    <div className="flex flex-wrap justify-center items-center mb-20  gap-x-3 my-3 w-full">
                        {contentOptions.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    setResponse('');
                                    setPageName(item.pageName);
                                    setShowSidebar(true);
                                    setSubPage(item.pageName);
                                    if (item.naviagatePage) {
                                        navigate(`/${item.label}`);
                                        return;
                                    }
                                    setCurrentPage(item.pageIndex);
                                    setPageNumber(item.pageIndex);
                                }}
                                className={`w-full ${showHistoryModal ? 'lg:w-[calc(43.33%-8px)]' : 'lg:w-[calc(33.33%-8px)] md:w-[65%] lg:w-[55%]'} lg:w-[400px] w-full'}  flex flex-row dark:bg-[#000B27] dark:shadow-lg   dark:hover:shadow-lg dark:hover:shadow-gray-300 items-center h-[6.5rem] px-2.5 py-2.5 mb-3 bg-[#E8ECEF] dark:shadow-gray-500 shadow shadow-gray-600 shadow-lg rounded-lg 
                 cursor-pointer hover:shadow-2xl`}
                            >
                                <div className="w-full flex items-center justify-between gap-x-2">
                                    {/* Left-aligned image and text */}
                                    <div className="flex items-center gap-x-4 flex-grow">
                                        <img
                                            src={item.img}
                                            className={`${
                                                item.pageIndex === 14
                                                    ? 'w-12 md:w-12 h-12 md:h-12 rounded-md'
                                                    : 'w-12 md:w-12 h-12 md:h-12 rounded-md'
                                            }`}
                                            alt={item.head}
                                        />

                                        <div className="flex flex-col gap-y-1">
                                            <h5 className="font-bold dark:text-white text-[0.7rem] md:text-[0.9rem] text-[var(--gmind-light-black)]">
                                                {item.head}
                                            </h5>
                                            <p className="font-normal dark:text-gray-300 text-[0.6rem] md:text-[0.6rem] text-[var(--gmind-white60)]">
                                                {item.text}
                                            </p>
                                        </div>
                                    </div>

                                    {/* Right-aligned arrow */}
                                    <ArrowRightOutlined className="text-[var(--gmind-black)] dark:text-white text-[0.7rem]" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewGenerateLetter;
