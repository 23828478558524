/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import send from '@assets/send.svg';
import microphone from '@assets/microphone.svg';
import photoPicker from '@icons/photo.svg';
import cancelImg from '@assets/cancel.svg';
import earthImg from '@icons/earth.png';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Input as FormInput, Tooltip } from 'antd';
import { uploadChatFile, uploadChatPicture } from '../endpoints';
import { Spin, Skeleton } from 'antd';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import imageCompression from 'browser-image-compression';
import { ThemeContext } from '@src/theme_provider/theme';
import { FileContent } from '@src/core/interfaces/chat';
import pdfIcon from '@icons/pdf.png';
import docxIcon from '@icons/docx.png';
import { extractTextFromDOCX, extractTextFromPDF } from '../function/pdf_doc_helper';
import { ArrowUp, Paperclip } from 'lucide-react';
import { getUrlPath } from '@src/utils/app_functions';
import { chatRoutePages } from '@src/utils/app_routes';
import TextareaAutosize from 'react-textarea-autosize';
import "./InviteModal.scss"

const Input = ({
    value,
    showLeftField,
    placeholder = 'Ask your question',
    img,
    setImg,
    onPaste,
    isNotChat,
    extractedFile,
    setExtractedFile,
    Value,
    setValue,
    isReasoning,
    onSelectReasoning,
}: {
    value: (v: string, img: string[], files: FileContent[]) => void;
    showLeftField?: boolean;
    placeholder?: string;
    img?: File | null;
    onPaste?: (e: any) => void;
    setImg?: React.Dispatch<React.SetStateAction<File | null>>;
    isNotChat?: boolean;
    extractedFile?: string;
    setExtractedFile?: React.Dispatch<React.SetStateAction<string>>;
    Value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    isReasoning?: boolean;
    onSelectReasoning?: () => void;
}) => {
    const inputRef = useRef<any>();
    const recognitionRef = useRef<any>(null);
    const [recording, setRecording] = useState(false);
    const [loading, setLoading] = useState(false);

    const [rows, setRows] = useState(1);
    const [imageSelected, setImageSelected] = useState<{ url: string; loading: boolean }[]>([]);
    const [filesSelected, setFilesSelected] = useState<{ file: FileContent; loading: boolean }[]>([]);
    const [uploading, setUploading] = useState(false);
    const { savedTheme } = useContext(ThemeContext);

    const imageRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries: IntersectionObserverEntry[]) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // setIsVisible(true); // Load image once it enters the viewport
                        observer.disconnect(); // Stop observing once the image is loaded
                    }
                });
            },
            { threshold: 0.1 }, // Load when 10% of the image is visible
        );

        if (imageRef.current) {
            observer.observe(imageRef.current);
        }

        return () => {
            if (imageRef.current) {
                observer.unobserve(imageRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const compressAndUploadImage = async () => {
            if (!img) return;

            const options = {
                maxSizeMB: 1, // Maximum size in MB
                maxWidthOrHeight: 1920, // Resize dimensions
                useWebWorker: true, // Use web worker for performance
            };

            try {
                // Compress the image before uploading
                const compressedFile = await imageCompression(img, options);

                // Proceed with upload if compression is successful
                if (compressedFile) {
                    await handlePictureUpload(compressedFile);
                } else {
                    toast.error('Image compression failed');
                }
            } catch (error) {
                console.error('Error during image compression or upload:', error);
                toast.error('An error occurred while processing the image');
            } finally {
                setImg?.(null); // Reset img after processing
            }
        };

        compressAndUploadImage();
    }, [img]);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        const file = fileInput.files?.[0];

        console.log('Selected file:', file);

        if (!file) return;
        console.log('Selected file:', file);

        const fileType = file.type;

        if (fileType.startsWith('image/')) {
            const options = {
                maxSizeMB: 1,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };

            try {
                const compressedFile = await imageCompression(file, options);
                setImg?.(compressedFile ?? null);
            } catch (error) {
                console.error('Error during image compression:', error);
                toast.error('Image compression failed');
            }
            //    await handlePictureUpload(file);
            //     return;
        } else if (
            fileType === 'application/pdf' ||
            fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ) {
            // Handle PDF and DOCX files
            const fileTypeValue: 'PDF' | 'DOCX' = fileType === 'application/pdf' ? 'PDF' : 'DOCX';
            try {
                // if ( fileType === 'application/pdf' ){
                //     await handleFileUpload(file, fileTypeValue);
                // }else {
                await handleDocumentUpload(file, fileTypeValue);
                // }

                if (fileType === 'application/pdf') {
                    const doc = await extractTextFromPDF(file);
                    setExtractedFile?.(doc);
                    console.log(doc);
                } else {
                    const doc = await extractTextFromDOCX(file);
                    setExtractedFile?.(doc);
                    console.log(doc);
                }
            } catch (error) {
                console.error('Error during file upload:', error);
                toast.error('File upload failed');
            }
        } else {
            console.warn('Unsupported file type:', fileType);
            return;
        }
    };

    const handlePictureUpload = async (file: File) => {
        const tempUrl = URL.createObjectURL(file); // Create the object URL once
        try {
            setUploading(true);

            // Set the new image with loading state as true
            setImageSelected((prev) => [...prev, { url: tempUrl, loading: true }]);

            const result = await uploadChatPicture(file);

            if (result.success) {
                if (imageSelected.length >= 5) {
                    toast.error('You can only upload 5 pictures');
                    return;
                }
                // Update the image URL and set loading to false
                setImageSelected((prev) =>
                    prev.map((image) =>
                        image.url === tempUrl // Compare with the initially created tempUrl
                            ? { ...image, url: result.url || '', loading: false }
                            : image,
                    ),
                );
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            toast.error('An error occurred while uploading the picture');
        } finally {
            setUploading(false);
        }
    };

    const handleFileUpload = async (file: File, type: 'PDF' | 'DOCX') => {
        const tempUrl = URL.createObjectURL(file); // Create the object URL once
        try {
            if (filesSelected.length >= 1) {
                toast.error('You can only upload 1 file');
                return;
            }

            setUploading(true);

            // Set the new image with loading state as true
            setFilesSelected((prev) => [
                ...prev,
                {
                    file: {
                        url: tempUrl,
                        title: file.name,
                        type: type,
                    },
                    loading: true,
                },
            ]);

            const result = await uploadChatPicture(file);

            if (result.success) {
                // Update the image URL and set loading to false
                setFilesSelected((prev) =>
                    prev.map((file) =>
                        file.file.url === tempUrl // Compare with the initially created tempUrl
                            ? {
                                  ...file,
                                  file: {
                                      url: result.url || '',
                                      title: file.file.title,
                                      type: type,
                                  },
                                  loading: false,
                              }
                            : file,
                    ),
                );
            } else {
                setFilesSelected(
                    (prev) => prev.filter((file) => file.file.url !== tempUrl), // Remove the file matching the tempUrl
                );
                toast.error(result.message);
            }
        } catch (error) {
            // Remove the temporary file
            setFilesSelected(
                (prev) => prev.filter((file) => file.file.url !== tempUrl), // Remove the file matching the tempUrl
            );
            toast.error('An error occurred while uploading the picture');
        } finally {
            setUploading(false);
        }
    };

    const handleDocumentUpload = async (file: File, type: 'PDF' | 'DOCX') => {
        const tempUrl = URL.createObjectURL(file); // Create the object URL once
        try {
            if (filesSelected.length >= 1) {
                toast.error('You can only upload 1 file');
                return;
            }

            setUploading(true);

            // Set the new image with loading state as true
            setFilesSelected((prev) => [
                ...prev,
                {
                    file: {
                        url: tempUrl,
                        title: file.name,
                        type: type,
                    },
                    loading: true,
                },
            ]);

            const result = await uploadChatFile(file);
            console.log(result);

            if (result.success) {
                // Update the image URL and set loading to false
                setFilesSelected((prev) =>
                    prev.map((file) =>
                        file.file.url === tempUrl // Compare with the initially created tempUrl
                            ? {
                                  ...file,
                                  file: {
                                      url: result.url || '',
                                      title: file.file.title,
                                      type: type,
                                  },
                                  loading: false,
                              }
                            : file,
                    ),
                );
            } else {
                setFilesSelected(
                    (prev) => prev.filter((file) => file.file.url !== tempUrl), // Remove the file matching the tempUrl
                );
                toast.error(result.message);
            }
        } catch (error) {
            // Remove the temporary file
            setFilesSelected(
                (prev) => prev.filter((file) => file.file.url !== tempUrl), // Remove the file matching the tempUrl
            );
            toast.error('An error occurred while uploading the picture');
        } finally {
            setUploading(false);
        }
    };

    const calculateRows = (e: any) => {
        const textarea = e.target;
        const lineBreaks = (textarea.value.match(/\n/g) || []).length;

        const scrollHeight = textarea.scrollHeight;
        const computedStyle = window.getComputedStyle(textarea);
        const lineHeight = parseInt(computedStyle.lineHeight, 10) || 20;
        const newRows = Math.ceil(scrollHeight / lineHeight);

        setRows(Math.max(lineBreaks, newRows));
    };

    const handleInput = (e: any) => {
        setValue(e.target.value);
        calculateRows(e); // Update rows on input
    };

    const startRecording = () => {
        try {
            toast.success('Recording in progress...');
            setRecording(true);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            recognitionRef.current = new window.webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                setValue(texts.join(' '));
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    const stopRecording = () => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                recognitionRef.current.stop();
                setRecording(false);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    useEffect(() => {
        return () => {
            // Stop the speech recognition if it's active
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        };
    }, []);

    function handleSent() {
        // if (Value.trim() == '' && imageSelected.length == 0) return;
        value(
            Value,
            imageSelected.map((img) => img.url),
            filesSelected.map((fileContent) => fileContent.file),
        );
        console.log(value);
        setValue('');
        setImageSelected([]);
        setFilesSelected([]);
    }

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSent();
        }
    }

    async function handleMicrophone() {
        if (recording) {
            stopRecording();
        } else {
            startRecording();
        }
    }

    return (
        <div
            className="relative space-y-2 max-md:h-fit flex flex-col max-md:py-1"
            style={{ flex: '1' }}
        >
            <section className="flex max-md:flex-col items-center gap-2 h-full">
                {showLeftField && (
                    <input
                        type="text"
                        className="border flex-1 h-12 placeholder:font-normal placeholder:text-gray-400 placeholder:text-xs w-full basis-[20%] border-[#f5b99d] transition-all focus:border-brandOrange p-2 px-4 text-nowrap rounded-xl text-xs flex items-center outline-none max-md:py-4"
                        placeholder="Enter your field"
                    />
                )}

                <div
                    style={
                        loading
                            ? { animation: 'pulse 1s ease infinite', userSelect: 'none' }
                            : { userSelect: 'auto', animation: 'none' }
                    }
                    className="flex flex-col gap-y-2 border bg-[#FCFCFC] min-h-[96px] md:min-h-[7rem] lg:flex-1  border-none dark:border-gray-200 rounded-2xl h-full w-full px-3 relative"
                >
                    <div className="flex items-center">
                        {imageSelected.map((image, index) => (
                            <div key={index} className="mx-5 mt-5 rounded-md w-16 h-16 relative">
                                {image.loading ? (
                                    <SkeletonLoader />
                                ) : (
                                    <img src={image.url} alt="" className="w-full h-full rounded-md" />
                                )}
                                <div
                                    className="w-6 h-6 border border-gray-400 rounded-full bg-white absolute right-[-10px] top-[-10px]"
                                    onClick={() => {
                                        const newImages = imageSelected.filter((_, i) => i !== index);
                                        setImageSelected(newImages);
                                    }}
                                >
                                    <img src={cancelImg} alt="cancel" loading="lazy" className="w-full h-full" />
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Text Input Field with Margin for Space */}
                    <div className="py-2 w-full mx-2 ">
                        <TextareaAutosize
                            ref={inputRef}
                            onPaste={onPaste}
                            onInput={(e: any) => {
                                handleInput(e);
                                setValue(e.target.value);
                            }}
                            style={{
                                backgroundColor: 'transparent',
                                color: savedTheme === 'dark' ? '#000' : '#000',
                                
                            }}
                        
                            value={Value}
                            onKeyDown={handleKeyDown}
                            className={`${getUrlPath().pagename === chatRoutePages.smartChat && getUrlPath().pagename === chatRoutePages.chat ?"w-full  flex-1 ] outline-none border-none focus:ring-0 text-sm px-2 placeholder:font-normal placeholder:text-black   placeholder:text-sm":"w-full min-h-[50px] flex-1 ] outline-none border-none focus:ring-0 text-sm px-2 placeholder:font-normal placeholder:text-gray-400  placeholder:text-sm" }`}
                            placeholder={placeholder}
                            
                           
                            maxRows={5}
                        />
                    </div>

                    {/* Paperclip Icon (Bottom Left) */}
                    <div className='flex flex-row items-center justify-between mb-1'>
                        <div className='flex items-center gap-x-3'>
                            <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-300 dark:bg-gray-700 cursor-pointer transition duration-300 hover:bg-gray-400 dark:hover:bg-gray-600">
                                <label htmlFor="fileInput">
                                    <PaperClipOutlined className="text-black dark:text-white text-2xl rotate-[-45deg] " />
                                    <input
                                        id="fileInput"
                                        type="file"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        accept="image/*,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    />
                                </label>
                            </div>
                            { onSelectReasoning && 
                                <div onClick={() => onSelectReasoning() } className={`${ isReasoning ? 'bg-[#EC5306]' : 'bg-[#FFEDE4]' } cursor-pointer border border-[#FFD0B8] py-[0.5rem] px-[0.625rem] rounded-[5.625rem] flex items-center justify-center`}>
                                    <p className={`text-[0.75rem] ${ isReasoning ? 'text-white': 'text-[#EC5306]' } font-normal`}>ThinkDeep</p>
                                </div> 
                            }
                        </div>
                        

                    {/* Audio Icon (Bottom Right) */}

                     {/* Send Button (Arrow-Up in Black Circle) */}
                     <div className='flex flex-row items-center gap-3'>
                     {!isNotChat && (
                        <div
                            className=" flex items-center justify-center w-10 h-10 rounded-full 
                    bg-customOrange cursor-pointer transition duration-300 hover:bg-orange-900"
                            onClick={handleSent}
                        >
                            <ArrowUp className="text-white text-lg" />
                        </div>
                    )}
                    <div
                        className=" flex items-center justify-center w-10 h-10 rounded-full
                bg-gray-300 dark:bg-gray-700 cursor-pointer transition duration-300 hover:bg-gray-400 dark:hover:bg-gray-600"

                    >
                       {recording ? ( <AudioOutlined
                            onClick={() => {
                                if (loading) {
                                    toast.info('Relax, your speech will soon be converted to text 🙂');
                                }
                                !loading && handleMicrophone();
                            }}
                            role="button"
                            className="text-orange-600  text-lg"
                        />):(
                            <AudioOutlined
                            onClick={() => {
                                if (loading) {
                                    toast.info('Relax, your speech will soon be converted to text 🙂');
                                }
                                !loading && handleMicrophone();
                            }}
                            role="button"
                            className="text-black  dark:text-white text-lg"/>
                        )}

                       
                    </div>
                    </div>
                    </div>

                   
                </div>
            </section>
            <p className="bg-[#E8ECEF] dark:bg-[#121c36] text-gray-400 text-xs text-center " >
                Gmind can make mistakes. It's advisable to verify crucial information.
            </p>
        </div>
    );
};
const BounceLoading = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
    return (
        <section
            role="button"
            onClick={onClick && onClick}
            className={`bounce_parent flex justify-center items-center gap-[0.1rem] ${className}`}
        >
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
            <div className="bounce rounded-xl h-full w-2 bg-brandOrange"></div>
        </section>
    );
};

const SkeletonLoader = () => {
    return (
        <div className="relative w-full h-full bg-gray-200 animate-pulse rounded-md">
            <div className="absolute inset-0 bg-gray-300 animate-pulse rounded-md"></div>
        </div>
    );
};

export default Input;
