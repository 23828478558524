import { useContext, useEffect, useState } from 'react';
import { NewContentProps, newGenerationHomeDataProps, NewMediaProps } from '../interface';
import BackButton from '@src/components/BackButton';
import { newGenerationHomeData } from '../constants';
import chevronRight from '@icons/chevron-right.svg';
import BlogWriter from '../sub_pages/blog';
import NewsWriter from '../sub_pages/new_writer';
import { useNavigate, useOutletContext } from 'react-router';
import JingleScriptForm from '../sub_pages/jingle';
import "./global.scss";
import SocialMediaContent from '../../generate/sub_pages/social_media';
import { ThemeContext } from '@src/theme_provider/theme';
import { RightOutlined } from '@ant-design/icons';
import { ArrowRightOutlined } from '@ant-design/icons';
import CopywritingPlanTemplate from '../../business_resources/sub_pages/copy_writing';
import CampaignAdGeneratorForm from '../../business_resources/sub_pages/campaign_ad';



const NewGenerateLetter = ({
    setShowSidebar,
    onGenerate,
    setPageName,
    currentIndex = 0,
    setTopic,
    setPageNumber,
}: NewMediaProps) => {
    const [currentPage, setCurrentPage] = useState<number>(currentIndex);
    const [contentOptions, setContentOptions] = useState<newGenerationHomeDataProps[]>(newGenerationHomeData);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('user') ?? '{}');
    

    const { subPage, setSubPage,showHistoryModal,setResponse, details } = useOutletContext<any>();

    useEffect(()=>{
        if(currentPage === 0){setSubPage('')}
    },[subPage])

    const setSubPageName = (index: number) => {
        switch (index) {
            case 0:
                setSubPage('');
                break;
            case 1:
                setSubPage('Blog Writer');
                break;
            case 2:
                setSubPage('News Writer');
                break;
            case 3:
                setSubPage('Jingle Creator');
                break;
            case 4:
                setSubPage('Social Media Writer');
                break;
            case 5:
                setSubPage('Image Generator');
                break;
            case 6:
                setSubPage('Speech Synthesizer');
                break;

           
            default:
                console.log('Invalid page');
                setSubPage('');
        }
        console.log('SubPage', subPage);
    };

    useEffect(() => {
        if (user.plan && user.plan?.restrictions) {
            let canUseLetter = user.plan.restrictions.letterWriter;
            let canUseArticleWritter = user.plan.restrictions.articleWriter;

            let contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer' && !canUseLetter) return false;
                if (e.label === 'article' && !canUseArticleWritter) return false;

                return true;
            });

            setContentOptions(contentOptions);
        } else if (user.freeTrialAvailable) {
            let canUseLetter = true;
            let canUseArticleWritter = true;

            let contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer' && !canUseLetter) return false;
                if (e.label === 'article' && !canUseArticleWritter) return false;

                return true;
            });

            setContentOptions(contentOptions);
        } else {
            let contentOptions = newGenerationHomeData.filter((e) => {
                if (e.label === 'letter_writer') return false;
                if (e.label === 'article') return false;

                return true;
            });

            setContentOptions(contentOptions);
        }
    }, [window.location.pathname]);
    const { savedTheme, toggleTheme } = useContext(ThemeContext);

    return (
        <div className={`w-full flex flex-col ${currentPage > 0 ? 'pt-5 pb-20' : 'pt-1 pb-2'} px-[2rem] gap-y-2`}>
            {currentPage > 0 && (
                <BackButton
                    onclick={() => {
                        setCurrentPage(0);
                        setPageNumber(0);
                        setShowSidebar(null);
                        setPageName('');
                      
                        setSubPage('');
                    }}
                />
            )}
            {currentPage == 1 && <BlogWriter onGenerate={(e) => onGenerate(e) }  />}
            {currentPage == 2 && <CopywritingPlanTemplate onGenerateCopy={(e) => onGenerate(e)} setTopic={(e) => {}} />}
            {currentPage == 3 && (
                <CampaignAdGeneratorForm setTopic={(e) => setTopic(e)} onGenerate={(e) => onGenerate(e)} />
            )}
            {currentPage == 4 && (
                <SocialMediaContent setTopic={(e) => setTopic(e)} onGenerate={(e) => onGenerate(e)} />
            )}
           
            {currentPage == 0 && (
                <div className="w-full flex flex-col items-center justify-center">                    <p className="text-customOrange font-medium text-base leading-7 text-center">
                        {details ? `Hi ${details.firstName || ''} ${details.lastName || ''}!👋.` : 'Hi there!👋.'}
                    </p>

                    <h2 className="self-start md:self-center font-medium text-[2rem] dark:text-white md:text-[2.3rem] text-[var(--gmind-black)]">
                        Generate Different Media Resources
                    </h2>
                    <p className="mx-0 md:mx-20 self-start md:self-center  dark:text-gray-300 mb-2 text-[1.125rem] md:text-[0.8rem] text-[var(--gmind-white60)] text-center">
                        {' '}
                        Explore standardized content tailored to your needs! Click to unlock resources, carefully
                        curated to align with academic standards, tailored to meet your unique needs and goals
                    </p>

                    <div className="flex flex-wrap justify-center items-center mb-20  gap-x-3 my-3 w-full lg:w-[75%]">
                        {contentOptions.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => {
                                    setPageName(item.pageName);
                                    setShowSidebar(true);
                                    setSubPage(item.pageName);
                                    setResponse('');
                                    if (item.naviagatePage) {
                                        navigate(`/${item.label}`);
                                        return;
                                    }
                                    setCurrentPage(item.pageIndex);
                                    setPageNumber(item.pageIndex);
                                }}
                                className={`w-full ${showHistoryModal ? 'lg:w-[calc(43.33%-8px)]' : 'lg:w-[calc(43.33%-8px)] md:w-[65%] lg:w-[55%]'} lg:w-[400px] w-full'}  flex flex-row dark:bg-[#000B27] dark:shadow-lg   dark:hover:shadow-lg dark:hover:shadow-gray-300 items-center h-[6.5rem] px-2.5 py-2.5 mb-3 bg-[#E8ECEF] dark:shadow-gray-500 shadow shadow-gray-600 shadow-lg rounded-lg 
                                cursor-pointer hover:shadow-2xl`}
                               
                            >
                                <div className="w-full flex items-center justify-between gap-x-2">
                                    <div className="flex gap-x-2 items-center">
                                        {item.pageName.toLowerCase() === 'report' ? (
                                            <div className="flex flex-col items-center justify-center w-12 md:w-12 h-12 md:h-12 bg-[var(--gmind-black)] dark:bg-transparent rounded-md">
                                                {item.imgElement ?? (
                                                    <img
                                                        className="w-12 md:w-12 h-12 md:h-12 rounded-md"
                                                        src={item.img}
                                                        alt={item.head}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <>
                                                {
                                                    index <= 4 ? (
                                                        <img
                                                            className={`w-12 md:w-12 h-12 md:h-12 rounded-md`}
                                                            src={item.img}
                                                            alt={item.head}
                                                        />
                                                    ): (
                                                        <div className={`flex flex-col items-center justify-center  ${ index == 5 ? 'bg-[#28C76F20] dark:bg-transparent' : 'bg-[#43B0FF20]' } rounded-md`}>
                                                            {item.imgElement ?? (
                                                                <img
                                                                    className="w-12 md:w-12 h-12 md:h-12 rounded-md"
                                                                    src={item.img}
                                                                    alt={item.head}
                                                                />
                                                            )}
                                                        </div>

                                                    )
                                                }
                                            </>
                                            
                                        )}
                                        <div className="flex flex-col gap-y-1">
                                            <h5 className="font-bold dark:text-white text-[0.8rem] md:text-[0.9rem] text-[var(--gmind-light-black)]">
                                                {item.head}
                                            </h5>
                                            <p className="font-normal  dark:text-gray-300 text-[0.625rem] md:text-[0.75rem] text-[var(--gmind-white60)]">
                                                {item.text}
                                            </p>
                                        </div>
                                    </div>

                                    {<ArrowRightOutlined  className="text-[var(--gmind-black)] dark:text-white text-[0.7rem]" />}                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewGenerateLetter;
