import { RightOutlined } from '@ant-design/icons';

import React from 'react';

const CustomSteps: React.FC<{ current: number; steps: any }> = ({ current, steps }) => {
    return (
        <div className="flex items-center justify-center bg-transparent py-4">
            <div className="flex items-center w-full gap-4">
                {steps.map((step, index) => (
                    <div key={index} className="flex  flex-row justify-center w-full items-center">
                        <div
                            className={`w-8 h-8 flex items-center justify-center rounded-full 
                ${index <= current ? 'bg-customOrange text-white' : 'bg-[#ec530626]   text-gray-400'}
              `}
                        >
                            {index + 1}
                        </div>
                        <span
                            className={`ml-2 text-sm ${
                                index === current ? 'text-black dark:text-white' : 'text-gray-400 dark:text-gray-100'
                            }`}
                        >
                            {step.title}
                        </span>
                        {index !== steps.length - 1 && (
                            <div className="mx-2">
                                <RightOutlined className="text-sm text-gray-600 dark:text-gray-100" />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CustomSteps;
