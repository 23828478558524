/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect, useRef, useState } from 'react';
import { MyBigMicIcon, MyProfileIcon, MySmallLogoIcon } from '@components/Svg';
import { toast } from 'react-toastify';
import Share from '@public/share.svg';
import Copy from '@assets/essay/copy_NEW.png';
import Download from '@public/download.svg';
import Edit from '@icons/edit.svg';
import logo from '@assets/essay/GMind_Icon_Orange_compressed.png';
import darkLogo from '@assets/essay/GMind_Icon_White_compressed.png';
import { saveAs } from 'file-saver';
import markdownToDelta from 'markdown-to-quill-delta';
import markdownToTxt from 'markdown-to-txt';
import { pdfExporter } from 'quill-to-pdf';
import moment from 'moment';
import axios from 'axios';
import { handleShare, openCurriculumEditor, openEditor } from '@src/utils/app_functions';
import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { Socket, io } from 'socket.io-client';
import { BASE_URL } from '@src/utils/constants';
import { ThemeContext } from '@src/theme_provider/theme';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import { useDebouncer } from '@src/pages/dashboard/function/helper';
import { prompts } from '@src/utils/prompts';
import NewEditorPage from '../../essay/editor/newInstanceOfEditor';
import NewSunEditorComponent from '../../essay/sun_editor copy';
import Profile from '@assets/essay/Avatar.png';
import { Button } from 'antd';
import { FaMicrophone, FaStop } from 'react-icons/fa';

const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

interface promptOptionsType {
    name: string;
    icon: string;
    onClick: (e: string) => void;
}

interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

const RecordAudioBox = ({ back, generate, setGenerate }: { back?: () => void; generate?: any; setGenerate?: any }) => {
    const [isRecording, setIsRecording] = useState(false);
    const recognitionRef = useRef<any>(null);
    const [completeTranscript, setCompleteTranscript] = useState<any[]>([]);
    const [transcript, setTranscript] = useState('');
    const [recordingComplete, setRecordingComplete] = useState(false);
    const waveformContainerRef = useRef<HTMLDivElement | null>(null);
    const [socket, setSocket] = useState<Socket | null>(null);
    const navigate = useNavigate();
    const { savedTheme } = useContext(ThemeContext);
    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const location = useLocation();
    const { error, loading, fetchDocument, document: textDocument, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const editorInstanceRef = useRef<any>();
    const [docId, setDocId] = useState('');
    const [isTyping, setIsTyping] = useState(false);

    const [showingHistory, setShowingHistory] = useState(false);
    const [promptSent, setPromptSent] = useState<string>('');

    const {
        setShowMobileHistory,
        setShowTop,
        setShowSidebar,
        setRightComponentName,
        setRightComponentPlaceholder,
        setRightComponentData,
        setHistory,
        setMiddleClass,
        setMobileNavStyle,
        selectedHistory,
        setShowPagePath,
        setTopExtraData,
        setCloseIdValue,
        setShowHistoryOption,
        setGmindTyping,
        setCanNavigateHome,
        setShowExpiredModal,
        setShowCompleteProfileModal,
        setIsAffiliate,
        setMenu,
        setShowInput,
        setShowHistoryOptions,
        setRightComponentClick,
        setOnDetailsFetched,
        setOnDeleteAllHistory,
        setOnDeleteHistory,
        setOnClickPage,
        setSelectedHistory,
        setValue,
        setShowRightComponent,
        setSchoolAdminPageName,
        setShowSchoolHeader,
        setShowHistory,
        response,
        setResponse,
        getHistory,
    } = useOutletContext<any>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');

    useEffect(() => {
        const Socket = io(BASE_URL.slice(0, -2));

        setSocket(Socket);
    }, []);

    const removeQueryParameter = (key: string) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key); // Remove the query parameter
        window.history.pushState({}, '', url.toString()); // Update the URL without navigation
    };

    function getDocIdFromUrl() {
        try {
            const urlObject = new URL(window.location.href); // Parse the URL
            const doc = urlObject.searchParams.get('document');
            setDocId(doc ?? '');
        } catch (error) {
            console.error('Invalid URL', error);
            return null;
        }
    }

    useEffect(() => {
        console.log(generate);

        if (generate || showingHistory) {
            openCurriculumEditor({
                response,
                pageId: docId,
                promptSent,
                promptType: '',
                navigate,
                pageSocket: socket,
            });
            getDocIdFromUrl();
        } else {
            removeQueryParameter('document');
        }
    }, [generate, showingHistory]);
    const regenerate = () => {};

    const sendChanges = useDebouncer((documentId: string, userId: string, data: string) => {
        console.log('sendChanges', documentId, userId, data);
        socket?.emit('send-changes', {
            documentId,
            userId,
            data,
        });
    }, 1000);

    useEffect(() => {
        if (generate || showingHistory) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate, showingHistory]);

    useEffect(() => {
        if (response) {
            setEditorContent(response);
        }
    }, [response]);

    const startRecording = () => {
        try {
            toast.success('recording in Progress...');
            setIsRecording(true);
            // @ts-ignore
            recognitionRef.current = new window.webkitSpeechRecognition();
            recognitionRef.current.continuous = true;
            recognitionRef.current.interimResults = true;

            recognitionRef.current.onresult = (event: any) => {
                const { transcript } = event.results[event.results.length - 1][0];
                const texts = Array.from(event.results)
                    .map((result: unknown) => (result as any)[0])
                    .map((result: unknown) => (result as any).transcript);
                // check if the system is working
                console.log(`pls do as you are told`);
                setCompleteTranscript(texts);
                setTranscript(transcript);
            };

            recognitionRef.current.start();
        } catch (err: any) {
            toast.error(err.message);
        }
    };

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    useEffect(() => {
        return () => {
            // Stop the speech recognition if it's active
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        };
    }, []);

    const stopRecording = () => {
        try {
            toast('Recording stopped');
            if (recognitionRef.current) {
                // Stop the speech recognition and mark recording as complete
                recognitionRef.current.stop();
                setRecordingComplete(true);
                setIsRecording(true);
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const promptOptionsData: Array<promptOptionsType> = [
        {
            name: 'Copy',
            icon: Copy,
            onClick: () => handleCopyText(),
        },
        {
            name: 'Download',
            icon: Download,
            onClick: () => handleWord(),
        },
        {
            name: 'Open Editor',
            icon: Edit,
            onClick: () => handleOpenEditor(),
        },
    ];

    async function handlePDF() {
        const delta = markdownToDelta(transcript);
        const blob = await pdfExporter.generatePdf({ ops: delta });
        const current_time = moment().format('YYYY-MM-DD HH:mm:ss');
        saveAs(blob as Blob, `gmind_document-${current_time}.pdf`);
    }

    async function handleOpenEditor() {
        let tesToDownload = '';
        completeTranscript.forEach((element) => {
            tesToDownload += element;
            setResponse(tesToDownload);
            setGenerate(true);
        });
    }

    async function handleWord() {
        let tesToDownload = '';
        completeTranscript.forEach((element) => {
            tesToDownload += element;
        });

        const to_markdown = markdownToTxt(tesToDownload);

        const response = await axios.post(
            'https://api-v2.gmind.ai/v1/document/markdown-to-doc',
            { content: to_markdown },
            {},
        );
        const { data: d } = response;
        if (d?.statusCode === 1) {
            const link = document.createElement('a');
            link.download = 'document.docx';
            link.href = `https://api-v2.gmind.ai/${d?.url}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success('Download successful');
        }
    }

    function handleCopyText() {
        navigator.clipboard
            .writeText(transcript)
            .then(() => {
                console.log('Written');
                toast.success('Content copied to clipboard!');
            })
            .catch((err) => {
                console.log('Failed, ', err);
            });
    }

    return (
        <div className="p-0 m-0">
            {generate && (
                <NewEditorPage
                    latestRespone={latestRespone}
                    setLatestRespone={setLatestRespone}
                    highlightedTexts={highlightedTexts}
                    setHighlightedTexts={setHighlightedTexts}
                    editorInstanceRef={editorInstanceRef}
                    regenerate={regenerate}
                    content={
                        <NewSunEditorComponent
                            latestRespone={latestRespone}
                            setLatestRespone={setLatestRespone}
                            highlightedTexts={highlightedTexts}
                            setHighlightedTexts={setHighlightedTexts}
                            editorInstanceRef={editorInstanceRef}
                            // humanizeded_content={Document.current?.data.ops}
                            // hasAccess={hasAccess}
                            hasAccess={!isTyping && (documentResponse?.role ?? '') !== 'view' ? true : false}
                            initial_content={response}
                            onChangeEditorContent={(e: string) => {
                                setEditorContent(e);
                                const newtext = e;
                                console.log('newtext', newtext);

                                console.log('docId', docId);
                                console.log('userDetails?.userId', userDetails?.userId);

                                sendChanges(docId, userDetails?.userId, e);
                            }}
                        />
                    }
                    showTopNavBar={false}
                    showInvite={true}
                    isTyping={isTyping}
                    Profile={Profile}
                    promptSent={promptSent}
                    showingHistory={showingHistory}
                    setGenerate={setGenerate}
                    setShowingHistory={setShowingHistory}
                    generate={generate}
                    setEditorContent={setEditorContent}
                    editorContent={editorContent}
                    translatedContent={translatedContent}
                    setTranslatedContent={setTranslatedContent}
                    humanizedContent={humanizedContent}
                    setHumanizedContent={setHumanizedContent}
                    setUserOnDoc={setUserOnDoc}
                    userOnDoc={userOnDoc}
                />
            )}
            {!generate && (
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column',alignItems:'end' }}>
                        <div className="flex flex-col mt-5  bg-[#F7F7F7] dark:bg-[#000B27] shadow-lg rounded-[0.75rem] p-[1.5rem] border border-[#E6E6E6] dark:border-[#FFFFFF33] rounded-xl w-full md:max-w-lg">
                            {/* Header */}
                            <div className="flex items-center gap-2 w-full">
                                <img src={Profile} alt="Profile" className="w-8 h-8 rounded-full" />
                                <span className="dark:text-white font-normal text-base leading-6 tracking-normal" style={{fontFamily:'Karla'}}>Record Audio To Transcribe</span>
                            </div>

                            {/* Controls */}
                            <div className="flex flex-col md:flex-row gap-4 mt-4 ">
                                <Button
                                    onClick={startRecording}
                                    className="flex items-center gap-2 px-4 py-2 w-60 md:h-[56px] rounded-full border-2 border-white bg-white text-black  hover:bg-gray-200 shadow"
                                >
                                    <div className="p-2 rounded-full bg-[#9068D033]">
                                        <div className="bg-[#8E55EA] h-3 w-3 rounded-full animate-pulse" />
                                    </div>
                                    Start Recording
                                </Button>

                                <Button
                                    onClick={stopRecording}
                                    className="flex items-center gap-2 px-4 py-2 w-60 md:h-[56px] rounded-full border-2 border-white bg-white text-black hover:bg-gray-200 shadow"
                                >
                                    <div className="p-2 rounded-full bg-[#D84C1033]">
                                        <FaStop className="text-orange-500 h-3 w-3" />
                                    </div>
                                    Stop Recording
                                </Button>
                            </div>
                        </div>

                        <div className="flex gap-x-2 flex-col  m-0 p-0 w-full mt-2 w-full md:max-w-[100%]">

                        <div className='flex flex-row  gap-x-3 w-full  mt3 justify-start items-center '>   
                        <div className="flex flex-col mt-5 justify-start bg-[#F7F7F7] dark:bg-[#000B27] min-h-[200px] shadow-lg rounded-[0.75rem] p-3  border border-[#E6E6E6] dark:border-[#FFFFFF33] rounded-xl w-full md:max-w-2xl">

                        {completeTranscript.length > 0 && (
                            <div className="w-full">
                                <div className="mb-[20px] " style={{ display: 'flex', flexDirection: 'column' }}>
                                   
                                   
                                    <div className="mt-[15px] text-black dark:text-white">{completeTranscript}</div>
                                </div>
                            </div>
                        )}
                        </div>
                        </div>

                           
                        
                       <div className='flex flex-row  gap-x-3  mt-3 justify-end items-center w-full md:max-w-2xl'>
                        {promptOptionsData.map((option, index) => (
                            <button
                                key={index}
                                onClick={(e: any) => option.onClick(e)}
                                className=" rounded-lg items-center px-2 py-1 bg-[var(--gmind-header)] dark:bg-[#FFFFFF33] flex gap-x-2"
                            >
                                
                                <div className="font-normal text-[0.75rem] text-[var(--gmind-plain-black)] dark:text-white">
                                    {option.name}
                                </div>
                                <img src={option.icon} className="h-3" alt="" />
                            </button>
                        ))}
                    </div>
                    </div>
                      
                    </div>

                   
                </div>
            )}
        </div>
    );
};

export default RecordAudioBox;
