import { ResponseSources } from '@src/pages/dashboard/components/interface/interface';
import { Card } from 'antd';
import React from 'react';

const ResearchSources = ({ sources }: { sources: ResponseSources[] }) => {
  return (
    <div className="grid grid-cols-1 gap-4 pb-4 px-4">
      {sources.map((source, index) => (
        <a
          key={index}
          href={source.url}
          target="_blank"
          rel="noopener noreferrer"
          className="block"
        >
          <Card
            className="hover:shadow-lg bg-transparent transition-shadow hover:dark:shadow-gray-700 border border-gray-300 dark:border-gray-700 rounded-lg overflow-hidden cursor-pointer"
            style={{ height: '6rem' }}
          >
            <div className="p-2">
              <h2 className="text-md font-semibold dark:text-white truncate">{source.title}</h2>
              <p className="text-xs text-gray-600 dark:text-gray-300">Source: {source.provider}</p>
            </div>
          </Card>
        </a>
      ))}
    </div>
  );
};

export default ResearchSources;
