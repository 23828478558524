import { Form, Input, Select as Selected } from 'antd';
import { useState } from 'react';
import { BaseFormOutput, BaseFormType } from '../interfaces';
import { toast } from 'react-toastify';
import { MyUploadFileIcon } from '@src/components/Svg';
import Close from '@assets/close.svg';
import Notes from '@assets/notes.svg';
import { Select } from '@src/pages/dashboard/components/Select';
import { gradeOptions } from '../constants';
import { extractTextFromDOCX, extractTextFromPDF } from '@src/pages/dashboard/function/pdf_doc_helper';
import { Option } from 'antd/es/mentions';

const BaseForm = ({ onSubmit, firstText, dropDownOptions }: BaseFormType) => {
    const [rememberMe, setRememberMe] = useState(false);
    const [choice, setChoice] = useState('');
    const [purpose, setPurpose] = useState('');
    const [duration, setDuration] = useState('');
    const [level, setLevel] = useState('');
    const [otherLevel, setOtherLevel] = useState('');
    const [resouceLink, setResouceLink] = useState('');
    const [resoucefile, setResouceFile] = useState<File | null>(null);
    const [optionsValue, setOptionsValue] = useState<string[]>([]);
    const [notes, setNotes] = useState('');

    const onFinish = () => {
        if (!choice || !purpose || !level) {
            toast.error('Input field cannot be empty');
            return;
        }

        const levelValue = otherLevel.toLowerCase() == 'others' ? otherLevel : level;
        console.log(level);

        const resourceData = resouceLink ? `\nResource Link:${resouceLink}` : '';
        const doc = `\n Notes:${notes}`;

        const promptMessage = `Create a ${
            doc ? `Use this as a template with the rest: ${doc}` : ''
        } \n${firstText} for ${choice} with this additional information: 
            Duration: ${duration} 
            Subject: ${purpose} 
            Grade Level: ${levelValue} 
            ${resourceData}
            Note: Always include the grade level (${level}) in the prompt.
        `;
        const output: BaseFormOutput = {
            subject: purpose,
            basePrompt: promptMessage,
        };
        onSubmit(output);
    };

    const handleUploadButtonClick = () => {
        // Create an input element for file selection
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.pdf, .doc, .docx'; // Allow only PDF, DOC, DOCX file types
        fileInput.multiple = false; // Allow only single file selection

        // Add event listener for file selection
        fileInput.addEventListener('change', (event) => {
            const target = event.target as HTMLInputElement;
            // Trigger function to handle file selection
            handleFileSelect({ target } as React.ChangeEvent<HTMLInputElement>);
        });

        // Simulate a click on the input element to open file selection dialog
        fileInput.click();
    };

    const handleFileSelect = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        setResouceFile(file);

        // Check for PDF, DOC, or DOCX
        const fileType = file.type;

        if (fileType === 'application/pdf') {
            try {
                const text = await extractTextFromPDF(file);
                setNotes(text);
                console.log('PDF Content:', text);
            } catch (error) {
                toast.error('Error reading PDF file');
            }
        } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            try {
                const text = await extractTextFromDOCX(file);
                setNotes(text);
                console.log('DOCX Content:', text);
            } catch (error) {
                toast.error('Error reading DOCX file');
            }
        } else {
            toast.error('Only PDF and DOCX files are supported.');
        }
    };

    return (
        <Form
            className="mt-5 md:mt-0 flex items-center flex-col"
            layout="vertical"
            onFinish={onFinish}
            initialValues={{ rememberMe }}
        >
            <div className="flex items-center flex-col gap-y-[1.5rem] border border-[#D1D5DB] dark:border-[#FFFFFF33] bg-[#F7F7F7] dark:bg-[#000B27] shadow-lg rounded-[12px] p-[1.5rem] w-full md:w-full md:max-w-[54.375rem]">
                <div className="flex gap-x-3 flex-col md:flex-row flex-wrap w-full items-center">
                    <Form.Item
                        className="w-full md:w-[48%] flex flex-col gap-y-3 mb-3"
                        name={'choose'}
                        label={<label className="curriculum--label">{firstText}</label>}
                        rules={[
                            {
                                message: 'field is required',
                                required: false,
                            },
                        ]}
                    >
                        {/* <Select
                            className="w-full"
                            onSelect={(e) => {
                                setChoice(e);
                            }}
                            options={['', ...dropDownOptions]}
                        /> */}

                        <Selected
                            className="w-full rounded-md h-[3rem] bg-white dark:bg-transparent"
                            value={choice}
                            onChange={(e) => setChoice(e)}
                            placeholder="Select curriculum"
                        >
                            {dropDownOptions.map((item) => (
                                <Option value={item}>{item}</Option>
                            ))}
                        </Selected>
                    </Form.Item>

                    <Form.Item
                        className="w-full md:w-[48%] flex flex-col gap-y-3 mb-3"
                        name={'purpose'}
                        label={<label className="curriculum--label"> Subject</label>}
                        rules={[
                            {
                                message: 'purpose is required',
                                required: false,
                            },
                        ]}
                    >
                        <Input
                            className="w-full placeholder-gray-700  rounded-md h-[3rem] bg-white"
                            value={purpose}
                            onChange={(e) => {
                                setPurpose(e.target.value);
                            }}
                            placeholder="e.g Physics, Project Management etc"
                            style={{
                                border: '1px solid gray',
                               
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                           
                        />
                    </Form.Item>

                    <Form.Item
                        className="w-full md:w-[48%] flex flex-col gap-y-3 mb-3"
                        name={'information'}
                        label={<label className="curriculum--label">Grade / Level</label>}
                        rules={[
                            {
                                message: 'field is required',
                                required: false,
                            },
                        ]}
                    >
                        {level.toLowerCase() == 'others' ? (
                            <Input
                                className="w-full placeholder-gray-700 rounded-md h-[3rem] bg-white"
                                value={otherLevel}
                                onChange={(e) => {
                                    setOtherLevel(e.target.value);
                                }}
                                placeholder="Kindly Enter your predefined grade level"
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '4px',
                                    backgroundColor: 'transparent',
                                    padding: '7px 10px',
                                }}
                            />
                        ) : (
                          
                            <Selected
                            className="w-full rounded-md h-[3rem] bg-white  dark:bg-transparent"
                            value={level}
                            onChange={(e) => setLevel(e)}
                            placeholder="Select grade level"
                        >
                            {gradeOptions().map((item) => (
                                <Option value={item}>{item}</Option>
                            ))}
                        </Selected>
                        )}
                    </Form.Item>

                    <Form.Item
                        className="w-full md:w-[48%] flex flex-col gap-y-3 mb-3"
                        name={'duration'}
                        label={<label className="curriculum--label">Duration ( weeks or days)</label>}
                        rules={[
                            {
                                message: 'field is required',
                                required: false,
                            },
                        ]}
                    >
                        <Input
                            className="w-full placeholder-gray-700 rounded-md h-[3rem] bg-white"
                            value={duration}
                            onChange={(e) => {
                                setDuration(e.target.value);
                            }}
                            placeholder="e.g 10 weeks, 10 days"
                            style={{
                                border: '1px solid gray',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        className="w-full md:w-[48%] flex flex-col gap-y-3 mb-3"
                        name={'link'}
                        label={<label className="curriculum--label">Link to Resource (optional)</label>}
                        rules={[
                            {
                                message: 'field is required',
                                required: false,
                            },
                        ]}
                    >
                        <Input.TextArea
                            className="w-full placeholder-gray-700 rounded-md h-[3rem] bg-white"
                            value={resouceLink}
                            onChange={(e) => {
                                setResouceLink(e.target.value);
                            }}
                            placeholder="Link to resource"
                            style={{
                                border: '1px solid gray',
                                borderRadius: '4px',
                                backgroundColor: 'transparent',
                                padding: '7px 10px',
                            }}
                            rows={1}
                        />
                    </Form.Item>

                    <Form.Item
                        className="w-full md:w-[48%] flex flex-col  gap-y-3 mb-3"
                        name={'choose'}
                        label={<label className="curriculum--label"></label>}
                        rules={[
                            {
                                message: 'field is required',
                                required: false,
                            },
                        ]}
                    >
                        {resoucefile ? (
                            <div className="flex gap-x-4 ">
                                <img src={Notes} alt="Music Icon" />
                                <span className="file--name w-[10rem] lg:w-full  overflow-x-hidden ">
                                    {resoucefile.name}
                                </span>
                                <img
                                    src={Close}
                                    alt="Close Icon"
                                    onClick={() => setResouceFile(null)}
                                    style={{ cursor: 'pointer' }}
                                />
                            </div>
                        ) : (
                            <div
                                className="w-full px-[1.5rem] py-[0.75rem] border-0.5 rounded-custom border-dashed dark:border-white border-gray-700 gap-5 flex flex-row justify-between items-center"
                                style={{ cursor: 'pointer' }}
                                onClick={handleUploadButtonClick}
                            >
                                <div className="flex flex-row items-center gap-2">
                                    <MyUploadFileIcon />
                                    <div className="text-[0.8rem] dark:text-white font-[Inter,sans-serif] font-normal text-black-400 ">
                                        Upload resources here ( Optional )
                                    </div>
                                </div>
                            </div>
                        )}
                    </Form.Item>
                </div>
                <button
                    type="submit"
                    className="text-white mx-3 py-2 px-3  transcribe md:w-[19.43rem] h-[3.25rem] bg-customOrange text-white rounded-[12px]"
                >
                    Generate
                </button>
            </div>
        </Form>
    );
};

export default BaseForm;
