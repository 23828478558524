import { Button, Form, Input, Select } from 'antd';
import { LessonMaterialProps } from '../interface';
import { toast } from 'react-toastify';
import { AudioOutlined, PaperClipOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { gradeOptions } from '../../curriculum/constants';
import './style.scss';

const WorksheetGenerator = ({ onGenerate, setTopic }: LessonMaterialProps) => {
    let grades = gradeOptions();
    const onFinish = (values: any) => {
        let promptMessage = '';

        const gradeLevel = values.gradeLevel;
        const pointScale = values.pointScale;

        // check if all data is passed
        if (!gradeLevel || !pointScale) {
            toast.error('Please enter all prompts field');
            return;
        }

        let informationData = `
           Grade Level: ${gradeLevel}
           Topic: ${pointScale}
        `;

        promptMessage = `Create structured worksheet with this following information: ${informationData}`;

        onGenerate(promptMessage, false);
    };

    return (
        <div className="flex flex-col items-center min-h-screen">
            <Form className="flex flex-col py-4 px-10 dark:text-white space-y-4" onFinish={onFinish} layout="vertical">
                {/* Title Section */}
                <h1 className="text-3xl font-bold dark:text-white text-center">Worksheet Generator</h1>
                <p className="text-[#6C7275] dark:text-white/50 text-center">
                    Generate worksheet based on any topic or text.
                </p>

                {/* Content Container */}
                <div
                    className="bg-[#F7F7F7] dark:bg-[#000B27] rounded-[0.75rem] gap-[1.5rem] p-[1.5rem] shadow-md w-full md:w-[38.625rem] border border-[#E6E6E6] dark:border-[#FFFFFF33] "
                  
                >
                    <Form.Item label="Grade Level" name="gradeLevel" className="w-full">
                        <select className="w-full rounded-md h-[3rem] bg-white dark:bg-[#ffffff33] dark:text-white p-3 border-[#D1D5DB] dark:border-white border">
                            {grades.map((item, index) => (
                                <option key={index} value={item || 'Select grade level'}>
                                    {item || 'Select grade level'}
                                </option>
                            ))}
                        </select>
                    </Form.Item>

                    <Form.Item label="Topic or text" name="pointScale" className="w-full">
                        <div
                            className="flex items-start border dark:border-white border-[#D1D5DB] bg-white dark:bg-[#FFFFFF33]  rounded-[10px] w-full bg-white dark:bg-opacity-20"
                            style={{
                              
                                padding: '12px 14px',
                                borderWidth: '1px',
                                justifyContent: 'space-between',
                            }}
                        >
                            <textarea
                                rows={4}
                                className="p-2 rounded-none border-none w-full focus:outline-none bg-transparent dark:text-white"
                                placeholder="Enter topic to generate worksheet for"
                                style={{ outline: 'none', boxShadow: 'none' }}
                            />
                        </div>
                    </Form.Item>
                </div>

                {/* Generate Button */}
                <div className="flex justify-center mt-4">
                    <button
                        type="submit"
                        className="no-hover-effect w-[311px] h-[52px] bg-customOrange text-white rounded-[12px] flex items-center justify-center"
                    >
                        Generate
                    </button>
                </div>
            </Form>
        </div>
    );
};

export default WorksheetGenerator;
