import React, { useState } from 'react';
import { Dropdown, Button, MenuProps } from 'antd';
import {
    DownOutlined,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined,
} from '@ant-design/icons';
import { useContext } from 'react';
import { ThemeContext } from '@src/theme_provider/theme';

interface ReviewProps {
    contents: string;
    setContent: (content: string) => void;
}

export const Review: React.FC<ReviewProps> = ({ contents, setContent }) => {
    const [contentLines, setContentLines] = useState<string[]>(contents.split(/\d+\.\d* /).filter(Boolean));

    const [editingIndex, setEditingIndex] = useState<number | null>(null);
    const {savedTheme} = useContext(ThemeContext);

    const updateContents = (lines: string[]) => {
        setContentLines(lines);
        setContent(lines.map((line, i) => `${i + 1}. ${line}`).join('\n'));
    };

    const moveItem = (fromIndex: number, toIndex: number) => {
        if (toIndex < 0 || toIndex >= contentLines.length) return;
        const updatedLines = [...contentLines];
        const [movedItem] = updatedLines.splice(fromIndex, 1);
        updatedLines.splice(toIndex, 0, movedItem);
        updateContents(updatedLines);
    };

    const addItem = () => {
        updateContents([...contentLines, 'New Item']);
    };

    const editItem = (index: number, newValue: string) => {
        const updatedLines = [...contentLines];
        updatedLines[index] = newValue;
        updateContents(updatedLines);
    };

    const handleDelete = (index: number) => {
        updateContents(contentLines.filter((_, i) => i !== index));
    };

    return (
        <div className="flex flex-col items-center w-full h-[58vh]  ">
            <h1 className="text-[40px] font-bold text-center dark:text-white text-black h-[64px]">Review & Edit</h1>
            <h2 className="font-normal text-lg leading-7 text-center mb-4 text-gray-500 dark:text-gray-400">
                Review & Edit Your Table of Contents
            </h2>
            <div className="mt-4 flex flex-col items-center bg-[#E6E6E6] border border-gray-600 dark:bg-[#000B27] md:min-w-[38.625rem] rounded-md  p-[1.5rem]  space-y-2   overflow-y-auto">
                {contentLines.map((line, index) => {
                    const menuItems: MenuProps['items'] = [
                        {
                            key: 'edit',
                            label: 'Edit',
                            icon: <EditOutlined />,
                            onClick: () => setEditingIndex(index),
                        },
                        {
                            key: 'delete',
                            label: 'Delete',
                            icon: <DeleteOutlined />,
                            onClick: () => handleDelete(index),
                            disabled: index === 0,
                        },
                        {
                            key: 'move-up',
                            label: 'Move Up',
                            icon: <ArrowUpOutlined />,
                            onClick: () => moveItem(index, index - 1),
                            disabled: index === 0,
                        },
                        {
                            key: 'move-down',
                            label: 'Move Down',
                            icon: <ArrowDownOutlined />,
                            onClick: () => moveItem(index, index + 1),
                            disabled: index === contentLines.length - 1,
                        },

                        {
                            key: 'add-item',
                            label: 'Add Section',
                            icon: <PlusOutlined />,
                            onClick: () => addItem(),
                        },
                    ];

                    return (
                        <div
                            key={index}
                            className="bg-white dark:bg-[#121A3D]  border border-gray-500 rounded shadow p-2 w-full md:min-w-[35.625rem] flex items-center space-x-4 dark:text-white"
                        >
                            <span>{index + 1}.</span>
                            {editingIndex === index ? (
                                <input
                                    type="text"
                                    value={line}
                                    onChange={(e) => editItem(index, e.target.value)}
                                    onBlur={() => setEditingIndex(null)}
                                    className="flex-1 p-1 border rounded focus:outline-none focus:ring focus:ring-blue-300"
                                    autoFocus
                                />
                            ) : (
                                <span className="flex-1">{line}</span>
                            )}
                            <Dropdown menu={{ items: menuItems }} trigger={['click']}>
                                <Button style={{ border: savedTheme === 'dark'? '1px solid #fff':'1px solid #000', color: savedTheme === 'dark' ? '#fff' : '#000', backgroundColor: 'transparent' }}>
                                    Actions <DownOutlined className='dark:text-white' />
                                </Button>
                            </Dropdown>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
