import React, { useContext, useState } from 'react';
import { Card } from 'antd';
import { ThemeContext } from '@src/theme_provider/theme';
import OrangePlus from '@assets/essay/BlackPlus.png';
import BlackPlus from '@assets/essay/plus-circle.png';
import GreyPlus from '@assets/essay/black-plus-circle.png';


const styles = [
    'Opinion Pieces',
    'Creative Writing',
    'News Articles',
    'Professional/Industry Articles',
    'Academic Articles',
];

export const ArticleStyle = ({
    selectedStyle,
    setSelectedStyle,
}: {
    selectedStyle: string;
    setSelectedStyle: React.Dispatch<React.SetStateAction<string>>;
}) => {

    const {savedTheme} = useContext(ThemeContext);
    return (
        <div className="flex flex-col items-center justify-center">
            <h2 className="text-[2.5rem] font-bold h-[4rem] dark:text-gray-300">Article type</h2>
            <p className="text-gray-600 text-[1.125rem] dark:text-gray-300 mb-6">Select your article type</p>
            <div  className=' flex flex-wrap items-center justify-center gap-x-2 gap-y-3 '>
                {styles.map((style) => (
                    <button
                        key={style}
                        
                        className='w-full md:w-auto h-auto flex flex-row items-center justify-center px-[16px] py-[6px] rounded-[6px] dark:text-white shadow-md dark:shadow-gray-500'
                        style={{
                            backgroundColor:savedTheme === 'dark' ? '#000B27' : 'transparent',
                            color: style === selectedStyle ? ' #EC5306' : savedTheme === 'dark' ? '#D9D9D9' : '#000B27',
                            border: style === selectedStyle ? '2px solid #EC5306' : '',
                        }}
                        onClick={() => setSelectedStyle(style)}
                    >
                        {style}  

                        <img src={style === selectedStyle ? OrangePlus : savedTheme === 'dark' ? GreyPlus :BlackPlus } alt="Plus" className='ml-2' />
                    </button>
                ))}
            </div>
        </div>
    );
};
