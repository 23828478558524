/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { Steps, Button, Spin, Modal } from 'antd';
import { ReferenceStyle } from './components/reference_style';
import { Review } from './components/review';
import { ArticleTitle } from './components/title';
import { ArticleTone } from './components/tone';
import { ArticleLength } from './length';
import BackButton from '@src/components/BackButton';
import './index.scss';
import { useDocumentContext } from '@src/context/DocumentContext';
import { useUserContext } from '@src/context/UseProvider';
import {
    ChatType,
    getHistory,
    handleAddHistory,
    handleDeleteAllWithTypeHistory,
    handleDeleteHistory,
} from '../../../../dashboard/endpoints';
import { BASE_URL } from '@src/utils/constants';
import { Chats } from '@src/pages/dashboard/components/interface/interface';

import { useLocation, useNavigate, useOutletContext } from 'react-router';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import FinishPage from './components/finishPage';
import { reference } from '@popperjs/core';
import { CircularProgress } from '@mui/material';
import { ArticleStyle } from './components/research_style';
import { ThemeContext } from '@src/theme_provider/theme';
import { RGrade } from './components/grade_level';
import { ResponseSources, ChatContent, isChatContentArray } from '@src/pages/dashboard/components/interface/interface';
import ResearchSources from './components/SourceCard';
import { normalize } from 'node:path';
import { PlusIcon } from 'lucide-react';
import UseCase from './components/usecase';
import CustomSteps from '../../article/a+_essay/components/StepComponent';

const { Step } = Steps;

interface Section {
    id?: string;
    title: string;
    content: string;
    reference: string[];
    isNew?: boolean;
    sources?: ResponseSources[];
}
interface UserDocOn {
    documentId: string;
    users: string[];
    admin: any;
    collaborators: any[];
}

interface Step {
    title: string;
}

const ResearchWriter = () => {
    const [current, setCurrent] = useState<number>(0);
    const [topic, setTopic] = useState('');
    const [length, setLength] = useState<number>(500);
    const [selectedTone, setSelectedTone] = useState<string>('');
    const [selectedStyle, setSelectedStyle] = useState<string>('');
    const [selectedResearchStyle, setResearchSelectedStyle] = useState<string>('');
    const baseurl = BASE_URL;
    const [streamEndMessage, setStreamEndMessage] = useState<ChatType>({ role: 'assistant', content: '' });
    const [histories, setHistories] = useState<any[]>([]);
    const [page, setPage] = useState<string>('');
    const [generating, setGenerating] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [sideBarVisible, setSidebarVisible] = useState<boolean | null>(null);
    const [basePage, setBasePage] = useState<number>(0);
    const [showEditorButton, setShowEditorButton] = useState(false);
    const [showDownload, setShowDownload] = useState<boolean>(false);
    const [showShareModal, setShowShareModal] = useState<boolean>(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [chats, setChats] = useState<Chats>({ role: '', content: '', type: 'gpt-4' });

    const [newPromptSent, setNewPromptSent] = useState('');

    const [editorContent, setEditorContent] = useState<any>('');
    const [humanizedContent, setHumanizedContent] = useState<any>();
    const [translatedContent, setTranslatedContent] = useState<any>();
    const [editorData, setEditorData] = useState('');
    const { error, loading, fetchDocument, document: textDocument, documentResponse } = useDocumentContext();
    const { userDetails } = useUserContext();
    const [userOnDoc, setUserOnDoc] = useState<UserDocOn>();
    const [highlightedTexts, setHighlightedTexts] = useState<string>('');
    const [latestRespone, setLatestRespone] = useState<string>('');
    const [socket, setSocket] = useState<Socket | null>(null);
    const [message, setMessage] = useState('');
    const StreamId = useRef<any>();
    const DocumentId = useRef('');
    const [canNavigate, setCanNavigate] = useState(false);
    const [basePrompt, setBasePrompt] = useState('');
    const [docId, setDocId] = useState<string | undefined>(undefined);
    const [content, setContent] = useState<string>();

    const [conversation, setConversation] = useState<ChatType[]>([]);
    const [sections, setSections] = useState<Section[]>([]);
    const [isIndex0Emitting, setIsIndex0Emitting] = useState(false);
    const { setShowTop, setShowSidebar, promptSent, details } = useOutletContext<any>();
    const [loadings, setLoadings] = useState(false);
    const essayRef = useRef<HTMLDivElement | null>(null);

    const [streaming, setStreaming] = useState(false);

    const [sockets, setSockets] = useState<{ [key: string]: Socket | null }>({});
    const [contentSockets, setContentSockets] = useState<{ [key: number]: Socket | null }>({});
    const [executiveSockets, setExecutiveSockets] = useState<{ [key: string]: Socket | null }>({});

    const [socketKey, setSocketKey] = useState<string[]>(['1', '2', '3', '4', '5', '6']);
    const [streamStatus, setStreamStatus] = useState<Record<string, boolean>>({});
    const [allStreamsComplete, setAllStreamsComplete] = useState<boolean>(false);
    const [htmlContent, setHtmlContent] = useState<any>();
    const [connectionStatus, setConnectionStatus] = useState<{ [key: string]: boolean }>({});
    const [allSocketsConnected, setAllSocketsConnected] = useState(false);
    const navigate = useNavigate();
    const { isMobile } = useContext(ThemeContext);
    const [grade, setGrade] = useState<string>('');
    const [chatList, setChatList] = useState<Chats[]>([]);
    const [sources, setSources] = useState<ResponseSources[]>([]);
    const [isSummaryGenerating, setIsSummaryGenerating] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inputValue, setInputValue] = useState<string>('');
    const [response, setResponse] = useState<string>('');
    const [childSections, setChildSections] = useState<Section[]>([]);
    const [height, setHeight] = useState<number>(0);

    const onGenerateContent = async (index: number, socketKey: string) => {
        const currentSlide = sections[index];
        const contentNumber = sections.length;
        let no = length / contentNumber;
        const introductionReduction = no * 0.4; // 40% of Introduction word count
        const redistributedWords = introductionReduction; // Redistribute this to index 2

        if (index === 1) {
            no = no * 0.6;
        } else if (index === 2) {
            no += redistributedWords;
        }
        console.log('Number:', no);
        const newPrompt = `
Generate detailed and informative content for the following research subtitle: "${currentSlide.title}" based on the topic "${topic}" using the tone "${selectedTone}, Using this ${grade} level of language, and the research type "${selectedResearchStyle}".
Ensure the research type aligns with "${selectedResearchStyle}."

**Content Requirements:**.
- ** Note: Content should only be taken from 1 source and avoid citing multiple references
- Must contain at least ${Math.floor(no)} words.
- No title or headings in the content.
- Add references as in-text citations within the content.
- Use HTML tags except <i>. Avoid unnecessary formatting.
- Include subheadings for each section. it should be numbering the subheadings. <h2> subheadings should be numbered.</h2>
- The number is ${index + 1} ,
- if ${index === 0} then the number is ${index + 1} so subheadings should be ${index + 1}.1 
- if ${index === 1} then the number is ${index + 1} so subheadings should be ${index + 1}.1, and so on.
- generate content for heading ${index + 1} but it must not have a title just the content.
- Do not give context to the content. Just give the content.



**Reference Formatting:**
- References must be presented in a bullet list using <ul> and <li> tags.
- Format should follow standard citation styles (APA, MLA, Chicago, etc.).
- Each reference should include:
  - Author(s)
  - Year of publication
  - Title (italicized for books, reports, or journals)
  - Journal or book details
  - URL or DOI for online sources.

**Example:**
References:
<ul>
  <li>Smith, J. (2021). <em>The Effects of Climate Change on Global Agriculture</em>. Journal of Environmental Science, 34(5), 112-125. doi:10.1234/jes.2021.0045</li>
  <li>Brown, A., & Taylor, P. (2019). <em>Advances in Artificial Intelligence</em>. Cambridge University Press.</li>
  <li>World Health Organization. (2020). <em>Global Health Statistics 2020</em>. Retrieved from https://www.who.int/publications/global-health-statistics-2020</li>
</ul>

** Note: Content should only be taken from 1 source and avoid citing multiple references

**Notes:**
- Do not include additional text or explanations.
- Use 'References:' as the header for the citations list.

`;

        try {
            const selectedSocket = sockets[socketKey];
            console.log('Selected socket:', selectedSocket);
            if (selectedSocket) {
                selectedSocket.emit(
                    'data',
                    {
                        data: {
                            messages: [
                                {
                                    role: 'system',
                                    content: newPrompt,
                                    type: 'gpt-4o',
                                },
                                {
                                    role: 'user',
                                    content: `Make a research about the ${topic}`,
                                    type: 'gpt-4o',
                                },
                            ],
                            userId: details?.userId ?? '',
                        },
                        label: "genie",
                    },
                    false,
                    true,
                );
            }
        } catch (error) {
            console.error('Error generating content:', error);
        }
    };

    const onGenerateContentReference = async (index: number, socketKey: number) => {
        const currentSlide = sections[index];
        const contentNumber = sections.length;
        let no = length / contentNumber;

        if (index === 0) {
            no = no * 0.6;
        }
        console.log('Number:', no);
        const newPrompt = `
        Using this Reference style ${selectedStyle} generate reference for the citations in this ${currentSlide.content}

        `;

        try {
            const selectedSocket = sockets[socketKey];
            console.log('Selected socket:', selectedSocket);
            if (selectedSocket) {
                selectedSocket.emit('data', {
                    data: {
                        messages: [
                            {
                                role: 'system',
                                content: newPrompt,
                                type: 'gpt-4',
                            },
                        ],
                        userId: details?.userId ?? '',
                    },
                    label: "genie",
                });
            }
        } catch (error) {
            console.error('Error generating content:', error);
        }
    };

    useEffect(() => {
        sections.forEach((item, index) => {
            const socket = sockets[item.title];
            if (socket) {
                socket.on('data', (data: string) => {
                    const referencePattern = /\(([^)]+)\)|\[[^\]]+\]|https?:\/\/[^\s]+/g;

                    const newReferences = data.match(referencePattern) || [];

                    setSections((prevSections) =>
                        prevSections.map((section, i) =>
                            i === index
                                ? {
                                      ...section,
                                      content: (section.content || '') + data,
                                      references: [...(section.reference || []), ...newReferences],
                                  }
                                : section,
                        ),
                    );
                });
            }

            if (socket) {
                socket.on('sources', (sources: ResponseSources[]) => {
                    console.log('sources', sources);
                    setSections((prevSections) =>
                        prevSections.map((section, i) =>
                            i === index
                                ? {
                                      ...section,
                                      sources: [...(section.sources || []), ...sources], // Ensure previous sources are kept
                                  }
                                : section,
                        ),
                    );
                    

                    console.log('checked if sources came');
                    const updateCallback = (current: Chats[]) => {
                        const updated = current.map((item, i) => {
                            if (i === current.length - 1 && item.role === 'assistant') {
                                // check if item.content is string
                                const sourcesFile: ChatContent = {
                                    type: 'sources',
                                    sources: sources,
                                };

                                if (typeof item.content === 'string') {
                                    return {
                                        ...item,
                                        content: [
                                            {
                                                type: 'text',
                                                text: item.content,
                                            },
                                            sourcesFile,
                                        ],
                                    };
                                } else if (isChatContentArray(item.content)) {
                                    return {
                                        ...item,
                                        content: [...item.content, sourcesFile],
                                    };
                                }
                                // return { ...item, content: item.content  };
                            }
                            return item;
                        });
                        // console.log(updated);
                        return updated;
                    };

                    console.log('Chat list updated:', updateCallback);
                });
            }

            if (socket) {
                socket.on('stream_end', (data: { streamId: string; assistant: ChatType }) => {
                    const { streamId, assistant } = data;
                    console.log(`${item.title} stream ended`);

                    // Update stream status
                    setStreamStatus((prevStatus) => {
                        const updatedStatus = { ...prevStatus, [item.title]: true };
                        const totalStreams = sections.length - 1; // Excluding index 0
                        const completedStreams = Object.values(updatedStatus).filter(Boolean).length;

                        // 3. Wait for all streams except index 0 to complete before emitting index 0
                        if (
                            completedStreams === totalStreams &&
                            !updatedStatus[sections[0]?.title] &&
                            !isIndex0Emitting
                        ) {
                            console.log('🚀 Starting index 0...');
                            setIsIndex0Emitting(true); // Mark index 0 as "in the process of emitting"
                        }

                        // 4. Once all streams are complete, set the final status
                        if (completedStreams === totalStreams + 1 && !allStreamsComplete) {
                            setTimeout(() => {
                                setAllStreamsComplete(true);
                                console.log('✅ All streams are complete');
                            }, 500);
                        }

                        return updatedStatus;
                    });
                });
            }
        });

        return () => {
            sections.forEach((item) => {
                const socket = sockets[item.title];
                if (socket) {
                    socket.off('data');
                    socket.off('stream_end');
                    if (allStreamsComplete) {
                        socket.close();
                    }
                }
            });
        };
    }, [sections, sockets, allStreamsComplete, streamStatus, isIndex0Emitting]);

    useEffect(() => {
        if (isIndex0Emitting && htmlContent) {
            handleGenerateSummary();
        }
    }, [isIndex0Emitting, htmlContent]);

    useEffect(() => {
        const newSocket = io(BASE_URL.slice(0, -2));
        setSocket(newSocket);
        return () => {
            newSocket.close();
        };
    }, []);

    const handleGenerateSummary = () => {
        setIsSummaryGenerating(true);

        const executiveSummaryIndex = 0; // Last section's index
        const executiveSummaryTitle = sections[executiveSummaryIndex]?.title; // Title of the last section
        const executiveSummarySocket = sockets[executiveSummaryTitle]; // Socket name matches the title

        if (executiveSummarySocket) {
            const newPrompt = `
Generate a concise and professional Executive Summary based on the research content "${htmlContent}" and the completed sections of the document. 
**Instructions:**
- Summarize key points from all sections.
- Use clear and formal language.
- Content should be around 150-200 words.
- Do not include reference.
- Make it structured and well formated,
- It must not have a title




            `;

            executiveSummarySocket.emit(
                'data',
                {
                    data: {
                        messages: [
                            {
                                role: 'system',
                                content: newPrompt,
                                type: 'gpt-4o',
                            },
                        ],
                        userId: details?.userId ?? '',
                    },
                    label: "genie",
                },
                false,
                false,
            );

            // Listen for 'data' events for Executive Summary content
        }
    };

    useEffect(() => {
        if (!socket) return;

        socket.on('data', (data: string) => {
            setContent((prev) => prev + data);
            console.log(content);
        });

        socket.on('stream_end', async (data: { streamId: string; assistant: ChatType }) => {
            const { assistant } = data;
            setStreamEndMessage(assistant);
            setShowEditorButton(true);
            setIsTyping(false);
            setCanNavigate(true);
            setConversation([assistant]);

            // if (streamId === StreamId.current) {
            StreamId.current = '';
            // setStreaming(false);
            const uuid = uuidv4();
            setDocId(uuid);
            DocumentId.current = uuid;
            const user = JSON.parse(localStorage.getItem('user') || '');
            try {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: assistant.content,
                    owner_id: user?.id,
                });
            } catch (error) {
                socket?.emit('store-document', {
                    id: uuid,
                    title: promptSent,
                    value: assistant.content,
                });
            }
            socket.emit('get-documents', user?.id);
        });

        return () => {
            socket.off('message');
        };
    }, [socket]);

    const onGenerateTable = async () => {
        const token = localStorage.getItem('token');

        toast.info('Please sit tight, your beautiful content is on its way.');

        let msgs = chats;
        msgs = { role: 'user', content: topic };

        setChats(msgs);
        setNewPromptSent('');
        setResponse('');

        const base = prompt(topic, selectedResearchStyle);
        setBasePrompt(base);
        console.log(base);

        try {
            let msgs = chats;
            msgs = {
                role: 'user',
                content: `Generate a table of contents for a research paper on ${topic} in ${selectedResearchStyle} tone.`,
            };
            const response = await axios.post(
                `${BASE_URL}/knowledge-base`,
                {
                    model: 'gpt-4o',
                    messages: [
                        {
                            role: 'system',
                            content:
                                grade === "Graduate Level (Master's/Ph.D.)"
                                    ? tocPrompt(topic, selectedResearchStyle)
                                    : prompt(topic, selectedTone),
                        },
                        msgs,
                    ],
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
            msgs = response.data.data.choices[0].message;
            const AIRESULT = response?.data?.data?.choices[0]?.message;
            const generatedContent = AIRESULT?.content;
            // const formattedData = formatJSON(generatedContent);
            setContent(generatedContent);
            console.log(generatedContent);
            return generatedContent;
        } catch (error: any) {
            return [];
        } finally {
        }
    };

    useEffect(() => {
        if (content) {
            const contentLines = content.split(/(?=\d+\.)/).filter(Boolean);

            const initialSections = contentLines.map((line, index) => {
                const title = line.match(/^\d+(\.\d+)*\..*/)?.[0] || '';
                return { id: `section-${index + 1}`, title, content: '', reference: [] };
            });

            setSections(initialSections);

            // Clear old sockets before initializing new ones
            Object.values(sockets).forEach((socket) => {
                if (socket) socket.close();
            });

            const newSockets: { [key: string]: Socket } = {};
            const newConnectionStatus: { [key: string]: boolean } = {};

            contentLines.forEach((line) => {
                const title = line.match(/^\d+(\.\d+)*\..*/)?.[0] || '';
                if (!title) return; // Skip if no valid title is found

                const socket = io(baseurl.slice(0, -2), { transports: ['websocket'] });

                // Listen for socket connection success
                socket.on('connect', () => {
                    console.log(`✅ Socket for ${title} connected successfully!`);
                    setConnectionStatus((prev) => {
                        const updatedStatus = { ...prev, [title]: true };

                        // Check if all sockets are connected
                        const allConnected = Object.values(updatedStatus).every((status) => status === true);
                        if (allConnected) {
                            console.log('🎉 All sockets are connected!');
                            setAllSocketsConnected(true);
                        }

                        return updatedStatus;
                    });
                });

                // Handle connection errors
                socket.on('connect_error', (error) => {
                    console.error(`❌ Socket for ${title} connection error:`, error);
                });

                newSockets[title] = socket;
                newConnectionStatus[title] = false; // Initially, the socket is not connected
            });

            setSockets(newSockets);
            setConnectionStatus(newConnectionStatus);
            setAllSocketsConnected(false); // Reset the connection status for a new batch of sockets

            return () => {
                // Cleanup: Close all sockets
                Object.values(newSockets).forEach((socket) => {
                    if (socket) socket.close();
                });
            };
        } else {
            // If no content, clear sections and close sockets
            setSections([]);
            Object.values(sockets).forEach((socket) => {
                if (socket) socket.close();
            });
        }
    }, [content]);

    useEffect(() => {
        if (generate) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [generate]);

    const steps: Step[] = [
        { title: 'Topic' },
        { title: 'Length' },
        { title: 'Style' },
        { title: 'Tone' },
        { title: 'Grade' },
        { title: 'Reference' },
        { title: 'Review' },
        { title: 'Finish' },
    ];

    const pages = [
        <ArticleTitle topic={topic} setTopic={setTopic} current={current} setCurrent={setCurrent} />,
        <ArticleLength length={length} setLength={setLength} />,
        <ArticleStyle selectedStyle={selectedResearchStyle} setSelectedStyle={setResearchSelectedStyle} />,
        <ArticleTone selectedTone={selectedTone} setSelectedTone={setSelectedTone} />,
        <RGrade selectedGrade={grade} setSelectedGrade={setGrade} />,
        <ReferenceStyle selectedStyle={selectedStyle} setSelectedStyle={setSelectedStyle} />,
        <Review contents={content} setContent={setContent} />,
        <FinishPage
        essayRef={essayRef}
            height={height}
            setHeight={setHeight}
            response={response}
            setResponse={setResponse}
            streaming={streaming}
            setStreaming={setStreaming}
            slide={sections}
            setSlide={setSections}
            generate={generate}
            setGenerate={setGenerate}
            isStreamEnd={isIndex0Emitting}
            setHtmlContent={setHtmlContent}
            docId={docId}
            setDocId={setDocId}
            isSummarize={allStreamsComplete}
            handleReference={() => {
                sections.forEach((section, index) => {
                    onGenerateContentReference(index, index);
                });
            }}
            selectedResearchStyle={selectedResearchStyle}
            topic={topic}
            grade={grade}
            no={length}
            selectedTone={selectedTone}
            details={details}
        />,
    ];

    useEffect(() => {
        if (current === 7) {
            setShowTop(false);
            setShowSidebar(false);
        } else {
            setShowTop(true);
            setShowSidebar(true);
        }
    }, [current]);

    return (
        <div
            className={`   ${generate ? '' : 'pt-7 pb-3  px-[2rem]'} w-full h-screen`}
            style={{ overflowY: 'auto', height: generate ? '100%' : '90%' }}
        >
            {!generate && (
                <BackButton
                    onclick={() => {
                        navigate(-1);
                    }}
                />
            )}

            {!generate && !isMobile && current !== 7 && (
                // <Steps current={current} direction="horizontal" className="steps-container mb-3 mt-8">
                //     <Step title="Topic" />
                //     <Step title="Length" />
                //     <Step title="style" />
                //     <Step title="Tone" />
                //     <Step title="Grade" />
                //     <Step title="Reference" />
                //     <Step title="Review" />
                //     <Step title="Finish" />
                // </Steps>

                <CustomSteps current={current} steps={steps} />
            )}

            {current === 7 && !generate && (
                <div className="flex flex-row justify-center items-center mb-4 ">
                    <div className="flex flex-row justify-center items-center  text-customOrange border border-customOrange rounded-lg p-4  ">
                        {!allStreamsComplete && <CircularProgress size={20} style={{ color: 'orange' }} />}{' '}
                        <p className="ml-2">{allStreamsComplete ? 'Content is ready' : 'Gmind is typing'}</p>
                    </div>
                </div>
            )}

            <div className="flex flex-row gap-3">
                <div className={`p-6 w-full rounded-lg ${current === 7 ? '' : ''} `}>{pages[current]}</div>
                {/* {sources && current === 7 && (
                    <div
                        className={`w-[35%] flex flex-col gap-4  overflow-y-auto ${generate ? 'pt-6' : ''}`}
                        style={{ height: `${height}px` }}
                    >
                        <p className="text-lg dark:text-white font-bold">Sources</p>
                        <ResearchSources sources={sources} />
                    </div>
                )} */}
            </div>

            <div className="mt-6 flex justify-center space-x-4">
                {current > 0 && !generate && (
                    <button
                        onClick={() => {
                            setCurrent(current - 1);

                            if (current === 6) {
                                setAllStreamsComplete(false);
                            }

                            if (current === 7) {
                                setSources([]);
                                setAllStreamsComplete(false);
                            }
                        }}
                        className="px-4 py-2 bg-gray-300 dark:bg-gray-700 dark:text-white rounded"
                    >
                        Previous
                    </button>
                )}
                {current < pages.length - 1 ? (
                    current !== 0 && (
                        <button
                            onClick={async () => {
                                console.log(current);
                                if (current === 0) {
                                    if (!topic) {
                                        toast.info('Please enter topic for your research');
                                        return;
                                    }
                                    setCurrent(current + 1);
                                } else if (current === 1) {
                                    if (!length) {
                                        toast.info('Please select length  for your research');
                                        return;
                                    }
                                    setCurrent(current + 1);
                                } else if (current === 2) {
                                    if (!selectedResearchStyle) {
                                        toast.info('Please select a research type for your research');
                                        return;
                                    }
                                    setCurrent(current + 1);
                                } else if (current === 3) {
                                    if (!selectedTone) {
                                        toast.info('Please select tone for your research');
                                        return;
                                    }
                                    setCurrent(current + 1);
                                } else if (current === 4) {
                                    if (!grade) {
                                        toast.info('Please select grade for your research');

                                        return;
                                    }
                                    setCurrent(current + 1);
                                } else if (current === 5) {
                                    if (!selectedStyle) {
                                        toast.info('Please select reference style for your research');
                                        return;
                                    }
                                    setLoadings(true);
                                    setContent('');
                                    await onGenerateTable();
                                    setLoadings(false);
                                    setCurrent(current + 1);
                                } else if (current === 6) {
                                    if (!allSocketsConnected) {
                                        toast.info('Please wait while Gmind prepares your essay.');
                                        return;
                                    }

                                    setLoadings(true);
                                    sections.forEach((section, index) => {
                                        if (index !== 0 && section.title !== 'Executive Summary') {
                                            onGenerateContent(index, section.title);
                                        }
                                    });

                                    setCurrent(current + 1);
                                    setStreaming(true);
                                    setLoadings(false);
                                } else {
                                    setCurrent(current + 1);
                                }
                            }}
                            className={`px-4 py-2 bg-customOrange text-white rounded`}
                            disabled={loadings}
                        >
                            {loadings ? (
                                'Generating'
                            ) : !allSocketsConnected && current === 6 ? (
                                <div className="flex flex-row items-center justify-center">
                                    <span className="p-2">
                                        <EllipsisLoader />
                                    </span>{' '}
                                    <span>Preparing essay...</span>
                                </div>
                            ) : (
                                'Next'
                            )}{' '}
                        </button>
                    )
                ) : (
                    <div>
                        {!generate && (
                            <button
                                className="px-4 py-2 bg-green-500 text-white rounded"
                                disabled={!allStreamsComplete}
                                onClick={async () => {
                                    if(!allSocketsConnected) {
                                        toast.info('Please wait while Gmind prepares your research.');
                                        return;
                                    }
                                    setGenerate(true);
                                }}
                            >
                                Generate Essay
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResearchWriter;

const prompt = (topic: string, tone: string): string => {
    return `Generate a concise table of contents with 6-8 sections based on the following topic: 
  - ${topic} and this research type ${tone}
  
  **Instructions:**
  - The table of contents must have a maximum of 6 sections.
  - Use a hierarchical numbering format like this:
    1. Executive Summary
    2. Introduction
    3. Background
    4. Main Section 1
    5. Main Section 2
    6. Conclusion
    
  - Generate only titles for the sections; no content or sub-sections.
  - Do not add references or any explanatory text.
  - first index of the table must always be Executive Summary
  - Provide a clean, professional structure suitable for an academic or professional document.

  **Note: ** first index of the table must always be 'Executive Summary', don't add any other text.
  
  Ensure the table of contents is concise, clean, and focused on the topic: "${topic}".`;
};

const tocPrompt = (topic: string, tone: string): string => {
    return `Generate a concise table of contents with 6-8 sections based on the following topic: 
  - ${topic} and this research type ${tone}
  
  **Instructions:**
  - The table of contents must have a maximum of 6 sections.
  - Use a hierarchical numbering format like this:
    1. Executive Summary
    2.Introduction
    3.Theoretical Foundations
    4.Methodological Approaches
    5.Critical Review of Literature
    6. Applications and Implications
    7.Future Directions: 
  - Generate only titles for the sections; no content or sub-sections.
  - Do not add references or any explanatory text.
  - first index of the table must always be Executive Summary
  - Provide a clean, professional structure suitable for an academic or professional document.

  **Note: ** first index of the table must always be 'Executive Summary', don't add any other text.
  
  Ensure the table of contents is concise, clean, and focused on the topic: "${topic}".`;
};

const EllipsisLoader = () => (
    <div className="flex space-x-1">
        <div className="w-1 h-1 bg-gray-500 rounded-full animate-bounce"></div>
        <div className="w-1 h-1 bg-gray-500 rounded-full animate-bounce delay-200"></div>
        <div className="w-1 h-1 bg-gray-500 rounded-full animate-bounce delay-400"></div>
    </div>
);
